import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Checkbox,
  useToast,
  Select,
  Button,
  SimpleGrid,
  Divider,
  Text,
  Stack,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import React, { useEffect, useState } from 'react';
import { createColumnHelper, Table } from '@tanstack/react-table';
import { ProjectPeople } from 'types/People';
import { TableViewComponent } from 'components/table/tablecomponent';
import { TemplateService } from 'services/TemplateService';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RiDeleteBin6Line } from 'react-icons/ri';
import CustomButton from 'components/button/CustomButton';
import { IndeterminateCheckbox } from 'components/IndeterminateCheckbox/IndeterminateCheckbox';
import SpinnerPage from 'components/spinner/spinner';
import { ProjectService } from 'services/ProjectService';
import { useNavigate, useParams } from 'react-router-dom';
interface ProjectsLinkToPeopleModel {
  isOpen: boolean;
  onClose: () => void;
  peopleList: any[];
  projectDetails: any;
}

const ProjectDocumentRequest: React.FC<ProjectsLinkToPeopleModel> = ({
  isOpen,
  onClose,
  peopleList,
  projectDetails,
}) => {

  const toast = useToast();
  const variant = 'circles';
  // const temDetails = jsonData.filter((item: any) => !userIds.includes(item.userId));
  let activeColor = useColorModeValue('gray.700', 'white');
  const [personaList, setPersonaList] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFormSubmit, setFormSubmit] = useState<boolean>(false);
  const [isrequestFormSubmit, setRequestFormSubmit] = useState<boolean>(false);
  const { projectId } = useParams();
  const [error, setError] = useState<string | null>(null);
  const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
  const navigate = useNavigate();
  const steps = [
    { label: 'Create Request' },
    { label: 'Add Receipents' },
    { label: 'Set Reminder Intervals' },
    { label: 'Request Overview' },
  ];
  const { nextStep, prevStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const [formrequest, setRequest] = useState({
    projectRequestTemplateId: '',
    requestDescription:'',
    requestName: '',
    requestDetails:[
      {
        documentTypeAutoId: 1,
        label: '',
      },
    ],
    requestSendTo: [
      {
        sendTo: '',
        sendCC: '',
      },
    ]
   
  });
  const [formStepThree, setformStepThree] = useState({
    reminderId: '1'
  });
  const [formStepThreeErrors, setFormStepThreeErrors] = useState({
    reminderId: '',
  });


  const [formStepOneErrors, setFormStepOneErrors] = useState(false);

  const [formErrors, setFormErrors] = useState({
    projectRequestTemplateId: null,
    requestDescription:null,
    requestName: null,
   
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [hasCompletedAllSteps, setHasCompletedAllSteps] = useState(false);

  const bg = useColorModeValue('background.100', 'background.900');
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
  const [reminderList, setReminderList] = useState([]);
  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [requestDetails, setRequestDetails] = useState([]);
  const [tableObject, setTableObject] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [initialDocumentTypeAutoId, setInitialDocumentTypeAutoId] = useState(1);

  let formInit = {
    requestName: '',
    requestDescription: '',
    reminderId: '0',
    statusAutoId: 10,
    requestDetails: [
      {
        documentTypeAutoId: initialDocumentTypeAutoId,
        label: '',
      },
    ],
    requestSendTo: [
      {
        sendTo: '',
        sendCC: '',
        status: 10,
      },
    ],
    projectId: projectDetails?.project?.projectId,
  };

  const validateRequestDetails = () => {
    // if(formik.values.requestTemplateName)
  };

  const [requestTemplateDetailsForm, setRequestTemplateDetailsForm] = useState(
    formInit,
  );

  const validationSchema = Yup.object().shape({
    // userName: Yup.string(),
    requestName: Yup.string().required('First Name is required'),
    requestDescription: Yup.string().required('Description is required'),
    reminderAutoId: Yup.string().required('Please Select A Reminder'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    roles: Yup.string(),
  });

  const formik = useFormik({
    initialValues: formInit,
    enableReinitialize: false,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      ///values.userId = ;
    },
  });

  useEffect(() => {
    fetchData();

    return () => {};
  }, [isOpen]);


  

  const handleStepThreeInputChange = (e:any) => {
    const { name, value } = e.target;
    setformStepThree((formStepThree) => ({
      ...formStepThree,
      [name]: value,
    }));
  
  };

  const handleInputChange = (e:any) => {
    const { name, value } = e.target;
    setRequest((formrequest) => ({
      ...formrequest,
      [name]: value,
    }));
  };
  const validateForm = (job:any) => {
    
    let errors = {projectRequestTemplateId:'',
    requestDescription:'',
    requestName:''};
    let valid = true;
    if (!job.requestDescription.trim()) {
      errors.requestDescription = 'Request Description is required';
      valid = false;
    }

    if (!job.requestName.trim()) {
      errors.requestName = 'Request Name is required';
      valid = false;
    }

    setFormErrors(errors);

    return valid;
  };

  const validateStepThreeForm = (job:any) => {
    
    let errors = {reminderId:'',};
    let valid = true;
    if (!job.reminderId) {
      errors.reminderId = 'Reminder is required';
      valid = false;
    }

   
    setFormStepThreeErrors(errors);

    return valid;
  };

  function isValueNotEmpty(value:any) {
    return value !== null && value !== undefined && value !== '';
  }

  const checkValues = (data:any) => {
    if (Array.isArray(data)) {
      return data.every(res=>{
        return Object.values(res).every(isValueNotEmpty);
      });
    } else if (typeof data === 'object') {
      return Object.values(data).every(isValueNotEmpty);
    }
    return isValueNotEmpty(data);
  };

  const addNewRequestTemplate = function () {
    
    let documentTemplate = [...requestDetails];
      const isValidRequest =  checkValues(documentTemplate);
      if(!isValidRequest){
        setRequestFormSubmit(true);
         return;
      }
    documentTemplate.push({
      documentTypeAutoId: initialDocumentTypeAutoId,
      label: '',
    });
    formik.values.requestDetails = documentTemplate;
    setRequestDetails(documentTemplate);
    setRequestTemplateDetailsForm(formik.values);
  };

  const fetchData = async () => {
    if (isOpen) {
      setLoading(true);
      reset();
      setCurrentStep(0);
      setIsLastStep(false);
      formik.values = formInit;
      setCurrentStep(0);
      // formrequest.projectRequestTemplateId = null;
      setRequestDetails(formrequest.requestDetails);
      setRequestTemplateDetailsForm(formInit);
      await getReminderList();
      await getDocumentTypeList();
      await getProjectRequestTemplates();

      setLoading(false);
    }
  };

  const getProjectRequestTemplates = async () => {
    try {
      const data: any = await TemplateService.getProjectDocumentTemplates();
      setDocumentTemplates(data.requestTemplates);
    } catch (error) {
      setError('Error fetching people data');
    } finally {
    }
  };

  const getReminderList = async () => {
    try {
      let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
      const data: any = await TemplateService.getReminderListByTenantId(tenantId);
      setReminderList(data);
    } catch (error) {
      setError('Error fetching people data');
    } finally {
    }
  };

  const getDocumentTypeList = async () => {
    try {
      let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
      const data: any = await TemplateService.getDocumentListByTenantId(tenantId);
      setDocumentTypes(data.documentTypes);
      setInitialDocumentTypeAutoId(data?.documentTypes[0].documentTypeAutoId);
      formInit.requestDetails[0].documentTypeAutoId = data?.documentTypes[0].documentTypeAutoId;
    } catch (error) {
      setError('Error fetching people data');
    } finally {
    }
  };

  const onDocumentRequestNameChange = (e: any, index: any) => {
    let docDet = [...requestDetails];

    docDet[index].label = e.target.value;
    setRequestDetails(docDet);
  };

  const onDocumentTypeChange = (e: any, index: any) => {
    let docDet = [...requestDetails];

    docDet[index].documentTypeAutoId = e.target.value;
    setRequestDetails(docDet);
  };

  const onRemoveDocumentType = (e: any, index: any) => {
    let docDet = [...requestDetails];
    docDet.splice(index, 1);
    setRequestDetails(docDet);
  };

  const getReminderDetails = (reminderId: any) => {
    return reminderList.find(
      (reminder) => reminder.remindersAutoId == reminderId,
    )?.details;
  };

  const getDocumentTypeName = (documentTypeAutoId: any) => {
    return documentTypes.find(
      (docType) => docType.documentTypeAutoId == documentTypeAutoId,
    )?.documentTypeName;
  };

  const columnsss = [
    columnHelper.display({
      id: 'isRecepientSelected',
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
 
    columnHelper.accessor('firstName', {
      id: 'firstName',
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          First Name#
        </p>
      ),
      cell: (info) => (
        <p
          color={activeColor}
          className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
          onClick={() => {
            // onBusinessClick(info.row.original.pe, true);
          }}
        >
          {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('lastName', {
      id: 'lastName',
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          Last Name
        </p>
      ),
      cell: (info: any) => (
        <p color={activeColor} className="text-sm font-bold dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">Email</p>
      ),
      cell: (info: any) => (
        <p color={activeColor} className="text-sm font-bold dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
  ];

  const getTableObject = (tableObject: any) => {
    setTableObject(tableObject);
  };

  const onDocumentTemplateChange = (e: any) => {
   
    let docTemplate = documentTemplates.find(
      (docTemp:any) => docTemp.requestTemplateId == e.target.value,
    );
    
    if (docTemplate) {
      formrequest.requestName = docTemplate?.requestTemplateName;
      formrequest.requestDescription =
        docTemplate?.requestTemplateDescription;
        formStepThree.reminderId = docTemplate?.remindersAutoId ?? 1 ;
        formrequest.requestDetails = docTemplate?.requestTemplateDetails;
      setRequestDetails(docTemplate?.requestTemplateDetails);
    } else {
      formrequest.requestName = "";
      formrequest.requestDescription = "";
        formStepThree.reminderId = '1';
        formrequest.requestDetails = formInit.requestDetails;
      setRequestDetails(formrequest?.requestDetails);
    }


  };

  const getUserDetails  = ()=>{
    let userLoggedIn:any;
    if(localStorage.getItem("userLoginStatus")){
      userLoggedIn =   JSON.parse(localStorage.getItem("userLoginStatus"));
    }
   return userLoggedIn?.userDetails?.userInfo?.tenantId
  }
  const saveProjectRequest = async () => {
    let mapProjectRequest:any = {requestName:'',
    requestDescription:'',
    reminderId:'',
    requestDetails: [],
    requestSendTo:[],
    tenentId:0,
    projectId:0};
    mapProjectRequest.requestName = formrequest.requestName;
    mapProjectRequest.requestDescription = formrequest.requestDescription;
    mapProjectRequest.reminderId = formStepThree.reminderId;
    mapProjectRequest.requestDetails = [];
    mapProjectRequest.requestSendTo = [];
    mapProjectRequest.projectId = projectId;
    mapProjectRequest.tenentId = getUserDetails();
    mapProjectRequest.statusAutoId = 10;
    requestDetails.forEach((reqDetails:any) => {
      mapProjectRequest.requestDetails.push({
        label: reqDetails.label,
        documentTypeAutoId: reqDetails.documentTypeAutoId == 1? documentTypes[0].documentTypeAutoId: reqDetails.documentTypeAutoId,
      });
      selectedUsers?.forEach((user: any) => {
        mapProjectRequest.requestSendTo.push({
          sendTo: user.email,
          sendCC: '',
          status: 10,
          label: reqDetails.label,
          peopleId: user.peopleId,
          documentTypeAutoId: reqDetails.documentTypeAutoId == 1? documentTypes[0].documentTypeAutoId: reqDetails.documentTypeAutoId,
        });
      });
    });
   

    try {
      setLoading(true);
      const data: any = await ProjectService.postprojectRequestWithDetails(mapProjectRequest);
      toast({
        title: 'Project Request Created successfully.',
        status: 'success',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      setCurrentStep(0);
      onClose();
      
    } catch (error) {
      toast({
        title: 'Error occured while creating request',
        //description: 'Notes update error.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const prevStepClick = () => {
    if (currentStep == 3) {
      setIsLastStep(false);
    }

    if (currentStep >= 0) {
      setCurrentStep(currentStep - 1);
      prevStep();
    }
  };

  const setSelectedRowsAndValidate = (): boolean => {
    let selectedRows =
      tableObject != undefined ? tableObject.getSelectedRowModel().rows : null;

    let selectedUsersData: any[] = [];

    selectedRows.forEach((row: any) => {
      selectedUsersData.push(row?.original);
    });

    setSelectedUsers(selectedUsersData);
   return selectedUsersData.length == 0 ? false : true;
  };

  const nextStepCLick = () => {
    
    if (currentStep === 3) {
     
      saveProjectRequest();
      setIsLastStep(true);
    } else if (currentStep === 2) {
    const isValid = validateStepThreeForm(formStepThree);
    if(!isValid){
        return;
    }
      setCurrentStep(currentStep + 1);
      setIsLastStep(true);

      let isValidRows = setSelectedRowsAndValidate();

      nextStep();
    } else {
      if (currentStep === 1) {
        if(!setSelectedRowsAndValidate()) {
          setFormStepOneErrors(true);
          return;
        } else {
          setFormStepOneErrors(false);
        }
      }
      if (currentStep < steps.length - 1) {
        const isvalid = validateForm(formrequest);
         const isValidRequest =  checkValues(requestDetails);
      if(!isValidRequest){
        setRequestFormSubmit(true);
         return;
      }
        if(!isvalid){
          return;
        }
        setFormSubmit(true);
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(0);
        setIsLastStep(true);
        setHasCompletedAllSteps(true);
      }
      nextStep();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent className="modalH">
        <ModalHeader>Create New Request</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            //(temDetails && temDetails?.length > 0) ?
            /*: <div>No Data</div>*/
          }
          {/* <GetModalBody></GetModalBody> */}
          {loading ? (
            <SpinnerPage />
          ) : (
            <Flex flexDir="column" width="100%">
              <Steps
                variant={variant}
                colorScheme="blue"
                activeStep={activeStep}
              >
                <Step
                  label={steps ? steps[0].label : ''}
                  key={steps ? steps[0].label : ''}
                >
                  <Box sx={{ bg, my: 8, rounded: 'md' }} className="">
                    <form onSubmit={formik.handleSubmit}>
                      <SimpleGrid
                        p={2}
                        spacingX={2}
                        minChildWidth="225px"
                        columns={2}
                      >
                        <Box>
                          <Text mb="8px">Select from template:  </Text>
                          <Select
                            name="projectRequestTemplateId"
                            id="projectRequestTemplateId"
                            onChange={(e:any) => {
                              handleInputChange(e);
                              onDocumentTemplateChange(e);
                            }}
                            value={formrequest.projectRequestTemplateId}
                          >
                            <option value={''}>-</option>
                            {documentTemplates?.map(function (
                              documentTemplate,
                            ) {
                              return ( documentTemplate.tenantId == userLoggedIn?.userDetails?.userInfo?.tenantId ?
                                <option
                                  key={documentTemplate.requestTemplateId}
                                  value={documentTemplate.requestTemplateId}
                                >
                                  {documentTemplate.requestTemplateName}
                                </option> : ""
                              );
                            })}
                          </Select>
                        </Box>
                      </SimpleGrid>

                      <SimpleGrid
                        p={2}
                        spacingX={3}
                        minChildWidth="225px"
                        spacingY={'5px'}
                      >
                        
                        <Box pt={5}>
                          <Text mb="8px">Request Name: </Text>{formik.values?.requestName}
                          <Input
                            placeholder="TemplateName"
                            id="requestName"
                            name="requestName"
                            type="requestTemplateName"
                            size="md"
                            value={formrequest.requestName}
                            onChange={handleInputChange}
                          />
                         <span style={{ color: 'red' }}>
                             {formErrors?.requestName && formrequest.requestName.length == 0 ? <span>{formErrors?.requestName}</span> : ''}
                         
                         
                          </span>
                        </Box>
                      </SimpleGrid>

                      <SimpleGrid
                        p={2}
                        spacingX={3}
                        minChildWidth="225px"
                        spacingY={'5px'}
                      >
                        <Box>
                          <Text mb="8px">Request Description: </Text>
                          <Textarea
                            placeholder="Template Description"
                            id="requestDescription"
                            name="requestDescription"
                            size="md"
                            height={{ base: '150px', md: '100px' }} // Adjusted height for responsiveness
                            // onChange={formik.handleChange}
                            // value={formik.values.requestDescription}
                            value={formrequest.requestDescription}
                            onChange={handleInputChange}
                          />
                           <span style={{ color: 'red' }}>
                             {formErrors?.requestDescription &&  formrequest.requestDescription.length == 0  ?  <span>{formErrors?.requestDescription}</span>:''}
                         
                         
                          </span>
                        </Box>
                      </SimpleGrid>

                      <Divider mt={4} />

                      {requestDetails.map((requestDetail, index) => {
                        return (
                          <Box key={index}>
                            <SimpleGrid
                              key={index}
                              p={2}
                              spacingX={3}
                              minChildWidth="225px"
                              spacingY={'5px'}
                            >
                              <Box
                                className="rounded-[10px]"
                                p="15px"
                                mt="10px"
                                borderColor={paleGray}
                                border={'1px'}
                              >
                                {index != 0 ? (
                                  <Box>
                                    <Button
                                      float={'right'}
                                      onClick={(e) => {
                                        onRemoveDocumentType(e, index);
                                      }}
                                    >
                                      <RiDeleteBin6Line />
                                    </Button>
                                  </Box>
                                ) : (
                                  ''
                                )}
                                <SimpleGrid
                                  p={2}
                                  spacingX={3}
                                  minChildWidth="225px"
                                  spacingY={'5px'}
                                >
                                  <Box pt={5}>
                                    <Text mb="8px">Request Name: </Text>
                                    <Input
                                      placeholder="Document Name"
                                      id="docLabel"
                                      name="docLabel"
                                      type="text"
                                      size="md"
                                      onChange={(e) => {
                                        onDocumentRequestNameChange(e, index);
                                      }}
                                      value={requestDetail.label}
                                    />
                                   <span style={{ color: 'red' }}>
                             {isrequestFormSubmit &&  requestDetail.label.length == 0  ?  <span>Request name is dequired</span>:''}
                         
                         
                          </span>
                                  </Box>
                                </SimpleGrid>
                                <SimpleGrid
                                  p={2}
                                  spacingX={2}
                                  minChildWidth="225px"
                                  columns={2}
                                >
                                  <Box>
                                    <Text mb="8px">Document type:</Text>
                                    <Select
                                      name="docType"
                                      value={requestDetail?.documentTypeAutoId}
                                      onChange={(e) => {
                                        onDocumentTypeChange(e, index);
                                      }}
                                    >
                                      {documentTypes.map(function (
                                        documentType,
                                      ) {
                                        return (
                                          <option
                                            key={
                                              documentType.documentTypeAutoId
                                            }
                                            value={
                                              documentType.documentTypeAutoId
                                            }
                                          >
                                            {documentType.documentTypeName}
                                          </option>
                                        );
                                      })}
                                    </Select>
                                    <span style={{ color: 'red' }}>
                             {isrequestFormSubmit &&  requestDetail?.documentTypeAutoId.length == 0  ?  <span>Document type is dequired</span>:''}
                         
                         
                          </span>
                                  </Box>
                                </SimpleGrid>
                              </Box>
                            </SimpleGrid>
                          </Box>
                        );
                      })}
                      <Divider mt={4} />
                      <Flex
                        justify={{ base: 'center', md: 'flex-start' }}
                        mt={4}
                      >
                        <CustomButton
                          label={'Add More'}
                          onClick={addNewRequestTemplate}
                        />
                      </Flex>
                    </form>
                  </Box>
                </Step>

                <Step
                  label={steps ? steps[1].label : ''}
                  key={steps ? steps[1].label : ''}
                >
                  <Box sx={{ bg, my: 8, rounded: 'md' }}>
                    <TableViewComponent
                      columnsList={columnsss}
                      tableData={projectDetails?.projectPeopleRelation}
                      enablePagination={projectDetails?.projectPeopleRelation?.length > 15 ? true : false}
                      enableSearch={true}
                      viewSaveCancel={false}
                      cancelButtonClick={onClose}
                      getTableObject={getTableObject}
                    ></TableViewComponent>
                      <span style={{ color: 'red' }}>
                             {formStepOneErrors ? <span>Please Select a user to send the Request</span>:''}
                      </span>
                  </Box>
                </Step>
                <Step
                  label={steps ? steps[2].label : ''}
                  key={steps ? steps[2].label : ''}
                >
                  <Box sx={{ bg, my: 8, rounded: 'md' }}>
                    <Box>
                      <Stack>
                        <Text fontSize="2xl">Set Reminder</Text>
                      </Stack>
                    </Box>

                    <SimpleGrid
                      p={2}
                      spacingX={2}
                      minChildWidth="225px"
                      columns={2}
                    >
                      <Box>
                        <Text mb="8px">Reminder:</Text>
                        <Select
                          name="reminderId"
                          value={formStepThree.reminderId}
                          onChange={handleStepThreeInputChange}
                        >
                          {reminderList?.map((reminder) => (
                            <option
                              key={reminder.remindersAutoId}
                              value={reminder.remindersAutoId}
                            >
                              {reminder.details}
                            </option>
                          ))}
                        </Select>
                        <span style={{ color: 'red' }}>
                             {formStepThreeErrors?.reminderId &&  formStepThree.reminderId.length == 0  ?  <span>Remider is required</span>:''}
                        </span>
                      </Box>
                    </SimpleGrid>
                  </Box>
                </Step>
                <Step
                  label={steps ? steps[3].label : ''}
                  key={steps ? steps[3].label : ''}
                >
                  <Box sx={{ bg, my: 8, rounded: 'md' }}>
                    <Box>
                      <Stack>
                        <Text pl={'25px'} as="u" fontSize="2xl">
                          Review Request
                        </Text>
                      </Stack>
                    </Box>
                  

                    <SimpleGrid px={6} spacingX={3} minChildWidth="225px">
                      <Box>
                        <Text mb="8px">
                          <span className="font-bold">Request Name:</span>{' '}
                          {formrequest?.requestName}
                        </Text>
                        <Text mb="8px">
                          <span className="font-bold">
                            Request Description:
                          </span>{' '}
                          {formrequest?.requestDescription}
                        </Text>

                        <Text mb="8px">
                          <span className="font-bold">Reminder:</span>{' '}
                          {getReminderDetails(formStepThree?.reminderId)}
                        </Text>
                      </Box>
                    </SimpleGrid>
                    {requestDetails.map((details, key) => {
                      return (
                        <SimpleGrid
                          px={2}
                          spacingX={3}
                          minChildWidth="225px"
                          spacingY={'5px'}
                          key={key}
                        >
                          <Box
                            key={key}
                            className="rounded-[10px]"
                            px="15px"
                            py="15px"
                            borderColor={paleGray}
                            border={'1px solid #EEE'}
                          >
                            <Text mb="8px">
                              <span className="font-bold">
                                Requested documentTypeAutoId No :
                              </span>
                              {' #'}
                              {key + 1}
                            </Text>
                            <Text mb="8px">
                              <span className="font-bold">Label:</span>{' '}
                              {details?.label}
                            </Text>
                            <Text>
                              <span className="font-bold">Document Type:</span>{' '}
                              {getDocumentTypeName(details?.documentTypeAutoId)}
                            </Text>
                          </Box>
                        </SimpleGrid>
                      );
                    })}
                  </Box>
                  <SimpleGrid px={6} spacingX={3} minChildWidth="225px">
                    <Box>
                      <table width={'100%'}>
                        <thead>
                          <tr>
                            {/*<th align="left">Index</th>*/}
                            <th align="left">Name</th>
                            <th align="left">Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableObject
                            ?.getSelectedRowModel()
                            .rows.map((row: any, key: any) => {
                              return (
                                <tr key={key}>
                                  {/*<td>*/}
                                  {/*  <Box>{key}</Box>*/}
                                  {/*</td>*/}
                                  <td>
                                    {row?.original?.lastName},{' '}
                                    {row?.original?.firstName}
                                  </td>
                                  <td>{row?.original?.email}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Box>
                  </SimpleGrid>
                </Step>
              </Steps>
              {hasCompletedAllSteps && (
                <Box sx={{ bg, my: 8, p: 8, rounded: 'md' }}>
                  <Heading fontSize="xl" textAlign={'center'}>
                    {/* Woohoo! All steps completed! 🎉 */}
                    Request Submitted Succesfully
                  </Heading>
                </Box>
              )}
              <Flex marginBottom={"20px"}  marginTop={"20px"} width="100%" justify="flex-end" gap={4}>
                {hasCompletedAllSteps ? (
                  <Button size="sm" onClick={reset}>
                    Reset
                  </Button>
                ) : (
                  <>
                    <Button 
                    bg="brand.500"
                    color="white"
                      isDisabled={activeStep === 0}
                      onClick={prevStepClick}
                      size="md"
                      borderRadius="full"
                      variant="ghost"
                    >
                      Prev
                    </Button>
                    <Button bg="brand.500"
                    borderRadius="full"
                color="white" size="md" onClick={nextStepCLick}>
                      {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                  </>
                )}
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectDocumentRequest;
const columnHelper = createColumnHelper<ProjectPeople>();
