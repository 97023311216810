// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
  const { mini, hovered } = props;
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex alignItems="center" flexDirection="column">
          <Text display={
          mini === false 
            ? 'block'
            : 'none'
        } fontWeight="bold" paddingBottom="2"
            fontSize="34px">Minerva</Text>

      <Text
        display={
          mini === true
            ? 'block'
           
            : 'none'
        }
        fontSize={'30px'}
        fontWeight="800"
        color={logoColor}
      >
       Minerva
      </Text>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
