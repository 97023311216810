import { Box, Input, Text, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue, useToast, Flex } from "@chakra-ui/react";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LenderService } from "services/LenderService";
import { Lenders } from "types/Lender";
import { StateList } from "types/People";
import * as Yup from 'yup';


export default function ViewBusinessPage() {
    const { lenderId } = useParams();
    const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
    const [currentUserId, setCurrentUserId] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const bg = useColorModeValue('background.100', 'background.900');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const {
        userDetails: {
            userInfo: { userId, tenantId },
        },
    } = userLoggedIn;
    const formInit = {
        lenderID: 0,
        tenantID: 0,
        name: "",
        contactAddress: "",
        phoneNumber: "",
        email: "",
        licensingDetails : "",
        commercialMortgageProducts: "",
        interestRates: 0,
        terms: "",
        loanToValueRatio: 0,
        applicationProcessDetails: "",
        underwritingGuidelines: "",
        closingCostsAndFees: 0,
        specializedServices: ""
      };
    const [lenderDetails, setLenderDetails] = useState(formInit);
 
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        //contactAddress: Yup.string().required('Contact Address  is required'),
        //phoneNumber: Yup.string().required('Phone Number is required'),
        //email: Yup.string().required('Email is required'),
        //licensingDetails : Yup.string().required('Licensing Details is required'),
        //commercialMortgageProducts: Yup.string().required('Commercial Mortgage Products is required'),
        //interestRates: Yup.number().required('Interest Rates is required'),
        //terms: Yup.string().required('terms is required'),
        //loanToValueRatio: Yup.number().required('loan To Value Ratio is required'),
        //applicationProcessDetails: Yup.string().required('Application Process Details is required'),
        //underwritingGuidelines: Yup.string().required('Underwriting Guidelines is required'),
        //closingCostsAndFees: Yup.number().required('closing Costs And Fees is required'),
        //specializedServices: Yup.string().required('Specialized Services is required'),
       
    });

    useEffect(() => {
        setCurrentUserId(userLoggedIn?.userDetails?.userInfo?.userId);
        getLenderById();
        return () => {
            setLenderDetails(formInit);
        
        };
    }, []);



    const getLenderById = async () => {
        if (!lenderId) {
            return;
        }
        try {
            const data: any =
                await LenderService.getLenderById<Lenders>(parseInt(lenderId));
            setLenderDetails(data);
        
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };


    const closeModal = () => {
        setIsModalOpen(false);
        getLenderById();
    };

   
    const handleFormattedInput = (event: { target: any; }, fieldName: string, maxDigits: number, isSSN = false, separator = '-') => {
        let inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        inputValue = inputValue.substring(0, maxDigits); // Limit to a maximum of `maxDigits` digits

        let formattedValue = inputValue;
        if (fieldName === 'phoneNumber') {
            // Adjust the regex pattern for phone number formatting
            formattedValue = inputValue.replace(/^(\d{0,3})(\d{0,3})?(\d{0,4})?$/, (match: any, p1: string, p2: any, p3: any) => {
                let formatted = '';
                if (p2 === undefined && p3 === undefined) {
                    formatted = `(${p1})`; // Format as (XXX)
                } else if (p3 === undefined) {
                    formatted = `(${p1}) ${p2}`; // Format as (XXX) XXX
                } else {
                    formatted = `(${p1}) ${p2}-${p3}`; // Format as (XXX) XXX-XXXX
                }
                return formatted;
            });
        }

        // Update formik values with formatted value
        formik.setFieldValue(fieldName, formattedValue);
    };



    const handleChange = (event: { target: any; }, fieldType: string) => {
        let fieldName = '';
        let maxDigits = 0;
        let separator = '-';
        //let isValidEmail = false;
        switch (fieldType) {
            case 'phoneNumber':
                fieldName = 'phoneNumber';
                maxDigits = 10;
                break;
            default:
                break;
        }
        handleFormattedInput(event, fieldName, maxDigits);
    };


    const cancelRequest = () => {
        navigate('/admin/lenders');
    };

    const getFormattedDate = (dob: string) => {
        return moment(dob, 'YYYY-MM-DD').format('YYYY-MM-DD');
    };

    const formik = useFormik({
        initialValues: lenderDetails,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
             values.tenantID = tenantId;
             values.lenderID = lenderId ? Number(lenderId):0;
            if (lenderId) {
               
                try {
                    const data: any = await LenderService.updateLender(values);
                    toast({
                        title: values.name + ' successfully updated.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    cancelRequest();
                } catch (error) { }
            } else {
                values.lenderID = 0;
                try {
                    const data: any = await LenderService.createLenders(values);
                    toast({
                        title: values.name + ' successfully created.',
                        //description: 'Business added successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    cancelRequest();
                } catch (error) { }
            }
        },
    });


    return (
        <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'} paddingTop="25">
            <Tabs isFitted variant="enclosed" bg="white">
                <TabList>
                    <Tab>Profile</Tab>
                   
                    {/* {lenderId ? <Tab>Associated People</Tab> : <Tab isDisabled>Associated People</Tab>} */}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px"> *Name</Text>
                                        <Input
                                            as={Input}
                                            id="name"
                                            name="name"
                                            placeholder=" Name"
                                            type="text"
                                            // helperText={
                                            //     formik.touched.businessName
                                            //         ? formik.errors.businessName
                                            //         : ''
                                            // }
                                            size="md"
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.name
                                                ? formik.errors.name
                                                : ''}
                                        </span>
                                    </Box>
                                    <Box>
                                        <Text mb="8px">Contact Business Type </Text>
                                        <Input
                                            placeholder="Business Type"
                                            id="businessType"
                                            name="contactAddress"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.contactAddress}
                                            size="md"
                                          
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.contactAddress
                                                ? formik.errors.contactAddress
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                               

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Phone Number  </Text>
                                        <Input
                                            placeholder="+1 000 111 2223"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            type="text"
                                            size="md"
                                           
                                            onChange={(event) => handleChange(event, 'phoneNumber')}
                                            value={formik.values.phoneNumber}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.phoneNumber
                                                ? formik.errors.phoneNumber
                                                : ''}
                                        </span>
                                    </Box>
                                    <Box>
                                        <Text mb="8px">Email </Text>
                                        <Input
                                            placeholder="email"
                                            id="email"
                                            name="email"
                                            type="text"
                                            size="md"
                                          
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.email
                                                ? formik.errors.email
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                              

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Licensing Details</Text>
                                        <Input
                                            placeholder="licensing Details"
                                            id="licensingDetails"
                                            name="licensingDetails"
                                            type="text"
                                            size="md"
                                           
                                            onChange={formik.handleChange}
                                            value={formik.values.licensingDetails}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.licensingDetails
                                                ? formik.errors.licensingDetails
                                                : ''}
                                        </span>
                                    </Box>
                                    <Box>
                                        <Text mb="8px">Commercial Mortgage Products </Text>
                                        <Input
                                            placeholder="Commercial Mortgage Products"
                                            id="commercialMortgageProducts"
                                            name="commercialMortgageProducts"
                                            type="text"
                                            size="md"
                                          
                                            onChange={formik.handleChange}
                                            value={formik.values.commercialMortgageProducts}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.commercialMortgageProducts ? formik.errors.commercialMortgageProducts : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                            
                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Interest Rates </Text>
                                        <Input
                                            placeholder="Interest Rates"
                                            size="md"
                                            id="interestRates"
                                            name="interestRates"
                                            type="text"
                                         
                                            onChange={formik.handleChange}
                                            value={formik.values.interestRates}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.interestRates
                                                ? formik.errors.interestRates
                                                : ''}
                                        </span>
                                    </Box>
                                    <Box>
                                        <Text mb="8px">Loan To Value Ratio </Text>
                                        <Input
                                            placeholder="Loan To Value Ratio"
                                            size="md"
                                            id="loanToValueRatio"
                                            name="loanToValueRatio"
                                            type="text"
                                          
                                            onChange={formik.handleChange}
                                            value={formik.values.loanToValueRatio}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.loanToValueRatio
                                                ? formik.errors.loanToValueRatio
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>
                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Terms</Text>
                                        <Input
                                            placeholder="terms"
                                            size="md"
                                            id="terms"
                                            name="terms"
                                            type="text"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.terms}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.terms
                                                ? formik.errors.terms
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>
                              
                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Application Process Details </Text>
                                        <Input
                                            placeholder="Application Process Details"
                                            size="md"
                                            id="applicationProcessDetails"
                                            name="applicationProcessDetails"
                                            type="text"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.applicationProcessDetails}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.applicationProcessDetails
                                                ? formik.errors.applicationProcessDetails
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>
                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Under writing Guidelines </Text>
                                        <Input
                                            placeholder="Underwriting Guidelines"
                                            size="md"
                                            id="underwritingGuidelines"
                                            name="underwritingGuidelines"
                                            type="text"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.underwritingGuidelines}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.underwritingGuidelines
                                                ? formik.errors.underwritingGuidelines
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Closing Costs And Fees </Text>
                                        <Input
                                            placeholder="Closing Costs And Fees"
                                            size="md"
                                            id="closingCostsAndFees"
                                            name="closingCostsAndFees"
                                            type="text"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.closingCostsAndFees}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.closingCostsAndFees
                                                ? formik.errors.closingCostsAndFees
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>
                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Specialized Services </Text>
                                        <Input
                                            placeholder="Specialized Services"
                                            size="md"
                                            id="specializedServices"
                                            name="specializedServices"
                                            type="text"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.specializedServices}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.specializedServices
                                                ? formik.errors.specializedServices
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>
                            
                                <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                    <CustomButton
                                        label={"Cancel"}
                                        onClick={cancelRequest}

                                    />
                                    <CustomButton
                                        label={"Save"}
                                        onClick={null}
                                        type="submit"

                                    />
                                </Flex>

                            </form>
                        </Box>
                    </TabPanel>
                    {/* <TabPanel></TabPanel> */}
                    <TabPanel>
                        <Box pt={'16px'} pb={'16px'}>
                            coming soon
                            {/* <CustomButton
                                label={"Link People"}
                                onClick={openModal}
                            /> */}
                            {/* <ViewTenantList
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                jsonData={tenantList}
                                personasList={personasList}
                                peoplesDetails={peopleList}
                                businessId={businessId}
                            /> */}
                        </Box>
                        {/* {peopleList.length ? (
                            <PeopleListTable
                                tableData={peopleList}
                                personasList={personasList}
                                deletePeople={deletePeople}
                            ></PeopleListTable>
                        ) : (
                            'data not available'
                        )} */}


                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Card>
    );
}