// src/services/apiService.ts
import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL } from './AxiosAuthService';// Replace with your API endpoint

interface Data<T> {
    data: any
}

interface ApiResponse<T> {
    data: Data<T>;
}

export const ProjectService = {
     createProject: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/project`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateProject: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${BASE_URL}/project`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getProject: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/project`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },  deleteProjectById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${BASE_URL}/project/${id}`);
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/project/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectDetailsById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/project/details/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getIndustries: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/master/getIndustrys`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getLoanTypes: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/master/getLoanTypes`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getStatues: async <T>(type: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/master/getProjectRequestTemplateStatus/${type}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getTenantStatues: async <T>(type: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/aster/getStatues`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectsByPeopleID: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/projectPeopleRelation/getProjectByPeople/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectprojectWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/project/projectWithDetails`,request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
   getProjectWithDetailsID: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/project/getProjectWithDetails/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postprojectPeopleRelationWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/projectPeopleRelation`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postprojectBusinessesRelationWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/projectBusinessesRelation`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    deleteprojectBusinessesRelationWithId: async <T>(bussinessPeopleId: any): Promise<T> => {
        try {
            const response: any = await axios.delete(`${BASE_URL}/projectBusinessesRelation/${bussinessPeopleId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    deleteprojectPeopleRelationWithId: async <T>(peoplePeopleId: any): Promise<T> => {
        try {
            const response: any = await axios.delete(`${BASE_URL}/projectPeopleRelation/${peoplePeopleId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getprojectBusinessesRelationWithProjectId: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/projectBusinessesRelation/projectBusinesses/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, postprojectRequestWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/project/createProjectRequest`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectRequestById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/ProjectRequest/projectId?projectId=${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateProjectRequestStatus: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${BASE_URL}/ProjectRequest/updateProjectRequestStatus`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getDownloadFileHeader: <T>(request: any): string => {
        return `${BASE_URL}/ProjectRequest/DownloadFileprojectRequestGet/${request}`;
    },
};




const handleApiError = (error: AxiosError) => {
    if (error.response) {
        console.error('API Error Response:', error.response.data);
    } else if (error.request) {
        console.error('API No Response:', error.request);
    } else {
        console.error('API Error:', error.message);
    }
};
