import React, { useEffect, useState } from 'react';
import { Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { Box } from "@chakra-ui/react"
import LenderListTable from './components/LenderListTable';
import { Lenders } from '../../../types/Lender';
import { LenderService } from 'services/LenderService';
import SpinnerPage from 'components/spinner/spinner';
import Card from 'components/card/Card';
import CustomButton from 'components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { PeopleService } from 'services/PeopleService';
// Custom components

export default function LendersPage() {
  const navigate = useNavigate();
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
  const [lenderList, setLenderList] = useState<Lenders[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const bg = useColorModeValue('background.100', 'background.900');
  const [error, setError] = useState<string | null>(null);
  const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];

  useEffect(() => {
    getLenderList();
    return () => {
      setLenderList([]);
    }
  }, []);

  const getLenderList = async () => {
    try {
     let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
      const data: any = await LenderService.getLenders();

      const lenders = data.filter((lender: any) => lender.tenantID == tenantId);
      // const data: any = [];
      setLenderList(lenders);
    } catch (error) {
      setError('Error fetching people data');
    } finally {
      setLoading(false);
    }
  };

  const addNewLender = () => {
    navigate('/admin/lenders/AddLenders', { state: { businessId: '' } });
};

  return (

    <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'}>
      {   
        loading ? <SpinnerPage /> : 
                    <Box bg={bg}>
                      {/* View Header Start */}
                        <div className="flex justify-between max-w-full items-center rounded-xl pt-[20px]">
                          <div className="flex  h-[38px] w-[400px] flex h-20 items-center justify-end px-6"></div>
                            <div className="flex  h-[38px] w-[400px] flex h-20 items-center justify-end px-6">
                              <CustomButton label={"Add Lender"}  onClick={addNewLender} />
                            </div>
                          </div>
                      {lenderList.length ? <LenderListTable tableData={lenderList}></LenderListTable> : <Box style={{ textAlign: 'center', marginTop: '10px' }}>No Records Available</Box> }
                          
                    </Box>
      }
    </Card>

  );
}