// Chakra imports
import {
  Button,
  Divider,
  Flex,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Textarea,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import CustomButton from 'components/button/CustomButton';
import Card from 'components/card/Card';
import SpinnerPage from 'components/spinner/spinner';
import { TableViewComponent } from 'components/table/tablecomponent';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { TemplateService } from 'services/TemplateService';
import { BusinessPersona } from 'types/People';
import { RequestTemplate } from 'types/RequestTemplate';
import * as Yup from 'yup';

// Custom components

export default function RequestTemplatePage() {
  const toast = useToast();
  const navigate = useNavigate();
  const bg = useColorModeValue('background.100', 'background.900');
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [documentRequest, setDocumentRequest] = useState(null);
  const [reminderList, setReminderList] = useState([]);
  const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
  let activeColor = useColorModeValue('gray.700', 'white');

    const requestTemplateClick = (requestTemplateId: any, flag: any) => {
        navigate('/admin/requestTemplate/AddRequestTemplate/' + requestTemplateId);
    };

  const columns = [
    columnHelper.accessor('requestTemplateId', {
        id: 'requestTemplateId',
        header: () => (
            <p className="text-sm font-bold text-gray-600 dark:text-white">
                Template Id
            </p>
        ),
        cell: (info) => (
            <Box>
                <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                Template Id
                </p>
                <p color={activeColor}
                    className="text-sm font-bold  dark:text-white">
                    {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                    {info.getValue()}
                </p>
            </Box>

        ),
        size: 10
    }),
    columnHelper.accessor('requestTemplateName', {
        id: 'requestTemplateName',
        header: () => (
            <p className="text-sm font-bold text-gray-600 dark:text-white">
                Template Name
            </p>
        ),
        cell: (info) => (
            <div>
                <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Name</p>
                <p
                    style={{ textDecoration: 'underline' }} // Apply underline style here
                    color={activeColor}
                    className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                    onClick={() => {
                        //onPeopleClick(info.getValue(), true);
                       requestTemplateClick(info.row.original.requestTemplateId, true);
                    }}
                >
                    {info.getValue()}

                </p>
            </div>
        ),
    }),columnHelper.accessor('requestTemplateDescription', {
      id: 'requestTemplateDescription',
      header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">
              Template Description
          </p>
      ),
      cell: (info) => (
          <Box>
              <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                  Template Description
              </p>
              <p color={activeColor} className="text-sm font-bold dark:text-white">
                  {info.getValue()}
              </p>
          </Box>
      ),
  }),columnHelper.accessor('requestTemplateDetails', {
    id: 'requestTemplateDetails',
    header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
            No of Document Types
        </p>
    ),
    cell: (info) => (
        <Box>
            <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
            No of Document Types
            </p>
            <p color={activeColor} className="text-sm font-bold dark:text-white">
                {info.getValue()?.length}
            </p>
        </Box>
    ),
}),
]; 

  const getAllRequestTemplate = async () => {
      try {
      const data: any = await TemplateService.getProjectDocumentTemplates();
      let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
      setDocumentRequest(data?.requestTemplates.filter((reqTemplate: any) => reqTemplate.tenantId==tenantId));
    } catch (error) {
      toast({
        title: 'Error creating template',
        //description: values.fileName + ' Project update error.',
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    }
  };

  const getReminderList = async () => {
    try {
      const data: any = await TemplateService.getReminderList();
      setReminderList(data);
    } catch (error) {
      setError('Error fetching people data');
    } finally {
    }
  };

  const addNewTemplateClick = () => {
    navigate("/admin/requestTemplate/AddRequestTemplate");
  }

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
  const fetchData = async () => {
    setLoading(true);
    await getAllRequestTemplate();
    await getReminderList();
    setLoading(false);
  };

  return (
    <Card  bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'}>
        { loading?  <SpinnerPage /> : 
                          <Box px={"10px"}>

                            <Box bg={bg}>
                              
                                    {/* View Header Start */}
                                    <SimpleGrid p={2} spacingX={4} minChildWidth='225px' spacingY={"20px"}>
                                      
                                        <Box>
                                            <Flex className="flex items-center justify-end px-6">
                                                <CustomButton
                                                    label={"Add New Template"}
                                                    onClick={addNewTemplateClick}
                                                />
                                            </Flex>
                                        </Box>
                                    </SimpleGrid>
                                    { documentRequest && documentRequest?.length > 0 ?
                                    <TableViewComponent
                                    columnsList={columns}
                                    tableData={documentRequest}
                                    enablePagination={documentRequest?.length > 15 ? true : false}
                                    enableSearch={true}
                                    saveButtonValue={null} 
                                    saveButtonClick={() => { }} 
                                    cancelButtonValue={null}
                                    cancelButtonClick={() => { }}></TableViewComponent>
                          : <Box>No Records Available</Box> }
                            </Box>
                          
                        </Box>
        }
      {/* </Card> */}
    </Card>
  );
}
const columnHelper = createColumnHelper<RequestTemplate>();