

// Chakra imports
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Divider, Flex, Grid, Heading, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea, useColorModeValue, Text, useToast, Button, Spacer } from '@chakra-ui/react';
import { Box } from "@chakra-ui/react"
import Card from 'components/card/Card';
import { Key, useEffect, useState } from 'react';
import { FaTags } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import FileInformation from '../components/FileInformation';

import { ProjectService } from 'services/ProjectService';
import SpinnerPage from 'components/spinner/spinner';
import ProjectsLinkToPeopleModel from './components/ProjectsLinkToPeopleModel';
import { GoPlusCircle } from 'react-icons/go';
import { PeopleService } from 'services/PeopleService';
import { People } from 'types/People';
import { Business } from 'types/Business';
import ProjectsLinkToBusinessModel from './components/ProjectsLinkToBusinessModel';
import FileProjectTable from './components/FileProjectTable';
import { FullProjectDetails, NotesDetails, ProjectRequestListItem, RequestDetails } from '../../../../types/Projects';
import { userService } from 'services/UsersService';
import CustomButton from '../../../../components/button/CustomButton';
import { MasterService } from 'services/MasterService';
import { AnyARecord } from 'dns';
import ProjectDocumentRequest from './components/ProjectDocumentRequest';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdEdit } from 'react-icons/md';
import ProjectNotes from './components/ProjectNotes';
import { setDynamicBreadCrumbName } from 'routes';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import { ConfirmationDialog } from 'components/Confirmation/ConfirmationDialog';
import { forEach } from 'lodash';

// Custom components

export default function ViewProject() {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const bg = useColorModeValue('background.100', 'background.900');
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const [tenantId, setTenantId] = useState<number | null>(null);
    const [projectDetails, setProjectDetails] = useState<any>();
    const [assignedUser, setAssignedUser] = useState<any>();
    const [userLoggedIn] = useState(JSON.parse(localStorage.getItem("userLoginStatus")));
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingBusiness, setLoadingBusiness] = useState<boolean>(false);
    const [loadingPeople, setLoadingPeople] = useState<boolean>(false);
    const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [peopleShowAll, setPeopleShowAll] = useState(false);
    const [userId, setUserId] = useState();
    const [businesshowAll, setBusinessShowAll] = useState(false);
    const [lendershowAll, setLendershowAll] = useState(false);
    const [peopleVisibleCount, setPeopleVisibleCount] = useState(4);
    const [businessVisibleCount, setBusinessVisibleCount] = useState(4);
    const [lendersVisibleCount, setLendersVisibleCount] = useState(4);
    const [isModalOpenDocRequest, setIsModalOpenDocRequest] = useState(false);
    const [isModalOpenPeople, setIsModalOpenPeople] = useState(false);
    const [isModalOpenBusiness, setIsModalOpenBusiness] = useState(false);
    const [isModalOpenLenders, setIsModalOpenLenders] = useState(false);
    const [peopleList, setPeopleList] = useState<People[] | []>([]);
    const [peopleModalList, setPeopleModalList] = useState<People[] | []>([]);
    const [projectPeopleList, setProjectPeopleList] = useState<People[] | []>([]);
    const [businessList, setBusinessList] = useState<Business[] | []>([]);
    const [selectedBusinessList, setSelectedBusinessList] = useState<Business[] | []>([]);
    const [filteredBusinessList, setFilteredBusinessList] = useState<Business[] | []>([]);
    const [projectBusinessList, setProjectBusinessList] = useState<Business[] | []>([]);
    const [notes, setNotes] = useState([]);
    const toast = useToast();
    const [requestList, setRequestList] = useState<any[]>([]);
    const [requestPersonalList, setrequestPersonalList] = useState<RequestDetails[] | []>([]);
    const [requestBusinessList, setrequestBusinessList] = useState<RequestDetails[] | []>([]);
    const [requestStaffList, setrrequestStaffList] = useState<RequestDetails[] | []>([]);
    const [deletePeople, setDeletePeople] = useState(null);
    const [isDeletePeopleModalOpen, setIsDeletePeopleModalOpen] = useState(false);
    const [deleteBusiness, setDeleteBusiness] = useState(null);
    const [isDeleteBusinessModalOpen, setIsDeleteBusinessModalOpen] = useState(false);
    const [isTenantAdmin, setIsTenantAdmin] = useState(false);
    const [sentCount, setSentCount] = useState(0);

    const [addNewNotes, setAddNewNotes] = useState('');
  
    useEffect(() => {
        fetchData();
        return () => {
        };
    }, []);

    const fetchData = async () => {
        setLoading(true);
        setTenantId(userLoggedIn?.userDetails?.userInfo?.tenantId);
        setUserId(userLoggedIn?.userDetails?.userInfo?.userId);
        let roleType = userLoggedIn?.userDetails?.userInfo?.roles;
        if (roleType === 'TenantAdmin') {
            setIsTenantAdmin(true);
        }
        await getProjectById();
        await getPeopleByProjectId();
        await getBusinessByProjectId();
        await getProjectRequestById();
        setLoading(false);
    };

    const togglePeopleShowAll = () => {
        setPeopleShowAll(!peopleShowAll);
        setPeopleVisibleCount(peopleShowAll ? 3 : peopleList.length);
    };

    const toggleBusinessShowAll = () => {
        setBusinessShowAll(!businesshowAll);
        setBusinessVisibleCount(businesshowAll ? 3 : businessList.length);
    };

    const toggleLendersShowAll = () => {
        setLendershowAll(!lendershowAll);
        setBusinessVisibleCount(lendershowAll ? 3 : businessList.length);
    };

    async function getProjectById() {
        if (projectId && !isNaN(Number(projectId))) {
            try {
                const data: any = await ProjectService.getProjectWithDetailsID<FullProjectDetails>(parseInt(projectId));
                setProjectDetails(data);

                setProjectPeopleList(data?.projectPeopleRelation);
                setProjectBusinessList(data?.projectBusinessList);
                setNotes(data.notes);
                await getAssignedUserById(data?.project?.assignedToUserId);
                // setDynamicBreadCrumbName(data?.project?.projectName);

            } catch (error) {
                setError('Error fetching people data');
            }
        } else {
            navigate('/admin/projects');
        }
    }

    const getAssignedUserById = async (userId: string) => {
        if (userId) {
            try {
                const data: any = await userService.getUserById(userId);
                setAssignedUser(data);

            } catch (error) {
                setError('Error fetching people data');
            }
        } 
    }

    function formatProjectRequests(data: any) : any[]{
        let requestDetails: ProjectRequestListItem[] = [];
        // const found = array1.find((element) => element > 10);
        data.projectRequest.forEach((request: RequestDetails) => {
            let foundRequestList = requestDetails.find((element) => element.projectRequestId == request.projectRequestId);
            if(!foundRequestList) { 
                foundRequestList = {
                    projectRequestId: request.projectRequestId,
                    projectRequestName: request.projectRequestName,
                    projectRequestList: [],
                }
                requestDetails.push(foundRequestList);
            }

            let projectRequestPerson = foundRequestList.projectRequestList.find((element) =>  element.sentTo == request.sentTo);

            if(!projectRequestPerson) {
                projectRequestPerson = {
                    assignedto: request.assignedto,
                    projectRequestList: [],
                    sentTo: request.sentTo,
                    sentcc: request.sentcc,
                }
                foundRequestList.projectRequestList.push(projectRequestPerson);
            }
            
            projectRequestPerson.projectRequestList.push(request);
            const countSentStatus = projectRequestPerson.projectRequestList.reduce((count, item) => {
                return item.statusName === 'Sent' ? count + 1 : count;
            }, 0);
            setSentCount(countSentStatus);

        })
        console.log("requestDetails", requestDetails);

        return requestDetails;
    }



    async function getProjectRequestById() {
        setLoadingRequest(true);
        if (projectId && !isNaN(Number(projectId))) {
            try {
                const data: any = await ProjectService.getProjectRequestById<RequestDetails>(parseInt(projectId));
                setRequestList(formatProjectRequests(data));
                await setrequestPersonalList(data.projectRequest.filter((request: { documentClassificationName: string; }) => request.documentClassificationName === 'Personal Financials'));
                await setrequestBusinessList(data.projectRequest.filter((request: { documentClassificationName: string; }) => request.documentClassificationName === 'Business Financials'));
                await setrrequestStaffList(data.projectRequest.filter((request: { documentClassificationName: string; }) => request.documentClassificationName === 'Project Financials'));

            } catch (error) {
                setError('Error fetching people data');
            } finally {

            }
        } else {
            navigate('/admin/projects');
        }
        setLoadingRequest(false);
    };

    

    async function getPeopleByProjectId() {
        let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
        if (tenantId && !isNaN(Number(tenantId))) {
            try {
                const data: any = await PeopleService.getPeopleByTenantId(tenantId);
                setPeopleList(data?.peoples);

            } catch (error) {
                setError('Error fetching people data');
            } finally {

            }
        } else {
            navigate('/admin/projects');
        }
    };


    async function getBusinessByProjectId() {

        let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
        if (tenantId && !isNaN(Number(tenantId))) {
            try {
                const data: any = await PeopleService.getBusinessesDetailsByTenantId(tenantId);
                setBusinessList(data?.business);

            } catch (error) {
                setError('Error fetching people data');
            } finally {

            }
        } else {
            navigate('/admin/projects');
        }
    };

    const clickPeopleNavigate = (peopleId: number) => {
        navigate("/admin/people/ViewPeople/" + peopleId);
    }

    const clickBusinessNavigate = (businessId: number) => {
        navigate("/admin/business/ViewBusiness/" + businessId);
    }

    const openModalPeople = async () => {
        const outputArray = peopleList.filter((element, index) => {
            return !projectDetails?.projectPeopleRelation.find((val: any) => {
                return (val.peopleId == element.clientId);
            });
        });
        setPeopleModalList(outputArray);
        
        setIsModalOpenPeople(true);
    };


    const closeModalPeople = async () => {
        const inputArray = peopleList.filter((element, index) => {
            return projectDetails?.projectPeopleRelation.find((val: { peopleId: number; }) => {
                return (val.peopleId == element.clientId);
            });
        });
        setProjectPeopleList(inputArray);
        await getProjectById() ;
        setIsModalOpenPeople(false);
    };

    const closeModalDocRequest = async () => {
        await getProjectRequestById();
        setIsModalOpenDocRequest(false);
    };

    const openModalBusiness = async () => {
        try {
            const data: any = await ProjectService.getprojectBusinessesRelationWithProjectId(parseInt(projectId));

            if (!Array.isArray(data.businessRelation)) {
                console.error("Invalid or empty data received from the API");
                return;
            }

            const projectBusinessIds = new Set(data.businessRelation.map((item: { businessId: any; }) => item.businessId));

            const filteredBusinessList = businessList.filter(business => !projectBusinessIds.has(business.businessId));

            setFilteredBusinessList(filteredBusinessList);
            setIsModalOpenBusiness(true);
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
    };


    const closeModalBusiness = async () => {
        try {
            setLoadingBusiness(true);
            const data: any = await ProjectService.getprojectBusinessesRelationWithProjectId(parseInt(projectId));
            const newBussinessList = data?.businessRelation;
            if (Array.isArray(newBussinessList)) {
                setProjectBusinessList([...newBussinessList]); 
            } else {
                console.error("Data received from ProjectService.getprojectBusinessesRelationWithProjectId is not an array");
            }
            await getProjectById() ;
            setIsModalOpenBusiness(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingBusiness(false);
        }
    };

    const openModalDocRequest = async () => {
        setIsModalOpenDocRequest(true);
    };


    const openModalLenders = async () => {
        setIsModalOpenLenders(true);
    };


    const closeModalLenders = () => {
        setIsModalOpenLenders(false);
    };

    const cancelRequest = () => {
        navigate('/admin/projects');
    };
    const editRequest = () => {

        navigate('/admin/projects/AddProject/' + projectId);
    };

    const onPeopleRelationDeleteClick = async (people: any) => {
       setDeletePeople(people);
       setIsDeletePeopleModalOpen(true);
    };
    

    const onPeopleRelationDeleteCloseClick = () => {
        setIsDeletePeopleModalOpen(false);
    };

    const onPeopleRelationDeleteConfirmClick = async () => {
        try {
            
            const postData: any = await ProjectService.deleteprojectPeopleRelationWithId(deletePeople.projectPeopleId);
            toast({
                title: 'People relation deleted',
                description: deletePeople?.firstName + ' ' + deletePeople.lastName + ' relation to project deleted successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });

            await getPeopleByProjectId();

            onPeopleRelationDeleteCloseClick();

        } catch (error) {
            // Handle error
            console.error('Error linking businesses:', error);
            toast({
                title: 'People delation delete Error',
                description: 'Error deleting '+deletePeople?.firstName + ' ' + deletePeople.lastName +'. relation to the project.',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }


        await getPeopleByProjectId();

        await getProjectById() ;

    };

    const resendEmail = async () => {
        setLoading(true);
        try {
            const email = requestPersonalList[0].sentTo;
            const data: any = await userService.forgotPassword(email);
            toast({
                title: 'Email Reset',
                description: 'Email Reset successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            setLoading(false);
        } catch (error) {
            toast({
                title: 'Error Email Reset',
                description: 'Error While Resetting Email.',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            setLoading(false);
        }
    };

    const onBusinesselationDeleteClick = async (business: any) => {
        setDeleteBusiness(business);
        setIsDeleteBusinessModalOpen(true);
     };
     
 
     const onBusinessRelationDeleteCloseClick = () => {
         setIsDeleteBusinessModalOpen(false);
     };

     const onBusinessRelationDeleteConfirmClick = async () => {


        try {
            
            const postData: any = await ProjectService.deleteprojectBusinessesRelationWithId(deleteBusiness.projectBusinessId);
            toast({
                title: 'Business relation deleted',
                description: deleteBusiness?.businessName + ' relation to project deleted successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });

            await getPeopleByProjectId();

            onBusinessRelationDeleteCloseClick();

        } catch (error) {
            // Handle error
            console.error('Error linking businesses:', error);
            toast({
                title: 'People Relation Delete Error',
                description: 'Error deleting '+deletePeople?.businessName +'. relation to the project.',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }


        await getBusinessByProjectId();

        await getProjectById() ;


     }

    const callUpdateParent = async () => {
        await getProjectRequestById();
    }

    const addNewPersonClick = () => {
        navigate('/admin/people/AddPeople');
    };

    const addNewBusiness = () => {
        navigate('/admin/business/AddBusiness', { state: { businessId: '' } });
    };

    return loading ? <SpinnerPage /> :
        <Card bg={bg} extra={'w-full h-full pb-5 py-6 px-2  cardH'} >
            <Box w={"100%"}>
                <Box float={{ sm: "none", md: "none", lg: "left" }} width={{ sm: "100%", md: "100%", lg: "30%" }} >
                    
                    <FileInformation file={{projectDetails}} assignedUser={assignedUser} />

                    {/* <Box bg={paleGray} mt="10px">
                        <Flex justify="space-between" align="center" p="3" borderTopRadius="xl">
                            <Box>
                                <Flex align="center">
                                    <FaTags style={{ width: "25px", height: "25px", marginRight: "1px" }} />
                                    <Box fontSize="lg" fontWeight="bold" color="navy.700" _dark={{ color: 'white' }}>
                                        Tags
                                    </Box>
                                </Flex>
                            </Box>
                           
                        </Flex>
                    </Box> */}

                    {/* People Information */}
                    <Box bg={paleGray} mt="5px" pr="4">
                        {/* Header */}
                        <Flex p={"10px"} justify="space-between" align="center" mb="4">
                            <Box fontSize="lg" fontWeight="bold" color="navy.700" _dark={{ color: 'white' }} ml="2">
                                People
                            </Box>
                            <CustomButton
                                label={"Add Person"}
                                onClick={addNewPersonClick}
                            />
                            <Box
                                as="button"
                                fontSize="base"
                                fontWeight="medium"
                                color="brand.500"
                                cursor="pointer"
                                transition="background-color 0.2s"
                                _hover={{ bgColor: peopleShowAll ? 'gray.200' : 'whiteAlpha.10' }}
                                _active={{ bgColor: 'gray.200' }}
                                onClick={togglePeopleShowAll}
                            >
                                {peopleShowAll ? 'See Less' : 'See All'}
                            </Box>

                        </Flex>
                        <Grid templateColumns={{ base: "repeat(5, 1fr)", sm: "repeat(4, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(5, 1fr)" }} gap={6}>
                            {/* Add New */}
                            <Box px="10px" color="blue.300" alignItems="center" mb="4" cursor="pointer" _hover={{ color: 'gray.600' }} onClick={openModalPeople}>
                                {/* Render people initials */}
                                <Box mx={"auto"} w="40px" h="40px" borderRadius="full" display="flex" alignItems="center" justifyContent="center">
                                    <Box as="div" fontSize="xl" fontWeight="bold" textAlign="center" >
                                        <GoPlusCircle size={50} />
                                    </Box>
                                </Box>
                                {/* Render people name */}
                                <Box as="div" fontSize="sm" mt="2" textAlign="center">
                                    Add New
                                </Box>
                            </Box>
                            {/* People List */}
                            
                            {projectDetails?.projectPeopleRelation?.slice(0, peopleVisibleCount).map((people: any, index: Key) => (
                                <Box alignItems="center" key={index} mb="4" cursor="pointer" className='projectPeople'
                                    color="gray.600"
                                    _hover={{ color: '#f3f3f3', textShadow: "1px 0px 0px #000" }}

                                    >
                                    {/* Render people initials */}
                                    {isTenantAdmin ? <Box className='closeButton'  color="gray.600"  _hover={{ color: '#797979', textShadow: "1px 0px 0px #000" }}
                                     onClick={() => onPeopleRelationDeleteClick(people)}
                                    >
                                        <IoMdCloseCircleOutline />
                                    </Box> : ""}
                                    <Box onClick={() => clickPeopleNavigate(people.peopleId)}>
                                        <Box
                                            mx={"auto"}
                                            w="40px"
                                            h="40px"
                                            borderRadius="full"
                                            bg="blue.300"

                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Box
                                                as="div"
                                                fontSize="xl"
                                                fontWeight="bold"
                                                textAlign="center"
                                            >
                                                {people.firstName.toUpperCase().charAt(0)}
                                                {people.lastName.toUpperCase().charAt(0)}
                                            </Box>
                                        </Box>
                                        {/* Render people name */}
                                        <Box
                                            as="div"
                                            fontSize="xs"
                                            mt="2"
                                            textAlign="center"
                                            fontWeight={"bold"}
                                        >
                                            {people.firstName} {people.lastName}
                                        </Box>
                                            <Box as="div"
                                            fontSize="xs"
                                            
                                            mt="2"
                                            textAlign="center">
                                                {people.personaName.toUpperCase()}
                                        </Box>
                                    </Box>
                                    
                                </Box>
                            ))}
                        </Grid>
                        {deletePeople ? <ConfirmationDialog
                            isOpen={isDeletePeopleModalOpen}
                            header={'Delete People'}
                            content={"Are you sure you want to delete "+deletePeople?.firstName + " " + deletePeople.lastName+" association? "}
                            onConfirmClick={onPeopleRelationDeleteConfirmClick}
                            onCloseClick={onPeopleRelationDeleteCloseClick}
                        ></ConfirmationDialog> : ""}
                        {/* ProjectsLinkToPeopleModel */}
                        <ProjectsLinkToPeopleModel
                            isOpen={isModalOpenPeople}
                            onClose={closeModalPeople}
                            peopleList={peopleModalList}
                        />
                    </Box>


                    {/* Business Information */}
                    <Box bg={paleGray} mt="5px" pr="4">
                        {/* Header */}
                        <Flex p={"10px"} justify="space-between" align="center" mb="4">
                            <Box fontSize="lg" fontWeight="bold" color="navy.700" _dark={{ color: 'white' }} ml="2">
                                Businesses
                            </Box>
                            <CustomButton label={"Add Business"} onClick={addNewBusiness} />
                            <Box
                                as="button"
                                fontSize="base"
                                fontWeight="medium"
                                color="brand.500"
                                cursor="pointer"
                                transition="background-color 0.2s"
                                _hover={{ bgColor: businesshowAll ? 'gray.200' : 'whiteAlpha.10' }}
                                _active={{ bgColor: 'gray.200' }}
                                onClick={toggleBusinessShowAll}
                            >
                                {businesshowAll ? 'See Less' : 'See All'}
                            </Box>
                        </Flex>
                        {
                            loadingBusiness ? <SpinnerPage /> : 
                                                <Grid templateColumns={{ base: "repeat(5, 1fr)", sm: "repeat(4, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(5, 1fr)" }} gap={6}>
                                                    {/* Add New */}
                                                    <Box px="10px" color="blue.300" alignItems="center" mb="4" cursor="pointer" _hover={{ color: 'gray.600' }} onClick={openModalBusiness}>
                                                        {/* Render people initials */}
                                                        <Box mx={"auto"} w="40px" h="40px" borderRadius="full" display="flex" alignItems="center" justifyContent="center">
                                                            <Box as="div" fontSize="xl" fontWeight="bold" textAlign="center" >
                                                                <GoPlusCircle size={50} />
                                                            </Box>
                                                        </Box>
                                                        {/* Render people name */}
                                                        <Box as="div" fontSize="sm" mt="2" textAlign="center">
                                                            Add New
                                                        </Box>
                                                    </Box>
                                                    {/* People List */}
                                                    {projectDetails?.projectBusinessesRelation?.slice(0, businessVisibleCount).map((business: any, index: Key) => (
                                                        <Box alignItems="center" key={index} mb="4" cursor="pointer"  className='projectPeople'
                                                            color="gray.600"
                                                            _hover={{ color: '#f3f3f3', textShadow: "1px 0px 0px #000" }}
                                                            
                                                        >
                                                            
                                                            {isTenantAdmin ? <Box className='closeButton'  color="gray.600"  _hover={{ color: '#797979', textShadow: "1px 0px 0px #000" }}
                                                                onClick={() => onBusinesselationDeleteClick(business)}
                                                            >
                                                                <IoMdCloseCircleOutline />
                                                            </Box> : ""}
                                                                <Box onClick={() => clickBusinessNavigate(business.businessId)}>
                                                                {/* Render people initials */}
                                                                <Box
                                                                    mx={"auto"}
                                                                    w="40px"
                                                                    h="40px"
                                                                    borderRadius="full"
                                                                    bg="blue.300"

                                                                    display="flex"
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                >
                                                                    <Box
                                                                        as="div"
                                                                        fontSize="xl"
                                                                        fontWeight="bold"
                                                                        textAlign="center"
                                                                    >
                                                                        {business.businessName.toUpperCase().charAt(0)}
                                                                        {business.businessName.toUpperCase().charAt(1)}
                                                                    </Box>
                                                                </Box>
                                                                {/* Render people name */}
                                                                <Box
                                                                    as="div"
                                                                    fontSize="sm"
                                                                    mt="2"
                                                                    textAlign="center"

                                                                >
                                                                    {business.businessName}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Grid>
                        }
                        
                        {/* ProjectsLinkToPeopleModel */}

                        {deleteBusiness ? <ConfirmationDialog
                            isOpen={isDeleteBusinessModalOpen}
                            header={'Delete Business'}
                            content={"Are you sure you want to delete "+deleteBusiness.businessName+" association? "}
                            onConfirmClick={onBusinessRelationDeleteConfirmClick}
                            onCloseClick={onBusinessRelationDeleteCloseClick}
                        ></ConfirmationDialog> : ""}
                        <ProjectsLinkToBusinessModel
                            isOpen={isModalOpenBusiness}
                            onClose={closeModalBusiness}
                            setBusinessList={setSelectedBusinessList}
                            businessList={filteredBusinessList}
                            projectId={projectId}

                        />
                    </Box>


                    
                </Box>





                <Box pl={"15px"} float={{ sm: "none", md: "none", lg: "left" }} width={{ sm: "100%", md: "100%", 'lg': "70%" }} >
                    <Flex justify={{ base: 'center', md: 'flex-end' }}>
                        <CustomButton
                            label={"Back"}
                            onClick={cancelRequest}

                        />
                        <CustomButton
                            label={"Edit"}
                            onClick={editRequest}
                        />
                    </Flex>

                    <Box mt="20px">
                        <Tabs size='lg'>
                            <TabList>
                                <Tab className='text-3xl font-semibold'>Project Details</Tab>
                                <Tab className='text-3xl font-semibold'>Requests</Tab>
                                <Tab className='text-3xl font-semibold'>Notes</Tab>
                                {/*<Tab className='text-3xl font-semibold'>Documents</Tab>*/}
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Card bg={bg} extra={'w-full h-full p-5 sm:overflow-auto px-4'}>
                                        

                                        <Box className="rounded-[10px]"  pl="15px" mt="10px" minH={"400px"}>
                                            <p><span className=' font-semibold'>Purpose: </span>{projectDetails?.project.purpose}</p>
                                            <p><span className=' font-semibold'>Description: </span>{projectDetails?.project.projectDescription}</p>
                                        </Box>
                                    </Card>

                                </TabPanel>
                                <TabPanel>
                                    <Flex justify={{ base: 'center', md: 'flex-end' }} my={4}>
                                        <CustomButton
                                            label={"New Request"}
                                            onClick={openModalDocRequest}
                                            type="submit"
                                        />

                                        {/* ProjectsLinkToPeopleModel */}
                                        <ProjectDocumentRequest
                                            isOpen={isModalOpenDocRequest}
                                            onClose={closeModalDocRequest}
                                            peopleList={peopleList}
                                            projectDetails= {projectDetails}/>

                                    </Flex>
                                    {
                                        loadingRequest? <SpinnerPage /> :
                                        <Accordion defaultIndex={[0]} allowMultiple>
                                            {requestList.map((request: any, key: number) => {
                                                return <AccordionItem  key={key}>
                                                            <h2 >
                                                                <AccordionButton backgroundColor="rgb(244 247 254)">
                                                                

                                                                    <SimpleGrid columns={[2, null, 3]} spacing='40px'>
                                                                        <Box p='4'>
                                                                            <Text fontSize={"12px"} align={"left"}>Request Id : <Text as='b'>{request.projectRequestId}</Text></Text>
                                                                        </Box>
                                                                        <Box fontSize={"12px"} p='4'>
                                                                            Request Name : <Text as='b'>{request.projectRequestName}</Text>
                                                                        </Box>
                                                                        <Box fontSize={"12px"} p='4'>
                                                                            Receipients Count : <Text as='b'>{request.projectRequestList.length}</Text>
                                                                        </Box>
                                                                    </SimpleGrid>
                                                                    {/* <Box className='text-xl font-semibold' as="span" flex='1' textAlign='left'>
                                                                        Personal Tasks | Requests
                                                                    </Box> */}
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel>

                                                                { 
                                                                    request.projectRequestList.map((requestPerson: any, key: number) => {
                                                                        return <AccordionItem key={key}>
                                                                            <AccordionButton>

                                                                                        <SimpleGrid  maxWidth={"98%"}  minWidth={"98%"}  columns={[2, null, 3]} spacing='20px'>
                                                                                            <Box p='1' maxW={"250px"}>
                                                                                                <Text fontSize={"12px"} align={"left"}>Assigned To : <Text as='b'>{requestPerson.assignedto}</Text></Text>
                                                                                            </Box>
                                                                                            <Box p='1'>
                                                                                                <Text fontSize={"12px"} align={"left"}>Sent To : <Text as='b'>{requestPerson.sentTo}</Text></Text>
                                                                                            </Box>
                                                                                            <Box p='1'>
                                                                                        <Text fontSize={"12px"} align={"left"}>Document Count :<Text as='b'>{sentCount}/{requestPerson.projectRequestList.length}</Text></Text>
                                                                                        {/* <CustomButton label='Resend Email' onClick={resendEmail}></CustomButton> */}

                                                                                    </Box>

                                                                                        </SimpleGrid>
                                                                                        <AccordionIcon />
                                                                                    </AccordionButton>
                                                                                    <AccordionPanel pb={4}>
                                                                                        {requestPerson.projectRequestList && requestPerson.projectRequestList.length >0?<FileProjectTable tableData={requestPerson.projectRequestList} callUpdateParent = {callUpdateParent}/> : <Box pt={4}>No Requests</Box> }
                                                                            </AccordionPanel>

                                                                                </AccordionItem> 
                                                                    }) 
                                                                }

                                                            </AccordionPanel>
                                                        </AccordionItem>;
                                            }) }
                                            

                                            {/* <AccordionItem>
                                                <h2 >
                                                    <AccordionButton>
                                                        <Box as="span" className='text-xl font-semibold' flex='1' textAlign='left'>
                                                            Business Tasks | Requests
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    {requestBusinessList && requestBusinessList.length >0?<FileProjectTable tableData={requestBusinessList} /> : <Box pt={4}>No Requests</Box> }
                                                </AccordionPanel>
                                            </AccordionItem>

                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box as="span" className='text-xl font-semibold' flex='1' textAlign='left'>
                                                            Project Tasks | Requests
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    {requestStaffList && requestStaffList.length >0?<FileProjectTable tableData={requestStaffList} /> : <Box pt={4}>No Requests</Box> }
                                                </AccordionPanel>
                                            </AccordionItem> */}
                                        </Accordion>
                                    }
                                    

                                </TabPanel>
                                {/*<TabPanel>*/}
                                {/*    {notes.length ? <NotesTableList tableData={notes}></NotesTableList> : <Box>No Data</Box>}*/}
                                {/*</TabPanel>*/}
                                <TabPanel>
                                    <ProjectNotes notes={notes} projectId={projectId}></ProjectNotes>
                                </TabPanel>
                                <TabPanel>
                                    <Card bg={bg} extra={'w-full h-full p-5 sm:overflow-auto px-4'}>
                                        <Text>Coming Soon....!</Text>
                                    </Card>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Box>
            </Box>
        </Card>;
}