import React, { useEffect, useState } from 'react';
import { Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { Box } from "@chakra-ui/react"
import BusinessListTable from './components/BusinessListTable';
import { Business } from '../../../types/Business';
import { BusinessService } from 'services/BusinessService';
import SpinnerPage from 'components/spinner/spinner';
import Card from 'components/card/Card';
import CustomButton from 'components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { PeopleService } from 'services/PeopleService';
// Custom components

export default function BusinessesPage() {
  const navigate = useNavigate();
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
  const [businessList, setBusinessList] = useState<Business[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const bg = useColorModeValue('background.100', 'background.900');
  const [error, setError] = useState<string | null>(null);
  const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];

  useEffect(() => {
    getBusinessList();
    return () => {
      setBusinessList([]);
    }
  }, []);

  const getBusinessList = async () => {
    try {
      let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
      const data: any = await PeopleService.getBusinessesDetailsByTenantId(tenantId);
      // const data: any = [];
      setBusinessList(data?.business);
    } catch (error) {
      setError('Error fetching people data');
    } finally {
      setLoading(false);
    }
  };

  const addNewBusiness = () => {
    navigate('/admin/business/AddBusiness', { state: { businessId: '' } });
};

  return (

    <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'}>
      {   
        loading ? <SpinnerPage /> : 
                    <Box bg={bg}>
                      {/* View Header Start */}
                        <div className="flex justify-between max-w-full items-center rounded-xl pt-[20px]">
                          <div className="flex  h-[38px] w-[400px] flex h-20 items-center justify-end px-6"></div>
                            <div className="flex  h-[38px] w-[400px] flex h-20 items-center justify-end px-6">
                              <CustomButton label={"Add Business"}  onClick={addNewBusiness} />
                            </div>
                          </div>
                      {businessList.length ? <BusinessListTable tableData={businessList}></BusinessListTable> : <Box>No Records Available</Box> }
                          
                    </Box>
      }
    </Card>

  );
}