import { Icon } from '@chakra-ui/react';
import {
  MdBusiness,
  MdHome,
  MdLock,
  MdOutlineDocumentScanner,
  MdOutlineWarehouse,
  MdPeople,
  MdSettingsApplications,
} from 'react-icons/md';
import { GoProjectRoadmap } from "react-icons/go";


import {
  FaBuildingUser
} from 'react-icons/fa6';
import BusinessesPage from 'views/admin/businesses';
import LendersPage from 'views/admin/lenders';
import PeoplePage from 'views/admin/people';
import AddProject from 'views/admin/projects/AddProject';
// import AddPeople from 'views/admin/people/AddPeople';


// Admin Imports
import Project from 'views/admin/projects';
import Dashboard from 'views/admin/dashboard';

import SignInCentered from 'views/auth/signIn/SignInCentered';
import ViewProject from 'views/admin/projects/ViewProject';
// import ViewPeopleDetailsTable from 'views/admin/people/ViewPeople/components/ViewPeopleDetailsTable';
import TenentsPage from 'views/admin/tenent';
import AddTenentPage from 'views/admin/tenent/AddTenent';
import AddStaffPage from 'views/admin/tenent/AddTenent/AddStaff';
import AccountsPage from 'views/admin/acounts';
import PeopleViewPage from 'views/admin/people/People';
import ViewBusinessPage from 'views/admin/businesses/ViewBusiness';
import ViewLenderPage from 'views/admin/lenders/ViewLenders';
import RequestTemplatePage from 'views/admin/RequestTemplate';
import AddRequestTemplatePage from 'views/admin/RequestTemplate/AddRequestTemplate';
import UploadPage from 'views/upload';


const routesDashboard: RoutesType[] = [
  {
    name: 'Authentication',
    layout: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    path: '/sign-in',
    collapse: false,
    isCommon: true,
    isActive: false,
    component: <SignInCentered />,
  },
  // {
  //   name: 'Dashboard',
  //   layout: '/admin',
  //   path: '/dashboard',
  //   isCommon: true,
  //   isActive: true,
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   collapse: false,
  //   component: <Dashboard />,
  // },
];


const routesDocumentTemplate: RoutesType[] = [
  {
    name: 'Request Template',
    layout: '/admin',
    path: '/requestTemplate',
    isCommon: true,
    isActive: true,
    icon: <Icon as={MdOutlineDocumentScanner} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <RequestTemplatePage />,
    breadCrumb: [{name: "Request Template", link: "/admin/requestTemplate"}]
  },
  {
    name: 'Add Request Template',
    layout: '/admin',
    path: '/requestTemplate/AddRequestTemplate',
    isCommon: true,
    isActive: false,
    icon: <Icon as={MdOutlineDocumentScanner} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddRequestTemplatePage />,
    breadCrumb: [
                  {name: "Request Template", link: "/admin/requestTemplate"},
                  {name: "Add New Request Template", link: "/admin/requestTemplate/AddRequestTemplate"}
                ]
  },
  {
    name: 'View Request Template',
    layout: '/admin',
    path: '/requestTemplate/AddRequestTemplate/:requestTemplateId',
    isCommon: true,
    isActive: false,
    icon: <Icon as={MdOutlineDocumentScanner} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddRequestTemplatePage />,
    breadCrumb: [
      {name: "Request Template", link: "/admin/requestTemplate"},
      {name: "View Request Template", link: "#"}
    ]
  },
];



const routeProject: RoutesType[] = [
  {
    name: 'Projects',
    layout: '/admin',
    path: '/projects',
    isCommon: true,
    isActive: true,
    icon: <Icon as={GoProjectRoadmap} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <Project />,
    breadCrumb: [
      {name: "Projects", link: "/admin/projects"},
    ]
  },
  {
    name: 'New Project Information',
    layout: '/admin',
    path: '/projects/AddProject',
    isCommon: true,
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddProject />,
    breadCrumb: [
      {name: "Projects", link: "/admin/projects"},
      {name: "Add Project", link: "/admin/projects/AddProject"},
    ]
  },
  {
    name: 'Edit Project Information',
    layout: '/admin',
    path: '/projects/AddProject/:projectId',
    isCommon: true,
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddProject />,
    breadCrumb: [
      {name: "Projects", link: "/admin/projects"},
      {name: "Edit Project", link: "#"},
    ]
  },
  {
    name: 'View Project',
    layout: '/admin',
    path: '/projects/ViewProject/:projectId',
    isCommon: true,
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <ViewProject />,
    breadCrumb: [
      {name: "Projects", link: "/admin/projects"},
      {name: "View Project", link: "#"},
    ]
  },
  // {
  //   name: 'Project Information',
  //   layout: '/admin',
  //   path: '/projects/ViewProject/:id',
  //   isCommon: true,
  //   isActive: false,
  //   icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
  //   collapse: false,
  //   component: <ViewProject />,
  //   breadCrumb: [
  //     {name: "Projects", link: "/admin/projects"},
  //     {name: "View Project", link: "#"},
  //   ]
  // },
];

const routePeople: RoutesType[] = [ 
  {
    name: 'People',
    layout: '/admin',
    path: '/people',
    isCommon: true,
    isActive: true,
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <PeoplePage />,
    breadCrumb: [
      {name: "People", link: "/admin/people"},
    ]
  },
  {
    name: 'View People',
    layout: '/admin',
    path: '/people/ViewPeople/:peopleId',
    isCommon: true,
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <PeopleViewPage />,
    breadCrumb: [
      {name: "People", link: "/admin/people"},
      {name: "View People", link: "#"},
    ]
  },
  {
    name: 'Add New Person',
    layout: '/admin',
    path: '/people/AddPeople',
    isCommon: true,
    isTenantAdmin: true,
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <PeopleViewPage />,
    breadCrumb: [
      {name: "People", link: "/admin/people"},
      {name: "Add new People", link: "/admin/people/AddPeople"},
    ]
  },
  {
    name: 'Add New Business',
    layout: '/admin',
    path: '/business/AddBusiness',
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <ViewBusinessPage />,
    breadCrumb: [
      {name: "Business", link: "/admin/businesses"},
      {name: "Add New Business", link: "/admin/business/AddBusiness"},
    ]
  },
  {
    name: 'View Business',
    layout: '/admin',
    path: '/business/ViewBusiness/:businessId',
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <ViewBusinessPage />,
    breadCrumb: [
      {name: "Business", link: "/admin/businesses"},
      {name: "View Business", link: "#"},
    ]
  },
  {
    name: 'Add New Lender',
    layout: '/admin',
    path: '/lenders/AddLenders',
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <ViewLenderPage />,
  },
  {
    name: 'View Lender',
    layout: '/admin',
    path: '/lenders/AddLenders/:lenderId',
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <ViewLenderPage />,
  },
];

const routeBusinesses: RoutesType[] = [ 
  {
    name: 'Business',
    layout: '/admin',
    path: '/businesses',
    isCommon: true,
    isActive: true,
    icon: <Icon as={MdBusiness} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <BusinessesPage />,
    breadCrumb: [
      {name: "Business", link: "/admin/businesses"},
    ]
  },
];

const routeLenders: RoutesType[] = [
  {
    name: 'Lenders',
    layout: '/admin',
    path: '/lenders',
    isActive: true,
    isCommon: true,
    icon: <Icon as={MdOutlineWarehouse} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <LendersPage />,
  },
];

export const routeAccount: RoutesType[] = [
  {
    name: 'Profile',
    layout: '/admin',
    path: '/profile',
    isCommon: true,
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AccountsPage />,
    breadCrumb: [
      {name: "Profile", link: "#"}
    ]
  },
];

export const routeTenant_Admin: RoutesType[] = [
  {
    name: 'Tenants',
    layout: '/admin',
    path: '/tenent',
    isCommon: false,
    isActive: true,
    isTenantAdmin: false,
    isAdmin: true,
    icon: <Icon as={FaBuildingUser} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <TenentsPage />,
    breadCrumb: [
      {name: "Tenants", link: "#"}
    ]
  },
  {
    name: 'Tenant',
    layout: '/admin',
    isAdmin: true,
    isTenantAdmin: true,
    isCommon: false,
    path: '/tenant/AddTenant',
    isActive: false,
    icon: <Icon as={FaBuildingUser} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddTenentPage />,
    breadCrumb: [
      {name: "Tenants", link: "/admin/tenent"},
      {name: "Add Tenant", link: "#"}
    ]
  },{
    name: 'Add New Staff',
    layout: '/admin',
    isAdmin: true,
    isTenantAdmin: true,
    isCommon: false,
    path: '/tenant/AddTenant/AddStaff',
    isActive: false,
    icon: <Icon as={MdSettingsApplications} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddStaffPage />,
    breadCrumb: [
      {name: "Tenants", link: "/admin/tenent"},
      {name: "View Tenant", link: "/admin/tenant/AddTenant"},
      {name: "Add Staff", link: "#"}
    ]
  },
];

export const routeTenant_TenantAdmin: RoutesType[] = [
  {
    name: 'Tenant',
    layout: '/admin',
    isAdmin: false,
    isTenantAdmin: true,
    isCommon: false,
    path: '/tenant/AddTenant',
    isActive: true,
    icon: <Icon as={FaBuildingUser} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddTenentPage />,
    breadCrumb: [
      {name: "View Tenant", link: "#"}
    ]
  },{
    name: 'Add New Staff',
    layout: '/admin',
    isAdmin: true,
    isTenantAdmin: true,
    isCommon: false,
    path: '/tenant/AddTenant/AddStaff',
    isActive: false,
    icon: <Icon as={FaBuildingUser} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <AddStaffPage />,
    breadCrumb: [
      {name: "View Tenant", link: "/admin/tenant/AddTenant"},
      {name: "Add Tenant", link: "#"}
    ]
  },
];

export const routeTenant_Staff: RoutesType[] = [
  // {
  //   name: 'View People',
  //   layout: '/admin',
  //   isAdmin: false,
  //   isTenantAdmin: false,
  //   isCommon: false,
  //   path: '/people/ViewPeopleDemo',
  //   isActive: true,
  //   icon: <Icon as={FaBuildingUser} width="20px" height="20px" color="inherit" />,
  //   collapse: false,
  //   component: <PeopleDemoPage />,
  // },
];


export const routeUpload: RoutesType[] = [
  {
    name: 'Upload Documents',
    layout: '/upload',
    isAdmin: false,
    isTenantAdmin: false,
    isCommon: true,
    path: ':token',
    isActive: true,
    icon: <Icon as={FaBuildingUser} width="20px" height="20px" color="inherit" />,
    collapse: false,
    component: <UploadPage />,
  },
];


export const routeDefault: RoutesType[] = [
    ...routeProject, ...routePeople, ...routeBusinesses, ...routeLenders, ...routesDocumentTemplate, ...routesDashboard,
];

// export const routes: RoutesType[] = [
//   ...routeCommon, 
// ];

export const getAllValidRoutes = () => {

  const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
  let activeRoutes: RoutesType[] = [];
  // let roleType = "Staff";
  let roleType = userLoggedIn?.userDetails?.userInfo?.roles
  if (routeDefault) { 
    for (let link = 0; link < routeDefault.length; link++) {
      if(routeDefault[link]) {
        activeRoutes.push(routeDefault[link]);
      }
    }
  } 

    if (roleType === "Admin" && routeTenant_Admin) {
    for (let link = 0; link < routeTenant_Admin.length; link++) {
      if(routeTenant_Admin[link]) {
        activeRoutes.push(routeTenant_Admin[link]);
      }
    }
  } else if (roleType === "TenantAdmin" && routeTenant_TenantAdmin) { 
    for (let link = 0; link < routeTenant_TenantAdmin.length; link++) {
      if(routeTenant_TenantAdmin[link]) {
        activeRoutes.push(routeTenant_TenantAdmin[link]);
      }
    }
  } else if (roleType === "Staff" && routeTenant_Staff) { 
    for (let link = 0; link < routeTenant_Staff.length; link++) {
      if(routeTenant_Staff[link]) {
        activeRoutes.push(routeTenant_Staff[link]);
      }
    }
  }

  if (routeAccount) { 
    for (let link = 0; link < routeAccount.length; link++) {
      if(routeAccount[link]) {
        activeRoutes.push(routeAccount[link]);
      }
    }
  }

  return activeRoutes;

}



export const getActiveRoutes = () => {
  const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
  let activeRoutes: RoutesType[] = [];
  // let roleType = "Staff";
  let roleType = userLoggedIn?.userDetails?.userInfo?.roles
  

  if(roleType === "Admin" && routeTenant_Admin) {

    // for (let link = 0; link < routesDashboard.length; link++) {
    //     if(routesDashboard[link].isActive) {
    //       activeRoutes.push(routesDashboard[link]);
    //     }
    // }
  
      for (let link = 0; link < routeAccount.length; link++) {
        if(routeAccount[link].isActive) {
          activeRoutes.push(routeAccount[link]);
        }
      }
    for (let link = 0; link < routeTenant_Admin.length; link++) {
      if(routeTenant_Admin[link].isActive) {
        activeRoutes.push(routeTenant_Admin[link]);
      }
    }

    return activeRoutes;
  } 


  if (routeDefault) { 
    for (let link = 0; link < routeDefault.length; link++) {
      if(routeDefault[link].isActive) {
        activeRoutes.push(routeDefault[link]);
      }
    }
  } 

  if (roleType === "TenantAdmin" && routeTenant_TenantAdmin) { 
    for (let link = 0; link < routeTenant_TenantAdmin.length; link++) {
      if(routeTenant_TenantAdmin[link].isActive) {
        activeRoutes.push(routeTenant_TenantAdmin[link]);
      }
    }
  } else if (roleType === "Staff" && routeTenant_Staff) { 
    for (let link = 0; link < routeTenant_Staff.length; link++) {
      if(routeTenant_Staff[link].isActive) {
        activeRoutes.push(routeTenant_Staff[link]);
      }
    }
  }

  if (routeAccount) { 
    for (let link = 0; link < routeAccount.length; link++) {
      if(routeAccount[link].isActive) {
        activeRoutes.push(routeAccount[link]);
      }
    }
  }

  return activeRoutes;
}




export const getDynamicLinks = (searchString: string) => {
  if(searchString.startsWith("/admin/projects")) {
      return ':projectId';
  } else if (searchString.startsWith("/admin/business")) {
    return ':businessId';
  } else if (searchString.startsWith("/admin/people")) {
    return ':peopleId';
  } else if (searchString.startsWith("/admin/requestTemplate")) {
    return ':requestTemplateId';
  } else if (searchString.startsWith("/admin/lenders")) {
    return ':lenderId';
  }
}

export const getFilteredSearchLink = (searchString: string) => {
  let splitStrings = searchString.split('/');
  let returnString = "";
  let searchFString = "";
  if(splitStrings && splitStrings.length > 0 && !isNaN(splitStrings[splitStrings.length-1] as any)) {
    let setString = getDynamicLinks(searchString);
    
    for(var i=0; i<splitStrings.length-1;i++ ) {
      if(splitStrings[i]) {
        searchFString = searchFString+"/" + splitStrings[i];
      }
      
    }
    searchFString = searchFString+"/" + setString;
    returnString = searchFString;
  } else {
    returnString = searchString;
  }

  return returnString;
}

export const setDynamicBreadCrumbName = (name: string) => { 
  const [breadCrumbs] = [JSON.parse(localStorage.getItem("breadCrumbs"))];
  let breadCrumbsEdit = breadCrumbs? [...breadCrumbs] : [];
  if(breadCrumbsEdit.length >0 ) {
    breadCrumbsEdit[breadCrumbsEdit.length-1].name = name;
    localStorage.setItem("breadCrumbs", JSON.stringify(breadCrumbsEdit));
  }
  return ;
}