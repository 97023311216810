// Chakra Imports
import {
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  layout,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import { useAuth } from "react-oidc-context";
import { routeDefault, routeTenant_Admin, routeTenant_TenantAdmin, routeTenant_Staff, routeAccount } from 'routes';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import Configurator from 'components/navbar/Configurator';
import { useNavigate } from 'react-router';
import { useState } from 'react';
export default function HeaderLinks(props: {
  secondary: boolean;
  [x: string]: any;
  layout: string;
}) {

  const auth = useAuth();
  const navigate = useNavigate();
  const { secondary, theme, setTheme, layout } = props;
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );

  const logoutUser = () => {
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("userLoginToken");
    auth.signoutSilent();
    navigate('/');
  }

  const [userLoggedIn] = useState(
    JSON.parse(localStorage.getItem("userLoginStatus"))
  );

  const [userInfo] = useState(userLoggedIn?.userDetails?.userInfo ? userLoggedIn?.userDetails?.userInfo?.firstName:userLoggedIn?.userDetails?.userName  );
  
   
  
  if(!userLoggedIn || !(userLoggedIn?.isUserLoggedIn) ) {
    navigate("/");
  }


  const getActiveRoutes = () => {
    let activeRoutes: RoutesType[] = [];
    // let roleType = "Staff";
    let roleType = userLoggedIn?.userDetails?.userInfo?.roles
    if (routeDefault) { 
      for (let link = 0; link < routeDefault.length; link++) {
        if(routeDefault[link].isActive) {
          activeRoutes.push(routeDefault[link]);
        }
      }
    } 

    if(roleType === "Admin" && routeTenant_Admin) {
      for (let link = 0; link < routeTenant_Admin.length; link++) {
        if(routeTenant_Admin[link].isActive) {
          activeRoutes.push(routeTenant_Admin[link]);
        }
      }
    } else if (roleType === "TenantAdmin" && routeTenant_TenantAdmin) { 
      for (let link = 0; link < routeTenant_TenantAdmin.length; link++) {
        if(routeTenant_TenantAdmin[link].isActive) {
          activeRoutes.push(routeTenant_TenantAdmin[link]);
        }
      }
    } else if (roleType === "Staff" && routeTenant_Staff) { 
      for (let link = 0; link < routeTenant_Staff.length; link++) {
        if(routeTenant_Staff[link].isActive) {
          activeRoutes.push(routeTenant_Staff[link]);
        }
      }
    }

    if (routeAccount) { 
      for (let link = 0; link < routeAccount.length; link++) {
        if(routeAccount[link].isActive) {
          activeRoutes.push(routeAccount[link]);
        }
      }
    }

    return activeRoutes;
  }


  function clickProfile(): void {
    navigate('/admin/profile');
  }

  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      {/* <SearchBar
        mb={() => {
          if (secondary) {
            return { base: '10px', md: 'unset' };
          }
          return 'unset';
        }}
        me="10px"
        borderRadius="20px"
      /> */}
      <Box minWidth={"100px"}></Box>
      <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius="20px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="20px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={getActiveRoutes()} layout={layout} />
      <Menu>
        <MenuButton p="0px">
          <Icon
            mt="6px"
            as={MdNotificationsNone}
            color={navbarIcon}
            w="18px"
            h="18px"
            me="10px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="20px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          me={{ base: '30px', md: 'unset' }}
          minW={{ base: 'unset', md: '400px', xl: '450px' }}
          maxW={{ base: '360px', md: 'unset' }}
        >
          <Flex w="100%" mb="20px">
            <Text fontSize="md" fontWeight="600" color={textColor}>
              Notifications
            </Text>
            <Text
              fontSize="sm"
              fontWeight="500"
              color={textColorBrand}
              ms="auto"
              cursor="pointer"
            >
              Mark all read
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              px="0"
              borderRadius="8px"
              mb="10px"
            >
              <Box>Coming Soon</Box>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>

      
      <Configurator
        mini={props.mini}
        setMini={props.setMini}
        theme={theme}
        setTheme={setTheme}
      />

      <Menu>
        <MenuButton p="0px">
          {/* <Avatar
            _hover={{ cursor: 'pointer' }}
            w="40px"
            h="40px"
            icon={<Icon as={FaRegCircleUser} width="20px" height="20px" color="inherit" />}
          /> */}

          <Avatar  size="sm" 
         
          _hover={{ cursor: 'pointer' }}
           w="40px"
           h="40px"/>
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {userInfo}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick = {() => clickProfile()}
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={() => logoutUser()}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
