

// Chakra imports
import { useEffect, useState } from 'react';
import { Box, useColorModeValue } from "@chakra-ui/react"
import TenentListTable from './components/TenentListTable';
import { Tenant } from '../../../types/Tenant';
import { tenantService } from 'services/TenantService';
import SpinnerPage from 'components/spinner/spinner';
import Card from 'components/card/Card';


// Custom components

export default function TenentsPage() {
    const [accountList, setAccountList] = useState<Tenant[] | []>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const bg = useColorModeValue('background.100', 'background.900');

    useEffect(() => {
        getAccountList();
        return () => {
            setAccountList([]);
        }
    }, []);

    const getAccountList = async () => {
        try {
            const data: any = await tenantService.gettenant<Tenant>();
            setAccountList(data);
        } catch (error) {
            setError('Error fetching people data');
        } finally {
            setLoading(false);
        }
    };
    return (
        <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'} paddingTop="25">
            {
                loading ? <SpinnerPage /> :
                    accountList.length ?
                        <TenentListTable tableData={accountList}></TenentListTable> :
                        <Box>No Records Available</Box>
            }
        </Card>

    );
}
