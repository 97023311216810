import { AxiosError } from "axios";
import { useAuth } from "react-oidc-context";

export interface Data<T> {
    data: any
}

export interface ApiResponse<T> {
    data: Data<T>;
}

export const handleApiError = (error: AxiosError) => {
    if (error.response) {
      console.error('API Error Response:', error.response.data);
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
};

export const BASE_URL = 'https://demo-api.minerva.zyq.ai'; // Replace with your API endpoint
// export const BASE_URL = process.env.REACT_API_BASE_URL; // Replace with your API endpoint

export function getAuthHeader() :  any {
    const userToken = localStorage.getItem("userLoginToken");
    return { headers: { Authorization: 'Bearer ' + userToken } };
}