import React from 'react';

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
} from '@tanstack/react-table';
import { Box, useColorModeValue } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { Lenders } from '../../../../types/Lender';
import { TableViewComponent } from 'components/table/tablecomponent';

function LenderListTable(props: { tableData: any }) {
    const navigate = useNavigate();
    const [userLoggedIn] = React.useState(
        JSON.parse(localStorage.getItem('userLoginStatus')),
    );
    const bg = useColorModeValue('background.100', 'background.900');
    const { tableData } = props;
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [],
    );
    let defaultData = tableData;
    // const [tableView, setTableView] = React.useState(true);
    // const [fileDetailsView, setFileDetailsView] = React.useState(false);
    const [listViewCount, setListViewCount] = React.useState(6);
    const [globalFilter, setGlobalFilter] = React.useState('');

    const addNewBusiness = () => {
        navigate('/admin/business/AddBusiness', { state: { businessId: '' } });
    };

    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };
    const columns = [
        columnHelper.accessor('name', {
            id: 'name',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                     Name
                </p>
            ),
            cell: (info) => (
                    <p
                        style={{ textDecoration: 'underline' }}
                        color={activeColor}
                        className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                        onClick={() => {
                            onLenderClick(info.row.original.lenderID, true);
                        }}
                    >
                    {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('contactAddress', {
            id: 'contactAddress',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Address
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        // columnHelper.accessor('businessType', {
        //     id: 'businessType',
        //     header: () => (
        //         <p className="text-sm font-bold text-gray-600 dark:text-white">
        //             Business Type
        //         </p>
        //     ),
        //     cell: (info) => (
        //         <p color={activeColor} className="text-sm font-bold dark:text-white">
        //             {info.getValue()}
        //         </p>
        //     ),
        // }),
        columnHelper.accessor('phoneNumber', {
            id: 'phoneNumber',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Phone
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                   Email
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('licensingDetails', {
            id: 'licensingDetails',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                   Licensing Details
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('commercialMortgageProducts', {
            id: 'commercialMortgageProducts',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Mortgage Products
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('interestRates', {
            id: 'interestRates',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                  Interest Rates
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('terms', {
            id: 'terms',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                Terms
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('loanToValueRatio', {
            id: 'loanToValueRatio',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
               Value Ratio
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('applicationProcessDetails', {
            id: 'applicationProcessDetails',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
              Application Details
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('underwritingGuidelines', {
            id: 'underwritingGuidelines',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
             Guidelines
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('closingCostsAndFees', {
            id: 'closingCostsAndFees',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
             Closing Fee
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
        columnHelper.accessor('specializedServices', {
            id: 'specializedServices',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
             Specialized Services
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);

    const [listFilterData, setListFilterData] = React.useState(() => [
        ...defaultData,
    ]);

    const [peopleId, setPeopleId] = React.useState(1);
    const [selectedPeople, setSelectedPeople] = React.useState(null);
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 15,
        });
    let activeColor = useColorModeValue('gray.700', 'white');

    // const addNewProjectClick = () => {
    //   navigate('/admin/dashboards/AddProject');
    // };

    const onLenderClick = (lenderId: any, flag: any) => {
        // let selectedPeople = data.find((people) => people.peopleId === peopleId);
        // selectedPeople(selectedPeople);
        // setPeopleId(selectedPeople);
        navigate('/admin/lenders/AddLenders/'+lenderId, {
            state: { lenderId: lenderId },
        });
    };

    const onChangeSearchFilter = (e: any) => {
        setGlobalFilter(e.target.value);
        setListViewCount(6);
        let filterData: any[] = [];
        table.getFilteredRowModel().rows.forEach((row) => {
            filterData.push(row.original);
        });
        setListFilterData(filterData);
    };

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    return (
        <Box px={"10px"} bg={bg}>
            <GetTableView />
        </Box>
    );

    function GetTableView(props: any) {
        return <div className="overflow-x-scroll xl:overflow-x-hidden">
             {(defaultData && defaultData?.length > 0) ? <TableViewComponent
                columnsList={columns}
                tableData={defaultData}
                enablePagination={defaultData?.length > 15 ? true : false}
                enableSearch={true}
                saveButtonValue={null}
                saveButtonClick={null}
                cancelButtonValue={null}
                cancelButtonClick={null}
                viewSaveCancel = {true}
            ></TableViewComponent> : <div>No Records Available</div>}
        </div>

    }

    
}

export default LenderListTable;
const columnHelper = createColumnHelper<Lenders>();
