// src/services/apiService.ts
import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL } from './AxiosAuthService'; // Replace with your API endpoint

interface Data<T> {
    data: any
}

interface ApiResponse<T> {
    data: Data<T>;
}

export const PeopleService = {
    getState: async <T>(endpoint: string): Promise<T> => {
        try {
            //const response: any = await axios.get(`https://demo-api.minerva.zyq.ai/states`);
            const response: any = await axios.get(`${BASE_URL}/states`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, createPepople: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/people`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updatePeople: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${BASE_URL}/people`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getPepoples: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/people`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getBusinessDetailsById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/peopleBusinessRelation/clientsRelation/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, deleteBusinessDetailsById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${BASE_URL}/peopleBusinessRelation/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPepoplesById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/people/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getBusinessesDetailsByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/tenant/businessesByTenant/${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPeopleByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/tenant/peoplesByTenant/${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }
};




const handleApiError = (error: AxiosError) => {
    if (error.response) {
        console.error('API Error Response:', error.response.data);
    } else if (error.request) {
        console.error('API No Response:', error.request);
    } else {
        console.error('API Error:', error.message);
    }
};
