// src/services/apiService.ts
import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL } from './AxiosAuthService'; // Replace with your API endpoint
 
interface Data<T>{
    data:any
}

interface ApiResponse<T> {
  data:Data<T> ;
}
export const tenantService = {
    createtenant: async <T>(request: any): Promise<T> => {
      try {
        const response: any = await axios.post(`${BASE_URL}/tenant`,request, getAuthHeader());
        return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },gettenant: async <T>(): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/tenant`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },gettenantById: async <T>(id:number): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/tenant/${id}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    }
    ,updatetenantById: async <T>(values:any): Promise<T>=>{
      try{
          const response: any = await axios.put(`${BASE_URL}/tenant`,values, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getBussinessByTenantId: async <T>(id:number): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/tenant/businessesByTenant/${id}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    }, getProjectByTenantId: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/project/projectList`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectTenantById: async <T>(id: number): Promise<T> => {
        try {
          // const response: any = await axios.get(`${BASE_URL}/project`, getAuthHeader());
          // return response.data;
            const response: any = await axios.get(`${BASE_URL}/tenant/projectByTenant/${id}`, getAuthHeader());
            return response.data.projects;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getStatuesByTenant: async <T>(tenantId: number, projectRequeststatus :number): Promise<T> => {
        try {
            // const response: any = await axios.get(`${BASE_URL}/project`, getAuthHeader());
            // return response.data;
            const response: any = await axios.get(`${BASE_URL}/tenant/getStatuesByTenant/${tenantId}/${projectRequeststatus}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }
  };
  
  
  
  
  const handleApiError = (error: AxiosError) => {
    if (error.response) {
      console.error('API Error Response:', error.response.data);
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
  };
  