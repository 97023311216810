/* eslint-disable */
// Chakra Imports
import {
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { getAllValidRoutes, getDynamicLinks } from 'routes';
import { useNavigate } from 'react-router-dom';

export default function AdminNavbar(props: {
  secondary: boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  breadCrumbs?: any;
  onOpen: (...args: any[]) => any;
  [x: string]: any;
}) {
  const [scrolled, setScrolled] = useState(false);
  const [activeRoute, setActiveRoute] = useState<RoutesType>();
  const [currentPath, setCurrentPath] = useState<string>();

  const [breadCrumbProps, setBreadCrumbProps] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    let activeRoute = getActiveRouteDisplayName(getAllValidRoutes());
    setActiveRoute(activeRoute);
    window.addEventListener('scroll', changeNavbar);

    return () => {
      window.removeEventListener('scroll', changeNavbar);
    };
  });

  const {
    secondary,
    brandText,
    mini,
    setMini,
    theme,
    setTheme,
    hovered,
    breadCrumbs,
  } = props;


  const [breadCrumbList, setBreadCrumbList] = useState<{name: string, url: string, route: RoutesType}[]>(( JSON.parse(localStorage.getItem("breadCrumbs"))));

  useEffect(() => {
    localStorage.setItem('breadCrumbs', JSON.stringify(breadCrumbList));
  }, [breadCrumbList]);
  

  const getActiveRouteDisplayName = (routes: RoutesType[]): RoutesType => {
    let activeRoute = 'Page';
    let searchString = window.location.pathname;
    setCurrentPath(searchString);
    let splitStrings = searchString.split('/');
    let searchFString = "";
    if(splitStrings && splitStrings.length > 0 && !isNaN(splitStrings[splitStrings.length-1] as any)) {
      let setString = getDynamicLinks(searchString);
      
      for(var i=0; i<splitStrings.length-1;i++ ) {
        if(splitStrings[i]) {
          searchFString = searchFString+"/" + splitStrings[i];
        }
      }
      searchFString = searchFString+"/" + setString;
      searchString = searchFString;
    } 


    for (let i = 0; i < routes?.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRouteDisplayName(routes[i].items);
        if (collapseActiveRoute ) {
          return collapseActiveRoute;
        }
      } else {
        if (
          searchString == (routes[i].layout + routes[i].path)
        ) {
          return routes[i];
        }
      }
    }

    return null;
  };

  useEffect(() => {
    setBreadCrumbProps(props.breadCrumbs);
  }, [props]);


  const breadCrumbClick = (index: any, breadCrumb: any) => {
    let breadCrumns = [...breadCrumbProps];
    breadCrumns.length = index;
    setBreadCrumbProps(breadCrumns);
    setBreadCrumbList(breadCrumns);
  
    if(breadCrumb) {
      navigate(breadCrumb?.url);
    }
    return false;
  }

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue('navy.700', 'white');
  let secondaryText = useColorModeValue('gray.700', 'white');
  let navbarPosition = 'fixed' as const;
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue(
    'rgba(244, 247, 254, 0.2)',
    'rgba(11,20,55,0.5)',
  );
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  return (
    <Box

      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH="75px"
      justifyContent={{ xl: 'center' }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
      px={{
        sm: paddingX,
        md: '10px',
      }}
      ps={{
        xl: '12px',
      }}
      pt="8px"
      top={{ base: '0px', md: '0px', xl: '0px' }}
      w={
        mini === false
          ? {
              base: 'calc(100vw - 6%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 350px)',
              '2xl': 'calc(100vw - 350px)',
            }
          : mini === true && hovered === true
          ? {
              base: 'calc(100vw - 6%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 350px)',
              '2xl': 'calc(100vw - 350px)',
            }
          : {
              base: 'calc(100vw - 6%)',
              md: 'calc(100vw - 8%)',
              lg: 'calc(100vw - 6%)',
              xl: 'calc(100vw - 185px)',
              '2xl': 'calc(100vw - 200px)',
            }
      }
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: 'column',
          md: 'row',
        }}
        alignItems={{ xl: 'center' }}
        mb={gap}
      >
        <Box mb={{ sm: '8px', md: '0px' }}>
          <Breadcrumb marginBottom={"10px"}>
          <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
              <BreadcrumbLink href="/" color={secondaryText}>
                Dashboard
              </BreadcrumbLink>
            </BreadcrumbItem>
            {breadCrumbProps?.map((breadCrumb: any, index: any) => {
                return <BreadcrumbItem key={index} color={secondaryText} fontSize="sm" onClick={() => breadCrumbClick(index, breadCrumb)}>
                          <BreadcrumbLink as={Link} to={breadCrumb?.url} color={secondaryText}>
                             {breadCrumb?.name}
                          </BreadcrumbLink>
                        </BreadcrumbItem>
            })}
            
          </Breadcrumb>
          {/* Here we create navbar brand, based on route name */}
          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            fontSize="34px"
            marginTop={"5px"}
            _hover={{ color: { mainText } }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            {brandText}
          </Link>
        </Box>
        <Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
          <AdminNavbarLinks
            mini={mini}
            setMini={setMini}
            theme={theme}
            setTheme={setTheme}
            secondary={props.secondary}
            layout = {'/admin'}
          />
        </Box>
      </Flex>
    </Box>
  );
}
