import React, { useEffect } from 'react';

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
} from '@tanstack/react-table';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    SimpleGrid,
    Switch,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';

import FileInformation from './FileInformation';
import { useNavigate } from 'react-router-dom';
import SearchIcon from 'components/icons/SearchIcon';
import CustomButton from '../../../../components/button/CustomButton';
import { TableViewComponent } from 'components/table/tablecomponent';
import { Projects } from '../../../../types/Projects';
import FileListViewInformation from './FileListViewInformation';
import moment from 'moment-timezone';

// import FileInformation from './FileInformation';
// import FileDetailsView from '../data-tables/FileDetailsView';

function ProjectListTable(props: { tableData: any }) {
    const navigate = useNavigate();

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const brandColor = useColorModeValue('brand.500', 'white');
    const bg = useColorModeValue('background.100', 'background.900');
    const bgActive = useColorModeValue('background.800', 'background.900');
    const { tableData } = props;
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [],
    );
    let defaultData = tableData;
    const [tableView, setTableView] = React.useState(true);
    const [fileDetailsView, setFileDetailsView] = React.useState(false);
    const [listViewCount, setListViewCount] = React.useState(6);
    const [globalFilter, setGlobalFilter] = React.useState('');
    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };
    const columns = [
        //columnHelper.accessor('projectId', {
        //    id: 'projectId',
        //    size: 10, // Adjust the size according to your preference
        //    header: () => (
        //        <p className="text-sm font-bold text-gray-600 dark:text-white">
        //            Id
        //        </p>
        //    ),
        //    cell: (info) => (
        //        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        //            <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white" style={{ flex: '0 0 auto' }}>
        //                Id
        //            </p>
        //            <p
        //                color={activeColor}
        //                className="text-sm font-bold dark:text-white"
        //                style={{ flex: '1', width: '0', minWidth: '0' }} // Set flex-grow and minimum width to allow flex items to fill remaining space
        //            >
        //                {info.getValue()}
        //            </p>
        //        </div>
        //    ),
        //}),

        columnHelper.accessor('projectName', {
            id: 'projectName',
            size: 100,
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Name
                </p>
            ),
            cell: (info) => (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white" style={{ flex: '0 0 auto' }}>
                        Name
                    </p>
                    <p
                        style={{ textDecoration: 'underline', flex: '1', width: '0', minWidth: '0'} }
                        color={activeColor}
                        className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600" 
                        onClick={() => {
                            onFileViewClick(info.row.original.projectId, true);
                        }}
                    >
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),
        columnHelper.accessor('purpose', {
            id: 'purpose',
            size: 80,
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Purpose
                </p>
            ),
            cell: (info) => (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white" style={{ flex: '0 0 auto' }}>
                        Purpose
                    </p>
                    <p
                        style={{flex: '1', width: '0', minWidth: '0' }}
                        color={activeColor}
                        className="text-sm font-bold dark:text-white"
                    >
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),
        columnHelper.accessor('amount', {
            id: 'amount',
            size: 80,
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Amount
                </p>
            ),
            cell: (info) => (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white" style={{ flex: '0 0 auto' }}>
                        Amount
                    </p>
                    <p
                        style={{ flex: '1', width: '0', minWidth: '0' }}
                        color={activeColor}
                        className="text-sm font-bold dark:text-white">
                        ${new Intl.NumberFormat("en-US").format(parseFloat(info.row.original.amount))}
                    
                        {/*{info.getValue()}*/}
                    </p>
                </div>
            ),
        }),


        columnHelper.accessor('industrySector', {
            id: 'industrySector',
            size: 80, // Adjust the size according to your preference
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Industry
                </p>
            ),
            cell: (info) => (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white" style={{ flex: '0 0 auto' }}>
                        Industry
                    </p>
                    <p
                        color={activeColor}
                        className="text-sm font-bold dark:text-white"
                        style={{ flex: '1', width: '0', minWidth: '0' }} // Set flex-grow and minimum width to allow flex items to fill remaining space
                    >
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),
        columnHelper.accessor('createdDateTime', {
            id: 'createdDateTime',
            size: 80, // Adjust the size according to your preference
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Created On
                </p>
            ),
            cell: (info) => (
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white" style={{ flex: '0 0 auto' }}>
                        Created On
                    </p>
                    <p
                        color={activeColor}
                        className="text-sm font-bold dark:text-white"
                        style={{ flex: '1', width: '0', minWidth: '0' }} // Set flex-grow and minimum width to allow flex items to fill remaining space
                    > 
                        {/*{moment(new Date(info.getValue())).format('LLL').toString()}*/}
                        {moment.tz(info.getValue(), 'UTC').tz('America/New_York').format('LLL')}


                    </p>
                </div>
            ),
        }),

    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);
    const [listFilterData, setListFilterData] = React.useState([]);
    const [fileId, setFileId] = React.useState(1);
    const [isTableView, setIsTableView] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 6,
        });
    let activeColor = useColorModeValue('gray.700', 'white');
    let inactiveColor = useColorModeValue(
        'secondaryGray.600',
        'secondaryGray.600',
    );

    const changeView = () => {
        setIsTableView(!isTableView);
        setTableView(isTableView);
    };

    const changeTableView = (setView: boolean) => {
        setTableView(setView);
    };

    const addNewProjectClick = () => {
        navigate('/admin/projects/AddProject');
    };

    const onFileViewClick = (projectId: any, flag: any) => {
        let selectedProject = tableData.find((project: { projectId: any; }) => project.projectId === projectId);
        navigate('/admin/projects/ViewProject/' + projectId);
    };

    const onChangeSearchFilter = (e: any) => {
        setGlobalFilter(e.target.value);
        setListViewCount(6);
        let filterData: any[] = [];
        table.getFilteredRowModel().rows.forEach((row) => {
            filterData.push(row.original);
        });
        setListFilterData(filterData);
    };

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    useEffect(() => {
        setListFilterData(defaultData);
        setData(defaultData);
    }, [data, tableData]);

    return (
        <Box p={4} bg={bg} rounded="xl" mt={"36px"} py="20px">
            {/* Top section */}
            {!fileDetailsView && (
                <Box>
                <SimpleGrid py={2} spacingX={4} minChildWidth='225px' spacingY={"20px"}>
                    <Box width="100%" maxWidth="400px">
                            <InputGroup>
                                <InputLeftElement 
                                    pointerEvents="none"
                                    children={<SearchIcon  />}
                                />
                                <Input
                                    type="text"  width="100%" 
                                    placeholder="Search"
                                    value={globalFilter ?? ''}
                                    onChange={(e: any) => onChangeSearchFilter(e)}
                                    borderColor="gray.300"
                                    _focus={{ borderColor: 'teal.400' }}
                                />
                            </InputGroup>
                    </Box>
                    <Box paddingBottom = "25"
                        bg={bg}
                        className="linear  bg-lightPrimary text-base font-medium text-brand-500 transition duration-200"
                        mt={{ base: 4, md: 0 }}
                    >
                        <Flex alignItems="center">
                            <Text  mr={2}>Show Tile View:</Text>
                            <Switch id={"ss"} colorScheme="brand" onChange={changeView} size="lg" defaultChecked />
                        </Flex>
                    </Box>
                    <Box>
                    <Flex className="flex h-20 items-center justify-end px-6">
                        <CustomButton
                            label={"Start New Project"}
                            onClick={addNewProjectClick}
                        />
                    </Flex>
                    </Box>
                </SimpleGrid>
              
                </Box>
            )}

            {/* Table view or List view */}
            <Box overflowX="auto">
                {tableView ? (
                     <GetListsHeader
                     tableViewData={defaultData}
                     changeTableView={changeTableView}
                 />
                ) : (<GetTableView />

                   
                )}
            </Box>
        </Box>
    );

    function GetListsHeader(props: { tableViewData: any, changeTableView: any }) {
        return (
            <Box>
                <Flex flexWrap="wrap" marginBottom="5" marginTop="8">
                    {listFilterData?.map(
                        (file: any, index: React.Key) =>
                        // index < listViewCount &&
                        (
                            <Box
                                key={index}
                                cursor="pointer"
                                height="400px"
                                width="400px"
                                marginRight="4"
                                onClick={() => onFileViewClick(file.projectId, true)} // Assuming onFileViewClick function is defined
                            >
                                <FileListViewInformation file={file} isHover={true} />
                            </Box>
                        ),
                    )}
                </Flex>
                <Flex
                    width="full"
                    marginTop="6"
                    marginBottom="6"
                    justifyContent="center"
                >
                    {listFilterData?.length >= listViewCount && (
                        <CustomButton label='View More Projects'
                            
                            onClick={() => {
                                setListViewCount(listViewCount + 6);
                            }}
                        >
                            
                        </CustomButton>
                    )}
                </Flex>
            </Box>
        );
    }

    function GetTableView(props: any) {
        return (
            <div className="overflow-x-scroll xl:overflow-x-hidden">
                <div className="overflow-x-scroll xl:overflow-x-hidden">
                    {(defaultData && defaultData?.length > 0) ? <TableViewComponent
                        columnsList={columns}
                        tableData={listFilterData}
                        enablePagination={true}
                        enableSearch={false}
                        saveButtonValue={null}
                        saveButtonClick={null}
                        cancelButtonValue={null}
                        cancelButtonClick={null}
                        viewSaveCancel={true}
                    ></TableViewComponent> : <div>No Records Available</div>}
                </div>
            </div>
        );
    }

}

export default ProjectListTable;
const columnHelper = createColumnHelper<Projects>();
