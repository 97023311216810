import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, useDisclosure } from "@chakra-ui/react"
import CustomButton from "components/button/CustomButton";
import React from "react"

export function ConfirmationDialog(props: { isOpen: any, header: any, content: any, onConfirmClick: any, onCloseClick: any }) {
  
  const { isOpen, header, content, onConfirmClick, onCloseClick } = props; 
  const cancelRef = React.useRef()
  const onConfirm = () => {
    onConfirmClick();
  }

  const onClose = () => {
    onCloseClick();
  }

  return (
    <>
      {/* <Button colorScheme='red' onClick={onOpen}>
        Delete Customer
      </Button> */}



      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {header}
            </AlertDialogHeader>

            <AlertDialogBody>
              {content}
            </AlertDialogBody>

            <AlertDialogFooter>
              
              <CustomButton label="Cancel" onClick={onClose} />
              <CustomButton label="Confirm" onClick={onConfirm} />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}