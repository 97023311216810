
import { useEffect, useState } from 'react';
import { useFormik, } from 'formik';
// Chakra imports
import { Box, Grid, GridItem, Input, Text, useToast, useColorModeValue, Button, Select, RadioGroup, Stack,Radio, SimpleGrid, Flex, } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerPage from 'components/spinner/spinner';


import { userService } from 'services/UsersService';

import * as Yup from 'yup';
import CustomButton from 'components/button/CustomButton';

// Custom components

//https://mocki.io/v1/8b1218b7-d6e8-4a61-b961-5afca0334956

interface StateList {
  name: string,
  Code: string,
  id: string
}

export default function AccountsPage() {
  const formInit = {
    userName: '',
    email: "",
    phoneNumber: '',
    userId: '',
    tenantId: 0,
    isActive: true,
    isDeleted: false,
    createdBy: '',
    modifiedBy: '',
    notificationsEnabled: true,
    mfaEnabled: true,
    isTenantUser: 1,
    isAdminUser: 0,
    tenant: '',
    firstName: '',
    lastName: '',
    roles: 'Staff',
  }
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [staffDetails, setStaffDetails] = useState(formInit);
 
  const [error, setError] = useState<string | null>(null);

  const [userId, setUserId] = useState<string>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [tenantDetails, setTenantDetails] = useState<any>(null);
  const [tenantId, setTenantId] = useState<any>(null);
  
  const toast = useToast();

  const navigate = useNavigate();

  let [userLoggedIn] = useState(JSON.parse(localStorage.getItem('userLoginStatus')));
  // const onTenentClick = () => {
  //   navigate("/admin/tenant/AddTenant", { state: { tenentId:tenantId?tenantId: tenantDetails.tenantId, tenantSelected: tenantDetails,tenantDetails:tenantDetails, isStaffListView: tenantDetails?true:false } });
  // };

  const bg = useColorModeValue('background.100', 'background.900');
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
  useEffect(() => {


    setUserId(userLoggedIn?.userDetails?.userInfo?.userId);
    setUserDetails(userLoggedIn?.userDetails?.userInfo);
    setTenantDetails(userLoggedIn?.userDetails?.userInfo?.tenant);
    setTenantId(userLoggedIn?.userDetails?.userInfo?.tenant.tenantId);
    if(userId) {
      setStaffDetails(userDetails);
    } else {
      setStaffDetails(formInit);
    }
   
    return () => {
      setStaffDetails(formInit);
    }
  }, []);
  

  // const getTenantById = async () => {
  //   if (!userId) {
  //     return;
  //   }
  //   try {
     
  //     setStaffDetails(userDetails);

  //   } catch (error) {
  //     setError('Error fetching user data');
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const onResetPassword = async () => {
    setLoading(true);
    try {
      const email = formik.values.email;
      const data: any = await userService.forgotPassword(email);
      toast({
        title: 'Email Reset',
        description: "Email Reset successfully.",
        status: 'success',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      setLoading(false);
    } catch (error) {
      toast({
        title: 'Error Email Reset',
        description: "Error While Resetting Email.",
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      setLoading(false);
    }
    
  }
 

  const validationSchema = Yup.object().shape({
    // userName: Yup.string(),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string(),
    phoneNumber: Yup.string().required('Phone Number is required'),
    roles: Yup.string(),

  });
  const cancel = () => {
    
   if(userDetails || tenantDetails){
    navigate("/admin/tenant/AddTenant", { state: { tenentId: tenantId?tenantId:tenantDetails.tenantId, tenantSelected: tenantDetails,isStaffListView:1 } });
   }else{
    navigate("/admin/tenant");
   }
  };

  const submitForm = () => {
    formik.submitForm();
  }


  const formik = useFormik({
    initialValues: userId ? userDetails: staffDetails,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {

      ///values.userId = ;
      values.tenantId = tenantId;
      values.userName = values.email;
      if( values.roles === "Staff") {
        values.isTenantUser = 1;
        values.isAdminUser =  0;
      }  else if (values.roles == "TenantAdmin") { 
        values.isTenantUser = 1;
        values.isAdminUser =  1; 
      } else {
        values.isTenantUser = 0;
        values.isAdminUser =  1; 
      }
       
      setLoading(true);
      //delete values.tenant;
      if (userId) {

        try {


          const data: any = await userService.updateUserById(values);
          setLoading(false);
          toast({
            title: 'Profile update.',
            description: "Profile updated successfully.",
            status: 'success',
            duration: 5000,
            position: 'top',
            isClosable: true,
          });
         
         
        } catch (error) {
          setLoading(false);
        }
      } else {
        try {

          const isEmailExist: any = await userService.verifyIsEmailExist(values.email);
          if (isEmailExist) {
            toast({
              title: 'Email already exist.',
              description: "Email already exist.",
              status: 'error',
              duration: 5000,
              position: 'top',
              isClosable: true,
            })
            return;
          }
          const data: any = await userService.createuser(values);
          setLoading(false);
          toast({
            title: 'Staff created.',
            description: "Staff Added successfully.",
            status: 'success',
            duration: 5000,
            position: 'top',
            isClosable: true,
          });
          resetForm();
        } catch (error) {
          setLoading(false);
        }
      }
    }
  });
 
  if(loading){
    return <SpinnerPage />;
  }

  return (
    <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'} paddingTop="25">
      <Box>

        <form onSubmit={formik.handleSubmit}>
            <SimpleGrid  p={2} spacingX={3} minChildWidth='225px' spacingY={"5px"}>
              <Box pt={5}>
                  <Text mb='8px'>Email: </Text>
                  <Input placeholder='name@email.com' id="email"
                    name="email"
                    type="email"
                    size='md'
                    onChange={formik.handleChange}
                    value={formik.values?.email}
                    readOnly={userId?true: false}
                    />
                  <span style={{ color: 'red' }}>
                    {formik.touched.email ? formik.errors.email : ''}
                  </span>
              </Box>
              <Box></Box>
              <Box></Box>
            </SimpleGrid>
          

            <SimpleGrid  px={2} spacingX={3} minChildWidth='225px' spacingY={"5px"}>
              <Box  pt={4}>
                  <Text mb='8px'>First Name</Text>
                  <Input
                    as={Input}
                    id="firstName"
                    name="firstName"
                    type="text"
                    // helperText={formik.touched.firstName ? formik.errors.firstName : ""}
                    error={true}
                    size='md'
                    onChange={formik.handleChange}
                    value={formik.values?.firstName}
                  />
                  <span style={{ color: 'red' }}>
                    {formik.touched.firstName ? formik.errors.firstName : ''}
                  </span>

              </Box>
              <Box pt={4}>
                  <Text mb='8px'>Last Name: </Text>
                  <Input as={Input} id="lastName"
                    name="lastName"
                    type="text"
                    size='md'
                    onChange={formik.handleChange}
                    value={formik.values.lastName} />
                  <span style={{ color: 'red' }}>
                    {formik.touched.lastName ? formik.errors.lastName : ''}
                  </span>
              </Box>
            </SimpleGrid>
            <SimpleGrid mb={"20px"} p={2} spacingX={3} minChildWidth='225px' spacingY={"5px"}>
            
              
              
              
              <Box pt={4}>
                  <Text mb='8px'>Mobile Number: </Text>
                  <Input placeholder='+1 000 111 2223' id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    size='md'
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber} />
                  <span style={{ color: 'red' }}>
                    {formik.touched.phoneNumber ? formik.errors.phoneNumber : ''}
                  </span>
              </Box>
              <Box pt={4}>

              </Box>
              
            </SimpleGrid>
            <Flex marginBottom={{base: '20px', xl: '0px' }} display={{ base: 'block', xl: 'flex' }} justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                  <CustomButton label='Cancel' onClick={cancel}></CustomButton> 
                  {userId? 
                    <CustomButton label='Reset Password' onClick={onResetPassword}></CustomButton>
                : ""}
                  {userId? 
                    <CustomButton label='Update' onClick={submitForm}></CustomButton> :
                    <CustomButton label='Save' onClick={submitForm}></CustomButton> 
                  }
                  </Flex>
            <SimpleGrid  p={2} spacingX={3} minChildWidth='225px' spacingY={"5px"}>
              <Box></Box>
              <Box float="right">
              
                </Box>
            </SimpleGrid>
        </form>


      </Box>
    </Card>
  );
}