

// Chakra imports
import { useColorModeValue } from '@chakra-ui/react';
import { Box } from "@chakra-ui/react"
import ProjectListTable from './components/ProjectListTable';
import tableDataComplex from 'variables/tableDataComplex';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { Projects } from '../../../types/Projects';
import { tenantService } from '../../../services/TenantService';

// Custom components
interface StateList {

}
export default function ProjectsPage() {
  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const bg = useColorModeValue('background.100', 'background.900');

    const [projectsList, setProjectsList] = useState<Projects[] | []>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];

    useEffect(() => {
        getProjectsList();
        return () => {
            setProjectsList([]);
        }
    }, []);

    //const getProjectsList = async () => {
    //    try {
    //        let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;

    //        const data: any = await tenantService.getProjectByTenantId<Projects>(tenantId);
    //        setProjectsList(data.responce);
    //    } catch (error) {
    //        setError('Error fetching people data');
    //    } finally {
    //        setLoading(false);
    //    }
    //};

    const getProjectsList = async () => {
        try {
            let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;

            const data: any = await tenantService.getProjectTenantById<Projects>(tenantId);
            setProjectsList(data);
        } catch (error) {
            setError('Error fetching people data');
        } finally {
            setLoading(false);
        }
    };


  return (
    <Card  bg={bg} extra={'w-full h-full pb-5  sm:overflow-auto px-4 cardH'}>
          <ProjectListTable tableData={projectsList} />
    </Card>

  );
}
