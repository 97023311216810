import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL, Data, ApiResponse, handleApiError } from './AxiosAuthService';

export const NotesService = {
    createStaffNotes: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/ProjectNotes`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, deleteStaffNotes: async <T>(notesId: any): Promise<T> => {
        try {
            const response: any = await axios.delete(`${BASE_URL}/ProjectNotes/${notesId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateStaffNotes: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${BASE_URL}/ProjectNotes`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
}