import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    useColorModeValue,
    Checkbox,
    useToast,
    Select

} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
    createColumnHelper,
} from '@tanstack/react-table';
import { TableViewComponent } from 'components/table/tablecomponent';
import { BusinessProjectModel } from 'types/Business';
import { useNavigate } from 'react-router-dom';
import { PersonaService } from '../../../../../services/PersonaService';
import { Personas } from '../../../../../types/Persona';
import { ProjectService } from '../../../../../services/ProjectService';



interface ProjectsLinkToBusinessModel {
    isOpen: boolean;
    onClose: () => void;
    businessList: any[];
    projectId: string
    setBusinessList: React.Dispatch<React.SetStateAction<any[]>>; // Update business list after save

}

const ProjectsLinkToBusiness: React.FC<ProjectsLinkToBusinessModel> = ({ isOpen, onClose, businessList, projectId, setBusinessList}) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const toast = useToast();
    // const temDetails = jsonData.filter((item: any) => !userIds.includes(item.userId)); 
    let activeColor = useColorModeValue('gray.700', 'white');
    const [personaList, setPersonaList] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const [selectedBusinessIds, setSelectedBusinessIds] = useState<number[]>([]);
    useEffect(() => {
        setSelectedBusinessIds([]);
    }, [setBusinessList]);

  
    const columns = [
        columnHelper.accessor('isSelected', {
            id: 'isSelected',
            header: () => <p className="text-sm font-bold text-gray-600 dark:text-white"></p>,
            cell: (info) => (
                <Box>
                    <p color={activeColor}>
                        <Checkbox
                            name={'bussinessClient'}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                const businessId = info.row.original.businessId;
                                if (checked) {
                                    setSelectedBusinessIds((prevIds) => [...prevIds, businessId]);
                                } else {
                                    setSelectedBusinessIds((prevIds) => prevIds.filter((id) => id !== businessId));
                                }
                            }}
                        />
                    </p>
                </Box>
            ),
        }),
        columnHelper.accessor('businessName', {
            id: 'businessName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Business Name
                </p>
            ),
            cell: (info) => (
                <p
                    color={activeColor}
                    className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                    onClick={() => {
                        // onBusinessClick(info.row.original.pe, true);
                    }}
                >
                    {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('businessRegistrationNumber', {
            id: 'businessRegistrationNumber',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Registration Number#
                </p>
            ),
            cell: (info: any) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('industry', {
            id: 'industry',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Industry</p>
            ),
            cell: (info: any) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
    ];

    const saveClientRelation = async () => {
        try {
            const requestData = selectedBusinessIds.map((businessId) => ({
                projectBusinessId: 0,
                tenantId: businessList[0].tenantId,
                projectId: projectId,
                businessId,
                primaryForLoan: 0,
                modifiedOn: new Date().toISOString(),
                modifiedBy: ""
            }));
            const postData: any = await ProjectService.postprojectBusinessesRelationWithDetails(requestData)
            
            toast({
                title: 'Businesses linked to project.',
                description: 'Businesses linked successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });

            const updatedOpenModalBusinessList = businessList.filter((business) => !selectedBusinessIds.includes(business.businessId));
            setBusinessList(updatedOpenModalBusinessList);
            setSelectedBusinessIds([]);
            onClose();
        } catch (error) {
            // Handle error
            console.error('Error linking businesses:', error);
            toast({
                title: 'Error',
                description: 'Error linking businesses. Please try again later.',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Assign Business to Project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        //(temDetails && temDetails?.length > 0) ?
                        <TableViewComponent
                        columnsList={columns}
                        tableData={businessList}
                        enablePagination={businessList?.length > 15 ? true : false}
                        enableSearch={true}
                        saveButtonValue="Save"
                        saveButtonClick={saveClientRelation}
                        cancelButtonValue="Cancel"
                        viewSaveCancel={true}
                        cancelButtonClick={onClose}
                    ></TableViewComponent>
                        /*: <div>No Data</div>*/
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ProjectsLinkToBusiness;
const columnHelper = createColumnHelper<BusinessProjectModel>();
