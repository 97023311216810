

// Chakra imports
import React, { useEffect, useState } from 'react';
import { Flex, Grid, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { Box } from "@chakra-ui/react"
import PeopleListTable from './components/PeopleListTable';
import { PeopleService } from './../../../services/PeopleService';
import { People } from '../../../types/People';
import SpinnerPage from 'components/spinner/spinner';
import Card from 'components/card/Card';
import CustomButton from 'components/button/CustomButton';
import { useNavigate } from 'react-router-dom';
//import { People } from './../../../types/people';
// Custom components
interface StateList {

}

export default function PeoplePage() {

  const [peopleList, setPeopleList] = useState<People[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
  const navigate = useNavigate();

  useEffect(() => {
    getPeopleList();
    return () => {
      setPeopleList([]);
    }
  }, []);

  const getPeopleList = async () => {
    try {
      let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
      const data: any = await PeopleService.getPeopleByTenantId(tenantId);
      // const data: any = [];
      setPeopleList(data?.peoples);
    } catch (error) {
      setError('Error fetching people data');
    } finally {
      setLoading(false);
    }
  };

  const addNewPersonClick = () => {
    navigate('/admin/people/AddPeople');
  };

  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
  const bg = useColorModeValue('background.100', 'background.900');
  return (
    <Card  bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'}>
      {/*  */}
        { loading?  <SpinnerPage /> : 
                          <Box px={"10px"}>

                            <Box bg={bg}>
                              
                                    {/* View Header Start */}
                                    <SimpleGrid p={2} spacingX={4} minChildWidth='225px' spacingY={"20px"}>
                                      
                                        <Box>
                                            <Flex className="flex items-center justify-end px-6">
                                                <CustomButton
                                                    label={"Add Person"}
                                                    onClick={addNewPersonClick}
                                                />
                                            </Flex>
                                        </Box>
                                    </SimpleGrid>
                      {peopleList.length ? <PeopleListTable tableData={peopleList}></PeopleListTable> : <Box>No Records Available</Box> }
                            </Box>
                          
                        </Box>
        }
      {/* </Card> */}
    </Card>

  );
}