import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL, handleApiError } from './AxiosAuthService';// Replace with your API endpoint

interface Data<T>{
    data:any
}
interface ApiResponse<T> {
  data:Data<T> ;
}

export const UploadService = {
    postProjectEmailDetails: async <T>(token: any): Promise<T> => {
        try {
            const request = { token: token};
            const response: any = await axios.post(`${BASE_URL}/ProjectRequest/projectRequestEmailDetails`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectRequestUploadFiles: async <T>(request: any): Promise<T> => {
        try {
            //const request = { token: token };
            const response: any = await axios.post(`${BASE_URL}/ProjectRequest/projectRequestUploadFiles`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    projectRequestUploadFileForStaff: async <T>(request: any): Promise<T> => {
        try {
            //const request = { token: token };
            const response: any = await axios.post(`${BASE_URL}/ProjectRequest/projectRequestUploadFileForStaff`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
}