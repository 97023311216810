import React, { Key } from 'react';
import { Table, Thead, Tr, Th, Checkbox, Tbody, Td, Select, Button } from "@chakra-ui/react";
import { TableViewComponent } from 'components/table/tablecomponent';

import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table';
import { Box, useColorModeValue } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import SearchIcon from 'components/icons/SearchIcon';
import Card from 'components/card/Card';
import { ClientPersona } from '../../../../types/Business';
import CustomButton from '../../../../components/button/CustomButton';
import { phoneNumberAutoFormat } from 'components/phonenumber/phoneNumberAutoFormat';
import { RiDeleteBin6Line } from 'react-icons/ri';

function PeopleListTable(props: { tableData: any, personasList: any, deletePeople: any }) {
    const navigate = useNavigate();
    const [userLoggedIn] = React.useState(
        JSON.parse(localStorage.getItem("userLoginStatus"))
    );
    const bg = useColorModeValue('background.100', 'background.900');
    const { tableData, personasList } = props;
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [],
    );
    let defaultData = tableData;


    // const [tableView, setTableView] = React.useState(true);
    // const [fileDetailsView, setFileDetailsView] = React.useState(false);
    const [listViewCount, setListViewCount] = React.useState(6);
    const [globalFilter, setGlobalFilter] = React.useState('');


    const createPages = (count: number) => {
        let arrPageCount = []

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };
    const columns = [
        columnHelper.accessor('firstName', {
            id: 'firstName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    First Name
                </p>
            ),
            cell: (info) => (
                <p
                    color={activeColor}
                    className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                    onClick={() => {
                        // onBusinessClick(info.row.original.pe, true);
                    }}
                >
                    {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('lastName', {
            id: 'lastName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Last Name
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Email</p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('phoneNumber', {
            id: 'phoneNumber',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Phone Number</p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {phoneNumberAutoFormat(info.getValue())}
                </p>
            ),
        }),
        columnHelper.accessor('personas.personaName', {
            id: 'personaName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Role</p>
               
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                     {info.getValue()}
                
                </p>
            ),
        }),
      
        columnHelper.accessor('personas.personaId', {
            id: 'personaId',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    
                </p>
            ),
            cell: (info) => (
                <Box float={"right"}>
                     <Button onClick={() => { deletePeople(info.row.original.clientBusinessId); }}><RiDeleteBin6Line /></Button>
                
                </Box>
            ),
        }),
    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);

    const [listFilterData, setListFilterData] = React.useState(() => [
        ...defaultData,
    ]);

    const [peopleId, setPeopleId] = React.useState(1);
    const [selectedPeople, setSelectedPeople] = React.useState(null);
    const [{ pageIndex, pageSize }, setPagination] = React.useState<
        PaginationState
    >({
        pageIndex: 0,
        pageSize: 6,
    });
    let activeColor = useColorModeValue('gray.700', 'white');

    // const addNewProjectClick = () => {
    //   navigate('/admin/dashboards/AddProject');
    // };

    const onBusinessClick = (businessId: any, flag: any) => {
        // let selectedPeople = data.find((people) => people.peopleId === peopleId);
        // selectedPeople(selectedPeople);
        // setPeopleId(selectedPeople);
        navigate("/admin/business/AddBusiness", { state: { businessId: businessId } });

    };

    const deletePeople = (id: string) => {
        props.deletePeople(id);
    }
    const onChangeSearchFilter = (e: any) => {
        setGlobalFilter(e.target.value);
        setListViewCount(6);
        let filterData: any[] = [];
        table.getFilteredRowModel().rows.forEach((row) => {
            filterData.push(row.original);
        });
        setListFilterData(filterData);
    };

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    return (
        <Box bg={bg}>
                {/* View Header Start */}

                <GetTableView />
        </Box>
    );

    function GetTableView(props: any) {

        return <>
            {(defaultData && defaultData?.length > 0) ? <TableViewComponent
                columnsList={columns}
                tableData={defaultData}
                enablePagination={defaultData?.length > 15 ? true : false}
                enableSearch={true}
                saveButtonValue={null}
                saveButtonClick={null}
                cancelButtonValue={null}
                cancelButtonClick={null}
                viewSaveCancel = {true}
            ></TableViewComponent> : <div>No Records Available</div>}
        </>

    }
}

export default PeopleListTable;
const columnHelper = createColumnHelper<ClientPersona>();