
import { SearchIcon } from "@chakra-ui/icons";
import { Table, Thead, Tr, Th, Checkbox, Tbody, Td, Select, Button, Box, Flex, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import {
    PaginationState,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table';
import React, { ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import CustomButton from "../button/CustomButton";

export interface TableViewProps {
    columnsList: any;
    tableData: any;
    enablePagination?: boolean;
    enableSearch?: boolean;
    saveButtonValue?: any;
    saveButtonClick?: () => void;
    cancelButtonValue?: any;
    cancelButtonClick?: () => void;
    children?: ReactNode; // Define children prop
    viewSaveCancel?: boolean;
    getTableObject?: any;
}

export function TableViewComponent(props: TableViewProps) {
    const [{ pageIndex, pageSize }, setPagination] = React.useState<
        PaginationState
    >({
        pageIndex: 0,
        pageSize: 15,
    });
    const {
        columnsList,
        tableData,
        saveButtonValue,
        saveButtonClick,
        cancelButtonValue,
        cancelButtonClick,
        children, // Access children prop here
        viewSaveCancel,
        getTableObject
    } = props;

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [],
    );
    const [listViewCount, setListViewCount] = React.useState(6);

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const [globalFilter, setGlobalFilter] = React.useState('');
    let activeColor = useColorModeValue('gray.700', 'white');
    const [data, setData] = React.useState(props.tableData);
    const [columns, setColumns] = React.useState(() => []);
    const [enableSearch, setEnableSearch] = React.useState(false);

    const [tableEnt, setTableEnt] = React.useState(true);
    const [enablePagination, setEnablePagination] = React.useState(true);

    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };

    useLayoutEffect(() => {

        table.setPageSize(15);
        setEnableSearch(props.enableSearch);

        if (props.tableData) {
            setData(props.tableData);
            props.tableData.length > 15 ? setEnablePagination(props.enablePagination) : setEnablePagination(false)
        }


       

        if (props.columnsList) {
            setColumns(props.columnsList);
        }
        
        if(getTableObject) {
            getTableObject(table);
        }

        return () => {
        }
    }, [props.tableData]);

    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    const onChangeSearchFilter = (e: any) => {
        setGlobalFilter(e.target.value);
        setListViewCount(15);
        let filterData: any[] = [];
        table.getFilteredRowModel().rows.forEach((row) => {
            filterData.push(row.original);
        });
        setListFilterData(filterData);

    };

    let defaultData = props.tableData;

    const [listFilterData, setListFilterData] = React.useState(() => [
        ...defaultData,
    ]);






    return (
        <div className="overflow-x-scroll xl:overflow-x-hidden">
            {data && data.length > 0 ? <div>

                <div>
                    {enableSearch ? <SimpleGrid py={2} spacingX={4} minChildWidth='225px' spacingY={"20px"}>
                        <Box width="100%" maxWidth="400px">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<SearchIcon color="gray.300" />}
                                />
                                <Input
                                    type="text"
                                    placeholder="Search"
                                    value={globalFilter ?? ''}
                                    onChange={(e: any) => onChangeSearchFilter(e)}
                                    borderColor="gray.300"
                                    _focus={{ borderColor: 'teal.400' }}
                                />
                            </InputGroup>
                        </Box>
                    </SimpleGrid> : <div></div>
                    }

                    <Box paddingY={"5px"}  paddingX={"10px"}  border={"1px"} borderColor={"#eee"}>
                        <Table className="w-full responsiveTable">
                            <Thead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id} className="!border-px !border-gray-400">
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <th
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    className="cursor-pointer border-b border-gray-200 pb-2 pr-4 pt-4 text-start dark:border-white/30"
                                                    style={{ width: '20px', minWidth: '20px', maxWidth: header.getSize()? header.getSize(): "100%" }} // Set min-width to ensure columns are not too narrow
                                                >
                                                    <div className="items-center justify-between text-xs text-gray-200 flex flex-row">
                                                        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                            {/* Render arrow icons for sorting */}
                                                            {header.column.getIsSorted() === 'asc' && (
                                                                <span>&uarr;</span>
                                                            )}
                                                            {header.column.getIsSorted() === 'desc' && (
                                                                <span>&darr;</span>
                                                            )}
                                                        </span>
                                                    </div>
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table
                                    .getRowModel()
                                    .rows.slice(0, 15)
                                    .map((row) => {
                                        return (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map((cell) => {
                                                    return (
                                                        <td
                                                            key={cell.id}
                                                            className=" border-white/0 py-3  pr-4"
                                                        >
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext(),
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                            </Tbody>
                        </Table>
                    </Box>
                    {/* Render the buttons dynamically */}
                    {viewSaveCancel ? (saveButtonValue || cancelButtonValue) && (
                        <Flex justifyContent="flex-end" mt={"16px"}> {/* Align buttons to the right */}
                            {cancelButtonValue && cancelButtonClick && (
                                <CustomButton
                                    label={cancelButtonValue}
                                    onClick={cancelButtonClick}
                                />
                            )}
                            {saveButtonValue && saveButtonClick && (
                                <CustomButton
                                    label={saveButtonValue}
                                    onClick={saveButtonClick}
                                />
                            )}
                        </Flex>
                    ) : <Box></Box>}

                    {/* pagination */}

                    {enablePagination ?
                        <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
                            {/* left side */}
                            <div className="flex items-center gap-3">
                                {/* <p className="> Show rows per page text-sm text-gray-700">
                                Showing 15 rows per page
                            </p> */}
                            </div>
                            {/* right side */}
                            <div className="flex items-center gap-2">
                                <Button
                                    colorScheme="blue"
                                    disabled={!table.getCanPreviousPage()}
                                    onClick={() => table.previousPage()}
                                    bg="brand.500"
                                    color="white"
                                    size="md"
                                    borderRadius="full"
                                    p="2"
                                    transition="background-color 0.2s"
                                    _hover={{ bg: 'brand.600' }}
                                    _active={{ bg: 'brand.700' }}
                                    _dark={{
                                        bg: 'brand.400',
                                        color: 'white',
                                        _hover: { bg: 'brand.300' },
                                        _active: { bg: 'brand.200' },
                                    }}
                                >
                                    <MdChevronLeft />
                                </Button>

                                {createPages(table.getPageCount()).map((pageNumber, index) => {
                                    return (
                                        <button
                                            color={activeColor}
                                            className={`linear flex h-10 w-10 items-center justify-center rounded-full p-2 text-sm transition duration-200 ${pageNumber === pageIndex + 1
                                                    ? 'bg-blue-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
                                                    : 'border-[1px] border-gray-400 bg-transparent dark:border-white dark:text-white'
                                                }`}
                                            onClick={() => table.setPageIndex(pageNumber - 1)}
                                            key={index}
                                        >
                                            {pageNumber}
                                        </button>
                                    );
                                })}

                                <Button
                                    colorScheme="blue"
                                    disabled={!table.getCanNextPage()}
                                    onClick={() => table.nextPage()}
                                    bg="brand.500"
                                    color="white"
                                    size="md"
                                    borderRadius="full"
                                    p="2"
                                    transition="background-color 0.2s"
                                    _hover={{ bg: 'brand.600' }}
                                    _active={{ bg: 'brand.700' }}
                                    _dark={{
                                        bg: 'brand.400',
                                        color: 'white',
                                        _hover: { bg: 'brand.300' },
                                        _active: { bg: 'brand.200' },
                                    }}
                                >
                                    <MdChevronRight />
                                </Button>
                            </div>
                        </div> : <Box p={"20px"} h={"20px"}></Box>}

                </div>

            </div>
                : <div>No Records Available</div>}
        </div>
    );
}