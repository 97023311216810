
import { Box, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import Progress from 'components/progress';
import { transform } from 'framer-motion';
import { FcAlarmClock } from 'react-icons/fc';


const FileListViewInformation = (props: { file: any, isHover?: any }) => {
    const { file, isHover } = props;
    let activeColor = useColorModeValue('gray.700', 'white');
    return (
        
            <Card color={activeColor} isHover={isHover} extra={"bg-[#000] mt-3 !z-5 overflow-hidden"}>
                <Box minHeight={"270px"}>
                    {/* HistoryCard Header */}
                    <div color={activeColor} className="flex items-center justify-between rounded-t-3xl p-3  sm:ml-4 sm:mr-4">
                        <div className="dark:text-white">
                            <p className='text-lg font-bold '>{file?.projectName}</p>
                            <p color={activeColor} className="text-xs dark:text-white">File Id : {file?.projectId}</p>
                            {/*{ file?.people && file?.people.length > 0 ? <p  className="text-xs font-bold dark:text-white">{file?.people[0].firstName} {file?.people[0].lastName} </p> : "" }*/}

                        </div>
                    <div className=" dark:text-white">
                            {
                                file?.assignedToName ? <Box>
                                    <p className="text-xs  font-bold dark:text-white text-end">{file?.assignedToName}</p>
                                    <p className="text-xs dark:text-white text-end">Assigned Staff</p>
                                    </Box>: <></>
                            }
                        </div>
                    </div>
                    <div className="flex items-center justify-center  p-4">
                        <p className='text-[40px] text-center font-bold '>
                            ${new Intl.NumberFormat("en-US").format(file?.amount)}
                        </p>
                    </div>

                    <div className="flex items-center justify-between rounded-t-3xl p-3 border-b sm:ml-4 sm:mr-4">
                        <div className=" dark:text-white">
                            <p className='text-lg font-bold '>{file?.fileOwner}</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between rounded-t-3xl p-3 sm:ml-4 sm:mr-4  text-start">
                        <div className="md:w-[230px]  dark:text-white">
                            {
                                file?.statusName ? <Box>
                                    <p className="text-sm font-bold  pt-4" style={{textTransform : "capitalize"}}>{file?.statusName}</p>
                                    <p className="text-sm  dark:text-white">Status</p>
                                </Box>: <></>
                            }
                        </div>
                        <div className=" dark:text-white text-end">
                            {
                                file?.industrySector ? <Box><p className="flex items-center justify-between text-sm font-bold  dark:text-white text-sm font-bold ">
                                <span className=" text-sm font-bold " >{file?.industrySector}</span>
                                </p>
                                <p className="text-sm  dark:text-white">Industry</p>
                                </Box>: <></>
                            }
                        </div>
                    </div>
                </Box>
            </Card>
    );
};

export default FileListViewInformation;
