import React from 'react';

import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table';
import { Box, Button, useColorModeValue } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import SearchIcon from 'components/icons/SearchIcon';
import Card from 'components/card/Card';
import { Business } from '../../../../types/Business';
import CustomButton from '../../../../components/button/CustomButton';
import { TableViewComponent } from 'components/table/tablecomponent';

function BusinessListTable(props: { tableData: any }) {
    const navigate = useNavigate();
    const [userLoggedIn] = React.useState(
        JSON.parse(localStorage.getItem('userLoginStatus')),
    );
    const bg = useColorModeValue('background.100', 'background.900');
    const { tableData } = props;
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [],
    );
    let defaultData = tableData;
    // const [tableView, setTableView] = React.useState(true);
    // const [fileDetailsView, setFileDetailsView] = React.useState(false);
    const [listViewCount, setListViewCount] = React.useState(6);
    const [globalFilter, setGlobalFilter] = React.useState('');

    const addNewBusiness = () => {
        navigate('/admin/business/AddBusiness', { state: { businessId: '' } });
    };

    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };
    const columns = [
        columnHelper.accessor('businessName', {
            id: 'businessName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Business Name
                </p>
            ),
            cell: (info) => (
                    <p
                        style={{ textDecoration: 'underline' }}
                        color={activeColor}
                        className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                        onClick={() => {
                            onBusinessClick(info.row.original.businessId, true);
                        }}
                    >
                    {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('businessAddress', {
            id: 'businessAddress',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Business Address
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        // columnHelper.accessor('businessType', {
        //     id: 'businessType',
        //     header: () => (
        //         <p className="text-sm font-bold text-gray-600 dark:text-white">
        //             Business Type
        //         </p>
        //     ),
        //     cell: (info) => (
        //         <p color={activeColor} className="text-sm font-bold dark:text-white">
        //             {info.getValue()}
        //         </p>
        //     ),
        // }),
        columnHelper.accessor('industry', {
            id: 'industry',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    industry
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('annualRevenue', {
            id: 'annualRevenue',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Annual Revenue
                </p>
            ),
            cell: (info) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    { info.getValue() }
                </p>
            ),
        }),
    ]; // eslint-disable-next-line
    const [data, setData] = React.useState(() => [...defaultData]);

    const [listFilterData, setListFilterData] = React.useState(() => [
        ...defaultData,
    ]);

    const [peopleId, setPeopleId] = React.useState(1);
    const [selectedPeople, setSelectedPeople] = React.useState(null);
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 15,
        });
    let activeColor = useColorModeValue('gray.700', 'white');

    // const addNewProjectClick = () => {
    //   navigate('/admin/dashboards/AddProject');
    // };

    const onBusinessClick = (businessId: any, flag: any) => {
        // let selectedPeople = data.find((people) => people.peopleId === peopleId);
        // selectedPeople(selectedPeople);
        // setPeopleId(selectedPeople);
        navigate('/admin/business/ViewBusiness/'+businessId, {
            state: { businessId: businessId },
        });
    };

    const onChangeSearchFilter = (e: any) => {
        setGlobalFilter(e.target.value);
        setListViewCount(6);
        let filterData: any[] = [];
        table.getFilteredRowModel().rows.forEach((row) => {
            filterData.push(row.original);
        });
        setListFilterData(filterData);
    };

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    return (
        <Box px={"10px"} bg={bg}>
            <GetTableView />
        </Box>
    );

    function GetTableView(props: any) {
        return <div className="overflow-x-scroll xl:overflow-x-hidden">
             {(defaultData && defaultData?.length > 0) ? <TableViewComponent
                columnsList={columns}
                tableData={defaultData}
                enablePagination={defaultData?.length > 15 ? true : false}
                enableSearch={true}
                saveButtonValue={null}
                saveButtonClick={null}
                cancelButtonValue={null}
                cancelButtonClick={null}
                viewSaveCancel = {true}
            ></TableViewComponent> : <div>No Records Available</div>}
        </div>

    }

    
}

export default BusinessListTable;
const columnHelper = createColumnHelper<Business>();
