import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
// Chakra imports
import {
    Box,
    Grid,
    GridItem,
    Input,
    Text,
    useToast,
    useColorModeValue,
    Button,
    Select,
    RadioGroup,
    Stack,
    Radio,
    SimpleGrid,
    Flex,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerPage from 'components/spinner/spinner';

import { userService } from 'services/UsersService';

import * as Yup from 'yup';
import CustomButton from '../../../../../components/button/CustomButton';

// Custom components

//https://mocki.io/v1/8b1218b7-d6e8-4a61-b961-5afca0334956

interface StateList {
    name: string;
    Code: string;
    id: string;
}

export default function AddStaffPage() {
    const formInit = {
        userName: '',
        email: '',
        phoneNumber: '',
        userId: '',
        tenantId: 1,
        isActive: true,
        isDeleted: false,
        createdBy: '',
        modifiedBy: '',
        notificationsEnabled: true,
        mfaEnabled: true,
        isTenantUser: 1,
        isAdminUser: 0,
        tenant: '',
        firstName: '',
        lastName: '',
        roles: 'Staff',
    };
    const { state } = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [staffDetails, setStaffDetails] = useState(formInit);

    const [error, setError] = useState<string | null>(null);
    const toast = useToast();
    const { tenantId, tenantDetails, userId, userDetails, isAddStaff } = state;
  

    // const [tenantId, setTenantId] = useState(tenentId);
    const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
    const isAdminUser = userLoggedIn.userDetails.userInfo.isAdminUser;

    const navigate = useNavigate();

    const onTenentClick = () => {
        navigate('/admin/tenant/AddTenant', {
            state: {
                tenentId: tenantId ? tenantId : tenantDetails.tenantId,
                tenantSelected: tenantDetails,
                tenantDetails: tenantDetails,
                isStaffListView: tenantDetails ? true : false,
            },
        });
    };

    const bg = useColorModeValue('background.100', 'background.900');
    // Chakra Color Mode
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    useEffect(() => {
        getTenantById();

        if (userId) {
            setStaffDetails(userDetails);
        } else {
            setStaffDetails(formInit);
        }

        return () => {
            setStaffDetails(formInit);
        };
    }, []);

    const getTenantById = async () => {
        if (!userId) {
            return;
        }
        try {
            setStaffDetails(userDetails);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const onResetPassword = async () => {
        setLoading(true);
        try {
            const email = formik.values.email;
            const data: any = await userService.forgotPassword(email);
            toast({
                title: 'Email Reset',
                description: 'Email Reset successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            setLoading(false);
        } catch (error) {
            toast({
                title: 'Error Email Reset',
                description: 'Error While Resetting Email.',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            setLoading(false);
        }
    };

    const validationSchema = Yup.object().shape({
        // userName: Yup.string(),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string().required('Email is required'),
        phoneNumber: Yup.string().required('Mobile Number is required'),
        roles: Yup.string(),
    });
    const cancel = () => {
        if (userDetails || tenantDetails) {
            navigate('/admin/tenant/AddTenant', {
                state: {
                    tenentId: tenantId ? tenantId : tenantDetails.tenantId,
                    tenantSelected: tenantDetails,
                    isStaffListView: 1,
                },
            });
        } else {
            navigate('/admin/tenant');
        }
    };

    const formik = useFormik({
        initialValues: userId ? userDetails : staffDetails,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {

            ///values.userId = ;
            values.tenantId = tenantId? tenantId :userLoggedIn?.userDetails?.userInfo?.tenantId;
            values.userName = values.email;
            if (values.roles === 'Staff') {
                values.isTenantUser = 1;
                values.isAdminUser = 0;
            } else {
                values.isTenantUser = 1;
                values.isAdminUser = 1;
            }

            setLoading(true);
            //delete values.tenant;
            if (userId) {
                try {
                    const data: any = await userService.updateUserById(values);
                    const emailData: any = await userService.forgotPassword(values.userName);

                    setLoading(false);
                    toast({
                        title: values.firstName + ' successfully updated.',
                        // description: 'Staff updated successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    onTenentClick();
                } catch (error) {
                    // Handle error
                } finally {
                    setLoading(false);
                }
            } else {
                //values.tenantId = 0;
                try {
                    const isEmailExist: any = await userService.verifyIsEmailExist(
                        values.email,
                    );
                    if (isEmailExist) {
                        toast({
                            title: 'Email already exist.',
                            description: 'Email already exist.',
                            status: 'error',
                            duration: 5000,
                            position: 'top',
                            isClosable: true,
                        });
                        return;
                    }
                    const data: any = await userService.createuser(values);
                    const emailData: any = await userService.forgotPassword(values.userName);

                    setLoading(false);
                    toast({
                        title: values.firstName + ' successfully created.',
                        // description: 'Staff added successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    onTenentClick();

                } catch (error) {
                    // Handle error
                } finally {
                    setLoading(false);
                }
            }
        },
    });

    if (loading) {
        return <SpinnerPage />;
    }



    const handleFormattedInput = (event: { target: any; }, fieldName: string, maxDigits: number, isSSN = false, separator = '-') => {
        let inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        inputValue = inputValue.substring(0, maxDigits); // Limit to a maximum of `maxDigits` digits

        let formattedValue = inputValue;
        if (fieldName === 'phoneNumber') {
            // Adjust the regex pattern for phone number formatting
            formattedValue = inputValue.replace(/^(\d{0,3})(\d{0,3})?(\d{0,4})?$/, (match: any, p1: string, p2: any, p3: any) => {
                let formatted = '';
                if (p2 === undefined && p3 === undefined) {
                    formatted = `(${p1})`; // Format as (XXX)
                } else if (p3 === undefined) {
                    formatted = `(${p1}) ${p2}`; // Format as (XXX) XXX
                } else {
                    formatted = `(${p1}) ${p2}-${p3}`; // Format as (XXX) XXX-XXXX
                }
                return formatted;
            });
        }

        // Update formik values with formatted value
        formik.setFieldValue(fieldName, formattedValue);
    };



    const handleChange = (event: { target: any; }, fieldType: string) => {
        let fieldName = '';
        let maxDigits = 0;
        let separator = '-';
        //let isValidEmail = false;
        switch (fieldType) {
            case 'phoneNumber':
                fieldName = 'phoneNumber';
                maxDigits = 10;
                break;
            //case 'email':
            //    fieldName = 'email';
            //    isValidEmail = validateEmail(event.target.value);
            //    break;
            default:
                break;
        }
        //if (isValidEmail) {
        //    handleFormattedInput(event, fieldName, maxDigits, isSSN, separator);
        //} else {
        //}

        handleFormattedInput(event, fieldName, maxDigits);
    };

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <Card bg={bg} extra={'w-full h-full pb-5 pt-4 sm:overflow-auto px-2'}>
                    <SimpleGrid p={2} spacingX={3} minChildWidth="225px" spacingY={'5px'}>
                        <Box pt={5}>
                            <Text mb="8px">Email: </Text>
                            <Input
                                placeholder="name@email.com"
                                id="email"
                                name="email"
                                type="email"
                                size="md"
                                onChange={formik.handleChange}
                                value={formik.values?.email}
                                readOnly={userId ? true : false}
                            />
                            <span style={{ color: 'red' }}>
                                {formik.touched.email ? formik.errors.email : ''}
                            </span>
                        </Box>
                        <Box></Box>
                        <Box></Box>
                    </SimpleGrid>

                    <SimpleGrid
                        px={2}
                        spacingX={3}
                        minChildWidth="225px"
                        spacingY={'5px'}
                    >
                        <Box pt={4}>
                            <Text mb="8px">First Name</Text>
                            <Input
                                as={Input}
                                id="firstName"
                                name="firstName"
                                type="text"
                                // helperText={formik.touched.firstName ? formik.errors.firstName : ""}
                                error={true}
                                size="md"
                                onChange={formik.handleChange}
                                value={formik.values?.firstName}
                            />
                            <span style={{ color: 'red' }}>
                                {formik.touched.firstName ? formik.errors.firstName : ''}
                            </span>
                        </Box>
                        <Box pt={4}>
                            <Text mb="8px">Last Name: </Text>
                            <Input
                                as={Input}
                                id="lastName"
                                name="lastName"
                                type="text"
                                size="md"
                                onChange={formik.handleChange}
                                value={formik.values.lastName}
                            />
                            <span style={{ color: 'red' }}>
                                {formik.touched.lastName ? formik.errors.lastName : ''}
                            </span>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid p={2} spacingX={3} minChildWidth="225px" spacingY={'5px'}>
                        <Box pt={4}>
                            <Text mb="8px">Role</Text>

                            <Select
                                id="roles"
                                name="roles"
                                value={formik.values.roles}
                                onChange={(e) => {
                                    formik.values.roles = e.target.value;
                                    formik.handleChange(e);
                                }}
                            >
                                <option value={'Staff'}>Staff</option>
                                <option value={'TenantAdmin'}>Admin</option>
                            </Select>
                            <span style={{ color: 'red' }}>
                                {formik.touched.roles ? formik.errors.roles : ''}
                            </span>
                        </Box>

                        <Box pt={4}>
                            <Text mb="8px">Mobile Number: </Text>
                            <Input
                                placeholder="+1 000 111 2223"
                                id="phoneNumber"
                                name="phoneNumber"
                                type="text"
                                size="md"
                                onChange={(event) => handleChange(event, 'phoneNumber')}
                                value={formik.values.phoneNumber}
                            />
                            <span style={{ color: 'red' }}>
                                {formik.touched.phoneNumber ? formik.errors.phoneNumber : ''}
                            </span>
                        </Box>
                    </SimpleGrid>
                    <SimpleGrid p={2} spacingX={3} minChildWidth="225px" spacingY={'5px'}>
                        <Box></Box>
                        <Box float="right">
                            <SimpleGrid p={2} minChildWidth="180px" spacingY={'5px'}>
                                <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                    <Box>
                                        <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                            {(tenantId) && (
                                                <CustomButton
                                                    label={"Reset Password"}
                                                    onClick={onResetPassword}
                                                />
                                            )}
                                            <CustomButton
                                                label={"Cancel"}
                                                onClick={cancel}
                                            />
                                            <CustomButton
                                                label={userId ? "Update" : "Save"}
                                                onClick={null}
                                                type="submit"
                                            />
                                        </Flex>
                                    </Box>
                                </Flex>
                            </SimpleGrid>
                        </Box>
                    </SimpleGrid>
                </Card>
            </form>
        </Box>
    );
}
