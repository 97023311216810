import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL } from './AxiosAuthService';// Replace with your API endpoint

interface Data<T>{
    data:any
}
interface ApiResponse<T> {
  data:Data<T> ;
}

export const PersonaService = {
   getpersona: async <T>(): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/persona`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },
    getPeoplePersona: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/persona/getPeoplePersona`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectPersona: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/persona/getProjectPersona`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    createProjectPeopleRelation: async <T>(details:any[]): Promise<T>=>{
      try{
          const response: any = await axios.post(`${BASE_URL}/projectPeopleRelation`, details,getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },
    createClintRelation: async <T>(details:any[]): Promise<T>=>{
      try{
          const response: any = await axios.post(`${BASE_URL}/peopleBusinessRelation`, details,getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },
    getclientPersonas: async <T>(businessId:number): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/peopleBusinessRelation/businessRelation/${businessId}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },
    deleteClientRelation: async <T>(businessId:string): Promise<T>=>{
      try{
          const response: any = await axios.delete(`${BASE_URL}/peopleBusinessRelation/${businessId}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },
    getPersonaList: async <T>(): Promise<T> => {
        try {
          const response: any = await axios.get( `${BASE_URL}` + "/persona", getAuthHeader() );
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
    },
    getPersonaByTenantList: async <T>(tenantId:string): Promise<T> => {
        try {
          const response: any = await axios.get( `${BASE_URL}/tenant/personasByTenant/${tenantId}`, getAuthHeader() );
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
    },
  };
  
  
  
  
  const handleApiError = (error: AxiosError) => {
    if (error.response) {
      console.error('API Error Response:', error.response.data);
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
  };
