import React from 'react';
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';

interface CustomButtonProps {
    label: string;
    onClick: (e?: any) => void;
    onClickEvent?: (e: any) => void;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({ label, onClick, type = "button", disabled }) => {
    const activeColor = useColorModeValue('gray.700', 'white');

    // Default no-op function if onClick is not provided
    const handleClick = onClick || (() => { });

    return (
        <Flex justifyContent="flex-end">
            <Button
                onClick={handleClick}
                disabled={disabled || false}
                bg="brand.500"
                color="white"
                size="xs"
                borderRadius="10px"
                p="15"
                mb="5px"
                transition="background-color 0.2s"
                _hover={{ bg: 'brand.600' }}
                _active={{ bg: 'brand.700' }}
                _dark={{
                    bg: 'brand.400',
                    color: 'white',
                    _hover: { bg: 'brand.300' },
                    _active: { bg: 'brand.200' },
                }}
                mr={2}
                type={type}
            >
                {label}
            </Button>
        </Flex>
    );
};

export default CustomButton;
