// src/services/apiService.ts
import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL } from './AxiosAuthService'; // Replace with your API endpoint
 
interface Data<T>{
    data:any
}

interface ApiResponse<T> {
  data:Data<T> ;
}
export const userService = {
    createuser: async <T>(request: any): Promise<T> => {
      try {
        const response: any = await axios.post(`${BASE_URL}/user`,request, getAuthHeader());
        return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUsers: async <T>(): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/user`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUserById: async <T>(id:string): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/user/${id}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUserByUserName: async <T>(userName:string): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/user/getuser/${userName}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    }
    ,updateUserById: async <T>(values:any): Promise<T>=>{
      try{
          const response: any = await axios.put(`${BASE_URL}/user`,values, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },getUserListByTanantId: async <T>(id:number): Promise<T>=>{
      try{
          const response: any = await axios.get(`${BASE_URL}/tenant/usersByTenant/${id}`, getAuthHeader());
          return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },verifyIsEmailExist: async <T>(values: any): Promise<T> => {
      try {
        const response: any = await axios.get(`${BASE_URL}/user/getuser/${values}`, getAuthHeader());
        return response.data;
      } catch (error: any) {
        handleApiError(error);
        throw error;
      }
    },forgotPassword: async <T>(values: any): Promise<T> => {
      try {
        const response: any = await axios.get(`${BASE_URL}/user/verifyemail/${values}`, getAuthHeader());
        return response.data;
      } catch (error: any) {
        handleApiError(error);
        throw error;
      }
    }
  };
  
  
  
  
  const handleApiError = (error: AxiosError) => {
    if (error.response) {
      console.error('API Error Response:', error.response.data);
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
  };
  