import { Table, Thead, Tr, Th, Checkbox, Tbody, Td, Select, Button, useToast, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Key } from "react";
import { BusinessPersona } from "../../../../types/People";
import { Box, useColorModeValue } from '@chakra-ui/react';
import {
    createColumnHelper,
} from '@tanstack/react-table';
import { TableViewComponent } from "components/table/tablecomponent";
import { debug } from "console";
import { Personas } from "types/Persona";
import { PeopleService } from "services/PeopleService";
import Card from "components/card/Card";
import CustomButton from "../../../../components/button/CustomButton";
import { RiDeleteBin6Line } from "react-icons/ri";
export function BusinessListTable(props: { businessList: any, personaList: Personas[], deleteBusiness: any }) {

    //props;
    let activeColor = useColorModeValue('gray.700', 'white');

    const { businessList, personaList } = props;

    const toast = useToast();
    let defaultData = businessList;

    const deletePeopleBussinessPersona = async (e: any) => {
        if (e.getValue()) {
            props.deleteBusiness(e.getValue());
        }
    }


    // const deletePeopleBussinessPersona = async (e: any) => {
    //     if(e.getValue()) {
    //         try {
    //             const data: any = await PeopleService.deleteBusinessDetailsById(e.getValue());
    //             toast({
    //               title: 'People Updated.',
    //               description: 'People Updated successfully.',
    //               status: 'success',
    //               duration: 5000,
    //               isClosable: true,
    //             });
    //             const j = businessList.findIndex((item: BusinessPersona) => item.clientBusinessId === e.getValue());



    //           } catch (error) {
    //             toast({
    //               title: 'People Update Error.',
    //               description: 'People Update Error.',
    //               status: 'error',
    //               duration: 5000,
    //               isClosable: true,
    //             });
    //           }
    //     }


    //     return;
    // }

    const columns = [
        columnHelper.accessor('businessName', {
            id: 'businessName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Business Name
                </p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        Business Name
                    </p>
                    <p color={activeColor}
                        className="text-sm font-bold  dark:text-white">
                        {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                        {info.getValue()}
                    </p>
                </Box>

            ),
            size: 10
        }),
        columnHelper.accessor('businessType', {
            id: 'businessType',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Business Type
                </p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        Business Type
                    </p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </Box>
            ),
        }),
        columnHelper.accessor('industry', {
            id: 'industry',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Industry</p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Industry</p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </Box>
            ),
        }),
        columnHelper.accessor('personas', {
            id: 'personas.personaName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Role
                </p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Role</p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue().personaName}
                    </p>
                </Box>
            ),
        }),
        columnHelper.accessor('clientBusinessId', {
            id: 'clientBusinessId',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">

                </p>
            ),
            cell: (info) => (
                <Box float={"right"}>
                     <Button onClick={() => { deletePeopleBussinessPersona(info) }}><RiDeleteBin6Line /></Button>
                </Box>
            ),
        }),
    ]; // eslint-disable-next-line

    return <Box className={"pb-5 sm:overflow-auto px-2"}>
        {(businessList && businessList?.length > 0) ? <TableViewComponent
            columnsList={columns}
            tableData={props.businessList}
            enablePagination={props.businessList?.length > 15 ? true : false}
            enableSearch={true}
            saveButtonValue={null} 
            saveButtonClick={() => { }} 
            cancelButtonValue={null}
            cancelButtonClick={() => { }}></TableViewComponent> : <div>No Records Available</div>}
    </Box>;
}


const columnHelper = createColumnHelper<BusinessPersona>();