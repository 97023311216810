import { useDropzone } from 'react-dropzone';
import {
    ChakraProvider,
    Box,
    Text,
    Button,
    VStack,
    HStack,
    Image,
    SimpleGrid,
    GridItem,
    Link,
    useToast,
    Icon,
} from '@chakra-ui/react';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useColorModeValue } from '@chakra-ui/system';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { UploadService } from 'services/UploadService';
import { ProjectService } from 'services/ProjectService'; 

interface DocumentUploadProps {
    createdOn: string;
    statusName: 'Sent' | 'Approved' | 'Rejected' | 'Received';
    remainder: string;
    updatedDate: string[];
    attachments: string[];
    documentTypeName: string;
    documentTypeDescription: string;
    documentTypeAutoId: string;
    projectRequestSentId: string;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>, details: any) => void;
    uploadedFiles: string[];
}

const downloadFileClick = function (downloadFilePath: string) {
    window.open(ProjectService.getDownloadFileHeader(downloadFilePath));
};

const Dropzoneupload: React.FC<{
    onDrop: (acceptedFiles: File[]) => void;
    content: React.ReactNode;
    isRejected?: boolean;
    isSent?: boolean;
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
    uploadedFiles: string[];
}> = ({ onDrop, content, isRejected, isSent, handleFileChange, uploadedFiles }) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Box
            {...getRootProps()}
            borderWidth="1px"
            borderRadius="md"
            p={4}
            textAlign="center"
            borderStyle="dashed"
            width="100%"
            height="200px"
            bg={isRejected ? 'red.100' : isSent ? 'gray.300' : 'inherit'}
        >
            <input {...getInputProps()} onChange={handleFileChange} />
            {content}
            {uploadedFiles.length > 0 && (
                <Box mt={4}>
                    <VStack align="center" spacing={1}>
                        {uploadedFiles.map((fileName, index) => (
                            <Text key={index} fontSize="sm">
                                {fileName}
                            </Text>
                        ))}
                    </VStack>
                </Box>
            )}
        </Box>
    );
};

const DocumentUpload: React.FC<DocumentUploadProps> = ({
    createdOn,
    statusName,
    remainder,
    updatedDate,
    attachments,
    documentTypeName,
    documentTypeDescription,
    documentTypeAutoId,
    projectRequestSentId,
    handleFileChange,
    uploadedFiles,
}) => {
    const statusColor = {
        Approved: 'green.500',
        Rejected: 'red.500',
        Sent: 'gray.500',
        Received: 'none',
        Acknowledged: 'yellow.500'
    }[statusName] || 'gray.100';

    const statusText = {
        Sent: 'Add Your Files Here',
        Approved: 'Approved! Great Job',
        Rejected: "Let's try again!",
        Received: "Nothing",
    }[statusName] || 'Add Your Files Here';

    const handleDrop = (acceptedFiles: File[]) => {
        const event = {
            target: {
                files: acceptedFiles,
            },
        } as unknown as ChangeEvent<HTMLInputElement>;
        handleFileChange(event, { documentTypeAutoId, projectRequestSentId, label: 'exampleLabel' });
    };

    const renderStatusComponent = () => {
        if (statusName === 'Sent' || statusName === 'Rejected') {
            return (
                <Dropzoneupload
                    onDrop={handleDrop}
                    content={
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            height="100%"
                        >
                            <Icon as={MdOutlineCloudUpload} w="40px" h="40px" />
                            <Text
                                mx="auto"
                                mb="12px"
                                fontSize="lg"
                                fontWeight="700"
                                whiteSpace="pre-wrap"
                                color={statusName === 'Rejected' ? 'red.500' : 'inherit'}
                            >
                                {statusText}
                            </Text>
                        </Box>
                    }
                    isRejected={statusName === 'Rejected'}
                    isSent={statusName === 'Sent'}
                    handleFileChange={(e) =>
                        handleFileChange(e, { documentTypeAutoId, projectRequestSentId, label: 'exampleLabel' })
                    }
                    uploadedFiles={uploadedFiles}
                />
            );
        } else if (statusName === 'Approved') {
            return (
                <Button colorScheme="green" variant="solid" bg="green.500" w="100%" minH="200px">
                    {statusText}
                </Button>
            );
        } else if (statusName == 'Received' || statusName == 'Acknowledged') {
            return (
                <Box  bg="yellow.200" w="100%" minH="200px" borderRadius={"10px"}>
                    <Text fontWeight={"bold"} textAlign={"center"} paddingTop={"80px"} className={"red.500"} verticalAlign={"middle"}>{statusName}</Text>
                </Box>
            );
        }
        return null;
    };

    return (
        <Box p={4} borderWidth="2px" borderRadius="md" width="100%">
            <HStack>
            <VStack align="start" spacing={4} w="100%">
                <Text fontWeight="bold">{documentTypeName}</Text>
                <Text fontWeight="bold" color="gray.300">
                    {documentTypeDescription}
                </Text>
                <Box display="flex" width="100%">
                    <SimpleGrid columns={5} spacing={2} flex="1">
                        <GridItem paddingTop={"40px"} colSpan={1}>
                            <Text fontWeight="bold">Created On</Text>
                            <Text>{createdOn}</Text>
                        </GridItem>
                        <GridItem paddingTop={"40px"} colSpan={1}>
                            <Text fontWeight="bold">Reminder</Text>
                            <Text>{remainder}</Text>
                        </GridItem>
                        <GridItem paddingTop={"40px"} colSpan={1}>
                            <Text fontWeight="bold">Status</Text>
                            <Text color={statusColor}>{statusName}</Text>
                        </GridItem>
                        <GridItem paddingTop={"40px"} colSpan={1}>
                            <Text fontWeight="bold">Updated Date</Text>
                            {updatedDate.map((date, index) => (
                                <Text key={index}>{date}</Text>
                            ))}
                        </GridItem>
                        <GridItem paddingTop={"40px"} colSpan={1} maxWidth={"220px"}>
                            <Text fontWeight="bold">Attachment</Text>
                            {attachments.map((attachment, index) => (
                                <Link
                                    key={index}
                                    href="#"
                                    onClick={() => downloadFileClick(attachment)}
                                    color="blue.500"
                                    isTruncated
                                    display="block"
                                    maxW="100%"
                                >
                                    {attachment.replace(/^.*[\\/]/, '')}
                                </Link>
                            ))}
                        </GridItem>
                    </SimpleGrid>
                    
                </Box>
            </VStack>
            <Box ml="4" minW={"150px"} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {renderStatusComponent()}
            </Box>

            </HStack>
            
        </Box>
    );
};

function UploadPage() {
    const { token } = useParams();
    const location = useLocation();
    const bg = useColorModeValue('background.100', 'background.900');

    const [projectRequestDetails, setProjectRequestDetails] = useState<any>();
    const [fileTypes, setFileTypes] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [uploadLoading, setUploadLoading] = useState<boolean>(false);
    const [clientDetails, setClientDetails] = useState<any>();
    const [isError, setIsError] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const toast = useToast();
    const [fileDataMap, setFileDataMap] = useState<{ [key: string]: { files: File[], details: any } }>({});
    const [documentData, setDocumentData] = useState<DocumentUploadProps[]>([]);

    useEffect(() => {
        const token = location.pathname.replace('/upload/', '');
        getProjectRequestDetails(token);
    }, [location.pathname]);

    const getProjectRequestDetails = async (token: string) => {
        try {
            const data: any = await UploadService.postProjectEmailDetails(token);
            if (data && data.project && data.projectRequestResponse && data.fileFormt) {
                setProjectRequestDetails(data);

                const fileFormats = data.fileFormt.map((element: any) => element.fileTypeName);
                setFileTypes(fileFormats);

                const transformedData: DocumentUploadProps[] = data.projectRequestResponse.map((item: any) => ({
                    createdOn: item.createdDate,
                    statusName: item.statusName,
                    remainder: item.remindersDetails,
                    updatedDate: item.updatedDate ? [item.updatedDate] : ['N/A'],
                    attachments: item.downloadFilePath ? [item.downloadFilePath] : [],
                    documentTypeName: item.documentTypeName,
                    documentTypeDescription: item.documentTypeDescription,
                    documentTypeAutoId: item.documentTypeAutoId,
                    projectRequestSentId: item.projectRequestSentId,
                    handleFileChange: handleFileChange,
                    uploadedFiles: fileDataMap[item.documentTypeAutoId]?.files.map(file => file.name) || [],
                }));

                setDocumentData(transformedData);
                setIsError(false);
            } else {
                setIsError(true);
            }
        } catch (error) {
            setError('Error fetching user data');
            setIsError(true);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, details: any) => {
        const files = event.target.files;
        if (files) {
            setFileDataMap(prevMap => ({
                ...prevMap,
                [details.documentTypeAutoId]: {
                    files: [...(prevMap[details.documentTypeAutoId]?.files || []), ...Array.from(files)],
                    details,
                },
            }));
        }
    };

    const handleSave = async () => {
        if (!projectRequestDetails || Object.keys(fileDataMap).length === 0) {
            toast({
                title: 'Please select a file to upload',
                status: 'error',
                duration: 13000,
                position: 'top',
                isClosable: true,
            });
            return;
        }

        try {
            const { fileFormt, projectRequestResponse, projectRequest } = projectRequestDetails;
            const token = location.pathname.replace('/upload/', '');
            const tenantId = fileFormt[0]?.tenantId;
            const projectId = projectRequest.projectId;
            const peopelId = projectRequestResponse[0].peopleId;

            let apiCalls: Promise<void>[] = [];
            Object.keys(fileDataMap).forEach(documentTypeAutoId => {
                const { files, details } = fileDataMap[documentTypeAutoId];
                files.forEach((file, index) => {
                    apiCalls.push(blobToData(file, index, projectRequest, tenantId, projectId, peopelId, fileFormt, token, details));
                });
            });

            await Promise.all(apiCalls);
        } catch (error) {
            handleUploadError(error);
        }
    };

    const blobToData = (
        blob: Blob,
        index: any,
        projectRequest: any,
        tenantId: any,
        projectId: any,
        peopelId: any,
        fileFormt: any,
        token: any,
        details: any
    ): Promise<void> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                try {
                    setUploadLoading(true);
                    const fileDataUrl = event.target.result as string;
                    const base64Data = fileDataUrl.split(',')[1];
                    const fileext = fileFormt[0]?.fileTypeName;
                    const documentTypeAutoId = details.documentTypeAutoId;
                    const projectRequestSentId = details.projectRequestSentId;
                    const projectRequestId = projectRequest?.projectRequestId;
                    const label = details.label;

                    const params = {
                        token: token,
                        tenantId: tenantId,
                        projectId: projectId,
                        projectRequestId: projectRequestId,
                        projectRequestSentId: projectRequestSentId,
                        peopelId: peopelId,
                        documentTypeAutoId: documentTypeAutoId,
                        fileData: base64Data,
                        fileext: '.' + fileext,
                        fileName: `${label}_${projectId}_${projectRequestSentId}_${peopelId}`,
                    };

                    await UploadService.postProjectRequestUploadFiles(params);
                    toast({
                        title: 'Files uploaded successfully',
                        status: 'success',
                        duration: 13000,
                        position: 'top',
                        isClosable: true,
                    });

                    setUploadLoading(false);
                    getProjectRequestDetails(token);
                    resolve();
                } catch (error) {
                    handleUploadError(error);
                    reject(error);
                }
            };
            reader.readAsDataURL(blob);
        });
    };

    const handleUploadError = (error: any) => {
        console.error('Error uploading file:', error);
        toast({
            title: 'Error uploading file',
            description: error || 'An error occurred while uploading the file',
            status: 'error',
            duration: 5000,
            position: 'top',
            isClosable: true,
        });
    };

    return (
        <ChakraProvider>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%" bg="gray.50">
                <Box minWidth="100%" maxWidth="100%" p={4}>
                    <Box bg="blue.500" p={4} color="white" position="relative">
                        <Button position="absolute" top={3} right={3} colorScheme="white" variant="link">
                            Help ?
                        </Button>
                        <VStack align="start" spacing={0}>
                            <Text fontSize="3xl" fontWeight="bold">
                                Minerva
                            </Text>
                            <Text fontSize="xs">Small business funding made easy!</Text>
                        </VStack>
                    </Box>

                    <Box p={8} bg="white" borderRadius="md" boxShadow="md">
                        <VStack align="start" spacing={4}>
                            <Text fontSize="xl" fontWeight="bold">
                                {projectRequestDetails?.people?.firstName} {projectRequestDetails?.people?.lastName },
                            </Text>
                            <Box borderWidth="2px" borderRadius="md" bg="gray.100" p={4} w="full">
                                <HStack align="start" spacing={4}>
                                    <Image borderRadius="full" boxSize="50px" src="https://bit.ly/dan-abramov" alt="Minerva Logo" />
                                    <VStack align="start" spacing={2} flex="1">
                                        <Text>
                                            Hi, I'm Jason and I'll be working with you to ensure that your loan process goes
                                            smoothly. The first step to getting funding is assessing your "lendability". We do
                                            this by collecting some critical financial information, analyzing that information
                                            and producing a Lendability Report. Let's get start
                                        </Text>
                                    </VStack>
                                </HStack>
                                <Text mt={4}>
                                    We know filling out documents and uploading information can seem daunting, we want
                                    to make this process as easy as possible for you. Think of this link as your Todo
                                    list. Review the list of items we need and start uploading them. If some are easier
                                    for you to attain, great, simply upload those first and start working on the others.
                                    As you are ready to upload new documents, simply come back to this link click the
                                    corresponding upload button and upload your documents.
                                </Text>
                            </Box>
                        </VStack>

                        <VStack mt={8} spacing={4} align="stretch">
                            {documentData.length > 0 ? (
                                documentData.map((doc, index: number) => (
                                    <DocumentUpload
                                        key={index}
                                        createdOn={doc.createdOn}
                                        statusName={doc.statusName}
                                        remainder={doc.remainder}
                                        updatedDate={doc.updatedDate}
                                        attachments={doc.attachments}
                                        documentTypeName={doc.documentTypeName}
                                        documentTypeDescription={doc.documentTypeDescription}
                                        documentTypeAutoId={doc.documentTypeAutoId}
                                        projectRequestSentId={doc.projectRequestSentId}
                                        handleFileChange={handleFileChange}
                                        uploadedFiles={fileDataMap[doc.documentTypeAutoId]?.files.map(file => file.name) || []}
                                    />
                                ))
                            ) : (
                                <Text>No project request details available.</Text>
                            )}
                        </VStack>
                        <Box display="flex" justifyContent="flex-end" mt={8}>
                            <Button colorScheme="blue" onClick={handleSave}>
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ChakraProvider>
    );
}

export default UploadPage;
