
import { Box, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import Progress from 'components/progress';
import { FcAlarmClock } from 'react-icons/fc';


const FileInformation = (props: { file: any, isHover?: any, assignedUser?: any }) => {
    const { file, isHover,assignedUser  } = props;
    
    let activeColor = useColorModeValue('gray.700', 'white');
    return (
        <Card color={activeColor} extra={isHover ? "bg-[#000] mt-3 !z-5 overflow-hidden hover:bg-brand-300" : "bg-[#000] mt-3 !z-5 overflow-hidden"}>
            {/* HistoryCard Header */}
            <div color={activeColor} className="flex items-center justify-between rounded-t-3xl p-3  sm:ml-4 sm:mr-4">
                <div className="dark:text-white">
                    <p className='text-lg font-bold '>{file?.projectDetails?.project?.projectName}</p>
                    <p color={activeColor} className="text-xs dark:text-white">File Id : {file?.projectDetails?.project?.projectId}</p>
                    {/*{ file?.people && file?.people.length > 0 ? <p  className="text-xs font-bold dark:text-white">{file?.people[0].firstName} {file?.people[0].lastName} </p> : "" }*/}

                </div>
               <div className=" dark:text-white">
                    {assignedUser?.firstName && assignedUser?.lastName ? <Box>
                        <p className="text-xs dark:text-white text-end  font-bold">{assignedUser?.firstName} {assignedUser?.lastName} </p>
                    <p className="text-xs dark:text-white text-end">Assigned Staff</p>

                    </Box> : <Box></Box>  }
                </div>
            </div>
            <div className="flex items-center justify-center  p-4">
                <p className='text-[40px] text-center font-bold '>
                    ${new Intl.NumberFormat("en-US").format(file?.projectDetails?.project?.amount)}
                </p>
            </div>
            <div className="flex items-center justify-between rounded-t-3xl p-3 border-b sm:ml-4 sm:mr-4">
                <div className=" dark:text-white">
                    <p className='text-lg font-bold '>{file?.fileOwner}</p>
                </div>
            </div>
            <div className="flex items-center justify-between rounded-t-3xl p-3 sm:ml-4 sm:mr-4  text-start">
                <div className="md:w-[230px]  dark:text-white">
                    {file?.projectDetails?.status?.statusName ? <Box>
                        <p className="text-sm font-bold  pt-4">{file?.projectDetails?.status?.statusName}</p>
                        <p className="text-sm  dark:text-white">Status</p>
                    </Box> : <></>}
                </div>
                <div className=" dark:text-white text-end">
                    { file?.projectDetails?.industry?.industrySector ? <Box>
                        <p className="flex items-center justify-between text-sm font-bold  dark:text-white text-sm font-bold ">
                        {file?.projectDetails?.industry?.industrySector}
                        </p>
                        <p className="text-sm  dark:text-white">Industry</p>
                        </Box> : <></>}
                    
                </div>
            </div>
        </Card>
    );
};

export default FileInformation;
