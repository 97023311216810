// Chakra imports
import {
    Button,
    Divider,
    Flex,
    Input,
    Select,
    SimpleGrid,
    Stack,
    Textarea,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import CustomButton from 'components/button/CustomButton';
import Card from 'components/card/Card';
import SpinnerPage from 'components/spinner/spinner';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import { TemplateService } from 'services/TemplateService';
import * as Yup from 'yup';

// Custom components

export default function AddRequestTemplatePage() {
    const toast = useToast();
    let formInit = {
        requestTemplateName: '',
        requestTemplateDescription: '',
        remindersAutoId: '',
        requestTemplateDetails: [
            {
                documentTypeAutoId: 1,
                label: '',
            },
        ],
    };


    const bg = useColorModeValue('background.100', 'background.900');
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const [reminderList, setReminderList] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { requestTemplateId } = useParams();
    const [requestTemplateDetails, setRequestTemplateDetails] = useState([]);
    const [requestTemplateDetailsForm, setRequestTemplateDetailsForm] = useState(formInit);
    const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        requestTemplateName: Yup.string().required('First Name is required'),
        requestTemplateDescription: Yup.string().required('Description is required',
        ),

    });
    const formik = useFormik({
        initialValues: formInit,
        enableReinitialize: false,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            let isValid = true;
            if(!values.remindersAutoId) {
                values.remindersAutoId = reminderList[0].remindersAutoId;
            }
            values.requestTemplateDetails = requestTemplateDetails;
            for (let i = 0; i < values.requestTemplateDetails.length; i++) {
                if (!values.requestTemplateDetails[i].label || values.requestTemplateDetails[i].label.trim() == "") {
                    isValid = false;
                    toast({
                        title: 'Invalid Document Types',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    return;
                } else if (values.requestTemplateDetails[i].documentTypeAutoId==1) {
                    values.requestTemplateDetails[i].documentTypeAutoId = documentTypes[0].documentTypeAutoId;
                }
            }
            if (requestTemplateId) {
                try {
                    const data: any = await TemplateService.updateRequestTemplates(values);
                    toast({
                        title: values.requestTemplateName + ' successfully updated.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    formik.values = formInit;
                    resetForm();
                } catch (error) {
                    toast({
                        title: 'Error updating template',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            } else {
                try {
                    const data: any = await TemplateService.postRequestTemplates(values);
                    toast({
                        title: values.requestTemplateName + ' successfully created.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    formik.values = formInit;
                    navigate('/admin/requestTemplate');
                    resetForm();

                } catch (error) {
                    toast({
                        title: 'Error creating template',
                        //description: values.fileName + ' Project update error.',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            }
        },
    });

    useEffect(() => {
        fetchData();
        getRequestDetailsById();
        return () => { };
    }, []);

    const addNewRequestTemplate = function () {
        let documentTemplate = [...formik.values.requestTemplateDetails];
        documentTemplate.push({
            documentTypeAutoId: 1,
            label: '',
        });
        formik.values.requestTemplateDetails = documentTemplate;
        setRequestTemplateDetails(documentTemplate);
        setRequestTemplateDetailsForm(formik.values);
    };

    const fetchData = async () => {
        setLoading(true);

        setRequestTemplateDetails(formInit.requestTemplateDetails);
        setRequestTemplateDetailsForm(formInit);
        await getReminderList();
        await getDocumentTypeList();
        setLoading(false);
    };

    const getReminderList = async () => {
        try {
            let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
            const data: any = await TemplateService.getReminderListByTenantId(tenantId);
            setReminderList(data);
        } catch (error) {
            setError('Error fetching people data');
        } finally {
            setLoading(false);
        }
    };

    const getDocumentTypeList = async () => {
        try {
            let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
            const data: any = await TemplateService.getDocumentListByTenantId(tenantId);
            setDocumentTypes(data.documentTypes);
        } catch (error) {
            setError('Error fetching people data');
        } finally {
            setLoading(false);
        }
    };

    const getRequestDetailsById = async () => {
        try {
            const data: any = await TemplateService.getRequestDetailsById(parseInt(requestTemplateId));
            formik.values.remindersAutoId = data.remindersAutoId;
            formik.values.requestTemplateName = data.requestTemplateName;
            formik.values.requestTemplateDescription = data.requestTemplateDescription;
            formik.values.requestTemplateDetails = data.requestTemplateDetails.map((detail: any) => ({
                documentTypeAutoId: detail.documentTypeAutoId,
                label: detail.label,
            }));
            setRequestTemplateDetails(formInit.requestTemplateDetails);
        } catch (error) {
            setError('Error fetching people data');
        } finally {
            setLoading(false);
        }
    };

    const onDocumentRequestNameChange = (e: any, index: any) => {
        let docDet = [...requestTemplateDetails];

        docDet[index].label = e.target.value;
        setRequestTemplateDetails(docDet);
    };

    const onDocumentTypeChange = (e: any, index: any) => {
        let docDet = [...requestTemplateDetails];

        docDet[index].documentTypeAutoId = e.target.value;
        setRequestTemplateDetails(docDet);
    };


    const onRemoveDocumentType = (e: any, index: any) => {
        let docDet = [...requestTemplateDetails];
        docDet.splice(index, 1);
        setRequestTemplateDetails(docDet);
    };

    const cancelRequest = () => {
        navigate('/admin/requestTemplate');
    };

    return (
        <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-5 cardH'}>
            {loading ? (
                <SpinnerPage />
            ) : (
                <form onSubmit={formik.handleSubmit}>                

                    <SimpleGrid p={2} spacingX={3} minChildWidth="225px" spacingY={'5px'}>
                        <Box pt={5}>
                            <Text mb="8px">Request Template Name: </Text>
                            <Input
                                placeholder="TemplateName"
                                id="requestTemplateName"
                                name="requestTemplateName"
                                type="requestTemplateName"
                                size="md"
                                onChange={formik.handleChange}
                                value={formik.values?.requestTemplateName}
                            />
                            <span style={{ color: 'red' }}>
                                {formik.touched.requestTemplateName
                                    ? formik.errors.requestTemplateName
                                    : ''}
                            </span>
                        </Box>
                    </SimpleGrid>

                    <SimpleGrid p={2} spacingX={3} minChildWidth="225px" spacingY={'5px'}>
                        <Box>
                            <Text mb="8px">Template Description: </Text>
                            <Textarea
                                placeholder="Template Description"
                                id="requestTemplateDescription"
                                name="requestTemplateDescription"
                                size="md"
                                height={{ base: '150px', md: '100px' }} // Adjusted height for responsiveness
                                onChange={formik.handleChange}
                                value={formik.values.requestTemplateDescription}
                            />
                            <span style={{ color: 'red' }}>
                                {formik.touched.requestTemplateDescription
                                    ? formik.errors.requestTemplateDescription
                                    : ''}
                            </span>
                        </Box>
                    </SimpleGrid>

                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                        <Box>
                            <Text mb="8px">Reminder:</Text>
                            <Select
                                    name="remindersAutoId"
                                    id='remindersAutoId'
                                    value={formik.values.remindersAutoId}
                                onChange={formik.handleChange}
                            >
                                {reminderList?.map((reminder) => (
                                    <option
                                        key={reminder.remindersAutoId}
                                        value={reminder.remindersAutoId}
                                    >
                                        {reminder.details}
                                    </option>
                                ))}
                            </Select>
                            <span style={{ color: 'red' }}>
                                    {formik.touched.remindersAutoId ? formik.errors.remindersAutoId : ''}
                            </span>
                        </Box>
                        <Box></Box>
                    </SimpleGrid>

                    <Divider mt={4} />

                    {requestTemplateDetails?.map((requestTemplate, index) => {
                        return (
                            <Box key={index}>
                                <SimpleGrid

                                    p={2}
                                    spacingX={3}
                                    minChildWidth="225px"
                                    spacingY={'5px'}
                                >
                                    <Box
                                        className="rounded-[10px]"
                                        p="15px"
                                        mt="10px"
                                        borderColor={paleGray}
                                        border={'1px'}
                                    >
                                        {index != 0 ? <Box >
                                            <Button float={'right'} onClick={(e) => {
                                                onRemoveDocumentType(e, index);
                                            }}>
                                                <RiDeleteBin6Line />
                                            </Button>
                                        </Box> : ""}
                                        <SimpleGrid
                                            p={2}
                                            spacingX={3}
                                            minChildWidth="225px"
                                            spacingY={'5px'}
                                        >
                                            <Box pt={5}>
                                                <Text mb="8px">Label: </Text>
                                                <Input
                                                    placeholder="Label"
                                                    id={"docLabel" + index}
                                                    name={"docLabel" + index}
                                                    type="text"
                                                    size="md"
                                                    onChange={(e) => {
                                                        onDocumentRequestNameChange(e, index);
                                                    }}
                                                    value={requestTemplate.label}
                                                />
                                                <span style={{ color: 'red' }}>
                                                    {/* {formik.touched.docLabel
                ? formik.errors.docLabel
                : ''} */}
                                                </span>
                                            </Box>
                                        </SimpleGrid>
                                        <SimpleGrid
                                            p={2}
                                            spacingX={2}
                                            minChildWidth="225px"
                                            columns={2}
                                        >
                                            <Box>
                                                <Text mb="8px">DocumentType:</Text>
                                                <Select
                                                    name="docType"
                                                    value={requestTemplate?.documentTypeAutoId}
                                                    onChange={(e) => {
                                                        onDocumentTypeChange(e, index);
                                                    }}
                                                >
                                                    {documentTypes.map(function (documentType) {
                                                        return (
                                                            <option
                                                                key={documentType.documentTypeAutoId}
                                                                value={documentType.documentTypeAutoId}
                                                            >
                                                                {documentType.documentTypeName}
                                                            </option>
                                                        );
                                                    })}
                                                </Select>
                                                <span style={{ color: 'red' }}>
                                                    {/* {formik.touched.assignedStaff ? formik.errors.assignedStaff : ''} */}
                                                </span>
                                            </Box>
                                        </SimpleGrid>
                                    </Box>
                                </SimpleGrid>
                            </Box>
                        );
                    })}
                    <Divider mt={4} />
                    <Flex justify={{ base: 'center', md: 'flex-start' }} mt={4}>
                        <CustomButton label={'Add More'} onClick={addNewRequestTemplate} />
                    </Flex>
                    <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                            <CustomButton
                                label={"Cancel"}
                                onClick={cancelRequest}
                            />
                            <CustomButton
                            label={"Save"}
                            onClick={null}
                            type="submit"
                        />

                        
                    </Flex>
                </form>
            )}
        </Card>
    );
}
