import React, { useEffect, useState } from 'react';

import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table';
import {
    Box,
    Button,
    SimpleGrid,
    Table,
    Tbody,
    Thead,
    useColorModeValue,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import SearchIcon from 'components/icons/SearchIcon';
import Card from 'components/card/Card';
import { Users } from 'types/Users';
import 'assets/css/TableResponsive.css';
import { TableViewComponent } from 'components/table/tablecomponent';
import { phoneNumberAutoFormat } from 'components/phonenumber/phoneNumberAutoFormat';

function StaffListTable(props: { tableData: any; tenantDetails: any }) {
    const navigate = useNavigate();

    const bg = useColorModeValue('background.100', 'background.900');
    const { tableData, tenantDetails } = props;

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [],
    );
    let defaultData = tableData;
    // const [tableView, setTableView] = React.useState(true);
    // const [fileDetailsView, setFileDetailsView] = React.useState(false);
    const [listViewCount, setListViewCount] = React.useState(15);
    const [globalFilter, setGlobalFilter] = React.useState('');
    const [adminTenant, setAdminTenant] = React.useState(null);
    const [isTenantAdmin, setIsTenantAdmin] = useState<boolean>(false);
    const [userLoggedIn] = useState(
        JSON.parse(localStorage.getItem('userLoginStatus')),
    );

    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };

    const checkInitialValue = () => {
        let roleType = userLoggedIn?.userDetails?.userInfo?.roles;
        if (roleType === 'TenantAdmin') {
            // tenentId = userLoggedIn?.userDetails?.userInfo?.tenant.tenantId;
            // formik.values.tenantId = tenentId;
            // tenantSelected = userLoggedIn?.userDetails?.userInfo?.tenant;
            // isStaffListView = 0;
            setIsTenantAdmin(true);
            setAdminTenant(userLoggedIn?.userDetails?.userInfo?.tenant);
        } else if (roleType !== 'Admin') {
            navigate('/');
        }
    };

    useEffect(() => {
        checkInitialValue();
    });

    const onPeopleClick = (email: string) => {
        let selectedUser: Users = data.find((user) => user.email === email);
        // let tenantIdSelected = isTenantAdmin? adminTenant.
        navigate('/admin/tenant/AddTenant/AddStaff', {
            state: {
                tenantId: isTenantAdmin ? adminTenant.tenantId : tenantDetails.tenantId,
                tenantDetails: isTenantAdmin ? adminTenant : tenantDetails,
                userId: email,
                userDetails: selectedUser,
            },
        });
    };

    const columns = [
        columnHelper.accessor('userName', {
            id: 'userName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Username
                </p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        First Name#
                    </p>
                    <p
                        style={{ textDecoration: 'underline' }}
                        color={activeColor}
                        className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                        onClick={() => {
                            onPeopleClick(info.getValue());
                        }}
                    >
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),
        columnHelper.accessor('firstName', {
            id: 'firstName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">FirstName</p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        FirstName
                    </p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),columnHelper.accessor('lastName', {
            id: 'lastName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">LastName</p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        FirstName
                    </p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),
        columnHelper.accessor('isAdminUser', {
            id: 'isAdminUser',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Role</p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        Role
                    </p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue() == 1 ? 'Admin' : 'Staff'}
                    </p>
                </div>
            ),
        }),

        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Email</p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        Email
                    </p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),
        columnHelper.accessor('phoneNumber', {
            id: 'phoneNumber',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Phone</p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        Phone
                    </p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {phoneNumberAutoFormat(info.getValue())}
                    </p>
                </div>
            ),
        }),
        // columnHelper.accessor('role', {
        //   id: 'Role',
        //   header: () => (
        //     <p className="text-sm font-bold text-gray-600 dark:text-white">
        //       Role
        //     </p>
        //   ),
        //   cell: (info) => (
        //     <p color={activeColor} className="text-sm font-bold dark:text-white">
        //       {info.getValue()}
        //     </p>
        //   ),
        // }),
    ]; // eslint-disable-next-line

    const [data, setData] = React.useState(() => [...defaultData]);
    const [listFilterData, setListFilterData] = React.useState(() => [
        ...defaultData,
    ]);
    const [peopleId, setPeopleId] = React.useState(1);
    const [selectedPeople, setSelectedPeople] = React.useState(null);
    const [{ pageIndex, pageSize }, setPagination] =
        React.useState<PaginationState>({
            pageIndex: 0,
            pageSize: 15,
        });
    let activeColor = useColorModeValue('gray.700', 'white');

    // const addNewProjectClick = () => {
    //   navigate('/admin/dashboards/AddProject');
    // };

    const onChangeSearchFilter = (e: any) => {
        setGlobalFilter(e.target.value);
        setListViewCount(15);
        let filterData: any[] = [];
        table.getFilteredRowModel().rows.forEach((row) => {
            filterData.push(row.original);
        });
        setListFilterData(filterData);
    };

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    return (
        <Box bg={bg}>

            {/* View Header Start */}
            {/* <SimpleGrid p={2} spacingX={4} minChildWidth="225px" spacingY={'20px'}>
          <Box
            mt={'20px'}
            className="flex h-[38px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white"
          >
            <SearchIcon />
            <input
              value={globalFilter ?? ''}
              onChange={(e: any) => onChangeSearchFilter(e)}
              type="text"
              placeholder="Search...."
              className="block w-full rounded-full bg-lightPrimary text-base text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
            />
          </Box>
          <Box></Box>
        </SimpleGrid> */}
            {/* <div className="flex justify-between max-w-full items-center rounded-xl pt-[20px]">
          <div className="flex h-[38px] w-[400px] flex-grow items-center rounded-xl bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900 dark:text-white">
            
          </div>
          <div className="flex  h-[38px] w-[400px] flex h-20 items-center justify-end px-6"></div>
        </div> */}
            <GetTableView />

        </Box>
    );

    function GetTableView(props: any) {
        return <div className="overflow-x-scroll xl:overflow-x-hidden">
            {(defaultData && defaultData?.length > 0) ? <TableViewComponent
                columnsList={columns}
                tableData={defaultData}
                enablePagination={true}
                enableSearch={true}
                saveButtonValue={null}
                saveButtonClick={null}
                cancelButtonValue={null}
                cancelButtonClick={null}
                viewSaveCancel={true}
            ></TableViewComponent> : <div>No Records Available</div>}
        </div>

    }

    function GetTableViews(props: any) {
        return (
            <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
                <Table className="w-full responsiveTable">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id} className="!border-px !border-gray-400">
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer border-b border-gray-200 pb-2 pr-4 pt-4 text-start dark:border-white/30"
                                        >
                                            <div className="items-center justify-between text-xs text-gray-200">
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table
                            .getRowModel()
                            .rows.slice(0, 7)
                            .map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className="min-w-[150px] border-white/0 py-3  pr-4"
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </Tbody>
                </Table>
                {/* pagination */}
                <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
                    {/* left side */}
                    <div className="flex items-center gap-3">
                        <p className="> Show rows per page text-sm text-gray-700">
                            Showing 15 rows per page
                        </p>
                    </div>
                    {/* right side */}
                    <div className="flex items-center gap-2">
                        <Button
                            colorScheme="blue"
                            disabled={!table.getCanPreviousPage()}
                            onClick={() => table.previousPage()}
                            bg="brand.500"
                            color="white"
                            size="md"
                            borderRadius="full"
                            p="2"
                            transition="background-color 0.2s"
                            _hover={{ bg: 'brand.600' }}
                            _active={{ bg: 'brand.700' }}
                            _dark={{
                                bg: 'brand.400',
                                color: 'white',
                                _hover: { bg: 'brand.300' },
                                _active: { bg: 'brand.200' },
                            }}
                        >
                            <MdChevronLeft />
                        </Button>

                        {createPages(table.getPageCount()).map((pageNumber, index) => {
                            return (
                                <button
                                    color={activeColor}
                                    className={`linear flex h-10 w-10 items-center justify-center rounded-full p-2 text-sm transition duration-200 ${pageNumber === pageIndex + 1
                                            ? 'bg-blue-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
                                            : 'border-[1px] border-gray-400 bg-transparent dark:border-white dark:text-white'
                                        }`}
                                    onClick={() => table.setPageIndex(pageNumber - 1)}
                                    key={index}
                                >
                                    {pageNumber}
                                </button>
                            );
                        })}

                        <Button
                            colorScheme="blue"
                            disabled={!table.getCanNextPage()}
                            onClick={() => table.nextPage()}
                            bg="brand.500"
                            color="white"
                            size="md"
                            borderRadius="full"
                            p="2"
                            transition="background-color 0.2s"
                            _hover={{ bg: 'brand.600' }}
                            _active={{ bg: 'brand.700' }}
                            _dark={{
                                bg: 'brand.400',
                                color: 'white',
                                _hover: { bg: 'brand.300' },
                                _active: { bg: 'brand.200' },
                            }}
                        >
                            <MdChevronRight />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default StaffListTable;
const columnHelper = createColumnHelper<Users>();
