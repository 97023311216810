// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useState } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { routeDefault,routeTenant_Admin, routeTenant_TenantAdmin, routeTenant_Staff, routeAccount, getActiveRoutes, getAllValidRoutes, getDynamicLinks } from 'routes';

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  const navigate = useNavigate();


  const [userLoggedIn] = useState(
    JSON.parse(localStorage.getItem("userLoginStatus"))
  );
  
  if(!userLoggedIn || !(userLoggedIn?.isUserLoggedIn) ) {
    navigate("/");
  }

  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRouteDisplayName = (routes: RoutesType[]): string => {
    let activeRoute = 'Page';
    let searchString = window.location.pathname;

    let splitStrings = searchString.split('/');
    let searchFString = "";
    if(splitStrings && splitStrings.length > 0 && !isNaN(splitStrings[splitStrings.length-1] as any)) {
      let setString = getDynamicLinks(searchString);
      
      for(var i=0; i<splitStrings.length-1;i++ ) {
        if(splitStrings[i]) {
          searchFString = searchFString+"/" + splitStrings[i];
        }
        
      }
      searchFString = searchFString+"/" + setString;
      searchString = searchFString;
    } 
    for (let i = 0; i < routes?.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRouteDisplayName(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          searchString == (routes[i].layout + routes[i].path)
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes?.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {
      if (route.layout === '/admin') {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };
  
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');
  return (
    <Box bg={bg} h="100vh" w="100vw">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          setHovered={setHovered}
          mini={mini}
          layout = "admin"
          routes={getActiveRoutes()}
          display="none"
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={
            mini === false
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : mini === true && hovered === true
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : { base: '100%', xl: 'calc( 100% - 120px )' }
          }
          maxWidth={
            mini === false
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : mini === true && hovered === true
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : { base: '100%', xl: 'calc( 100% - 120px )' }
          }
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                logoText={'Minerva UI Dashboard PRO'}
                brandText={getActiveRouteDisplayName(getAllValidRoutes())}
                secondary={getActiveNavbar(getActiveRoutes())}
                theme={props.theme}
                breadCrumbs = {props.breadCrumbs}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              top="10px"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="80vh"
              pt="50px"
            >
              <div style={{marginBottom: "70px"}}></div>
              <Routes>
                {getRoutes(getAllValidRoutes())}
                              {userLoggedIn?.userDetails?.userInfo?.roles == "Admin" ? <Route
                  path="/"
                  element={<Navigate to="/admin/tenent" replace />}
                /> :  
                <Route path="/"
                      element={<Navigate to="/admin/projects" replace />}
                                  />
                              }
               
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
