import React, { useEffect, useState } from 'react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { Box, Button, Center, Spinner, useToast } from '@chakra-ui/react';
import moment from 'moment';
import { FullProjectDetails, Projects, RequestDetails } from '../../../../../types/Projects';
import { tenantService } from '../../../../../services/TenantService';
import { UploadService } from '../../../../../services/UploadService';
import { useLocation, useParams } from 'react-router-dom';
import CustomButton from 'components/button/CustomButton';
import SpinnerPage from 'components/spinner/spinner';
import { ProjectService } from 'services/ProjectService';
import FileUploadModal from './RequestFileUploadModel';
import { MdCancel, MdCheckCircle, MdFileDownload, MdOutlineError, MdUpload } from 'react-icons/md';


const useDropdown = (initialState: boolean) => {
    const [showDropdown, setShowDropdown] = useState(initialState);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    return { showDropdown, toggleDropdown };
};

const FileProjectTableCell = ({ info, options, callUpdate }: { info: any, options: any[], callUpdate?: any }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const callUpdateFile = callUpdate;
    const [selectedValue, setSelectedValue] = useState('');
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    useEffect(() => {
        options.forEach(option => {
            if (info.row.original.statusName == option.statusName) {
                setSelectedValue(option.statusAutoId);
            }
        });
    }, [info]);

    const handleToggleDropdown = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation(); // Prevent event propagation to parent elements
        setShowDropdown((prevState) => !prevState);

    };

    const handleSelectChange = async (e: any) => {
        e.stopPropagation();
        setIsStatusLoading(true);
        //console.log(info);
        let selectedName = info.row.original.statusName;
        options.forEach(option => {
            if (selectedValue == option.statusAutoId) {
                selectedName = option.statusName;
            }
        });
        info.row.original.statusName = selectedName;
        const reqeust = {
            "projectRequestSentId": info.row.original.projectRequestSentId,
            "statusAutoId": selectedValue
        }

        try {
            const data: any = await ProjectService.updateProjectRequestStatus(reqeust);

        } catch (error) {
            setIsStatusLoading(false);
            setIsStatusLoading(false);
        } finally {
            setIsStatusLoading(false);
            setIsStatusLoading(false);
            callUpdateFile()
        }

        //console.log(selectedValue);
        setIsStatusLoading(false);
        setShowDropdown(false);
        // Handle dropdown selection
        // For example, you can update the value in your data here
    };

    const handleChange = (e: any) => {
        setSelectedValue(e.target.value);
        e.stopPropagation(); // Prevent event propagation to parent elements


    };


    return (
        <div onClick={handleToggleDropdown} style={{ cursor: 'pointer' }}>
            {showDropdown && !(info.getValue() == "Completed" || info.getValue() == "Sent") ? (
                <Box> {isStatusLoading ? <Box width={"20px"}><SpinnerPage width='20px' height='20px' /></Box> :
                    <Box>
                        <select style={{ float: "left", paddingTop: "15px", marginLeft: "5px" }}
                            value={selectedValue}

                            onChange={handleChange}
                            onClick={(e) => e.stopPropagation()} // Prevent dropdown from closing when clicked
                        >
                            {options.map(option => (
                                <option key={option.statusAutoId} value={option.statusAutoId}>{option.statusName}</option>
                            ))}
                        </select>
                        <Box float={"left"} paddingTop={"5px"}><CustomButton label='Update' onClick={async (e) => {
                            await handleSelectChange(e);
                        }} /></Box>
                    </Box>}
                </Box>
            ) : (
                <Box float={"left"}>
                    
                    
                    <Box  float={"left"} className="flex items-center justify-start">
                        <Box>
                            <p className="flex justify-start text-sm font-bold text-navy-700 dark:text-white">
                                {info.getValue()} 
                            </p>
                        </Box>
                        {/* <Button padding={"0px"} title='Approve Document' marginLeft={"5px"} onClick={() => console.log('SSS')}>
                            <FaRegCircleCheck color='#00de00'/>
                        </Button>
                        <Button  padding={"0px"}  title='Approve Document' marginLeft={"5px"} onClick={() => console.log('SSS')}>
                            <RiErrorWarningLine  color='#ffad00'/>
                        </Button>
                        <Button  padding={"0px"}  title='Approve Document' marginLeft={"5px"} onClick={() => console.log('SSS')}>
                            <ImCross color='#F00' />
                        </Button> */}
                        
                    </Box>
                </Box>
            )}
        </div>
    );
};

const downloadFileClick = function (rowInfo: any) {
    window.open(ProjectService.getDownloadFileHeader(rowInfo.downloadFilePath));
    //console.log(rowInfo.downloadFilePath);
}


function FileProjectTable(props: { tableData: any, callUpdateParent: any }) {
    const { tableData, callUpdateParent } = props;
    //console.log("Table Data", tableData);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
    const [projectsList, setProjectsList] = useState<Projects[] | []>([]);
    const [projectRequestDetails, setProjectRequestDetails] = useState<any>();
    const toast = useToast();
    const location = useLocation();
    const [requestName, setRequestName] = useState();
    const [requestDesc, setRequestDesc] = useState();
    const [isError, setIsError] = useState<boolean>(false);
    const [filteTypes, setFileTypes] = useState([]);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const [currentStatusToUpdateRow, setCurrentStatusToUpdateRow] = useState(null);
    const { token } = useParams();


    useEffect(() => {
        // Fetch options from API
        getStatuesByTenant();
        //let token = location.pathname.replace('/upload/', '');
        //console.log("Token", token);
        //getProjectRequestDetails(token);
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState<any>(null); 

    const openModal = (info: any) => { 
        setModalInfo(info);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalInfo(null);
    };

    const callUpdate = async () => {
        callUpdateParent();
    }

    const getProjectRequestDetails = async (token: string) => {
        try {
            const data: any = await UploadService.postProjectEmailDetails(token);
            if (data && data.project && data.projectRequestResponse && data.fileFormt) {
                setProjectRequestDetails(data);
                let fileFormats: any[] = [];
                data.fileFormt.forEach((element: any) => {
                    fileFormats.push(element.fileTypeName);
                });

                if (data.projectRequestResponse.length > 0) {
                    setRequestName(data.projectRequestResponse[0].documentTypeName);
                    setRequestDesc(data.projectRequestResponse[0].documentTypeDescription);
                }

                setFileTypes(fileFormats);
                setIsError(false);
            } else {
                setIsError(true);
            }


        } catch (error) {
            setError('Error fetching user data');
            setIsError(true);
        } finally {
            setLoading(false);
        }
    };
    const getStatuesByTenant = async () => {
        try {
            let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
            var projectRequeststatus = 1;
            const data: any = await tenantService.getStatuesByTenant<Projects>(tenantId, projectRequeststatus);
            setOptions(data.statuses); // Set options to statuses from API response
        } catch (error) {
            setError('Error fetching people data');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectStatusChange = async (e: any, info: any, status: any) => {
        debugger;
        console.log(info);
        let selectedName = status;
        let selectedValue = options[0].statusAutoId;
        setCurrentStatusToUpdateRow(info);
        setIsStatusLoading(true);
        options.forEach(option => {
            if (status == option.statusName) {
                selectedValue = option.statusAutoId;
            }
        });
        
        
        
        const reqeust = {
            "projectRequestSentId": info.row.original.projectRequestSentId,
            "statusAutoId": selectedValue+""
        }

        try {
            const data: any = await ProjectService.updateProjectRequestStatus(reqeust);
            info.row.original.statusName = selectedName;
            callUpdate();
            toast({
                title: 'Update Status Successfully ',
                description: error || 'An error occurred while updating the status of the file',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error updating status ',
                description: error || 'An error occurred while updating the status of the file',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        } finally {
            setIsStatusLoading(false);
            
        }

        // //console.log(selectedValue);
        // setShowDropdown(false);
        // Handle dropdown selection
        // For example, you can update the value in your data here
    };

    let defaultData = tableData;
    const columns = [
        // columnHelper.accessor('label', {
        //     id: 'label',
        //     header: () => (
        //         <p className="text-sm font-bold text-gray-600 dark:text-white">
        //             Label
        //         </p>
        //     ),
        //     cell: (info) => (
        //         <p className="text-sm font-bold text-navy-700">
        //             {info.getValue()}
        //         </p>
        //     ),
        // }),
        columnHelper.accessor('documentTypeName', {
            id: 'documentTypeName',
            header: () => (
                <p className="text-xs font-bold text-gray-600 dark:text-white">
                    Document type
                </p>
            ),
            cell: (info) => (
                <p className="text-xs font-bold text-navy-700">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('createdOn', {
            id: 'createdOn',
            header: () => (
                <p className="text-xs font-bold text-gray-600 dark:text-white">
                    Created On
                </p>
            ),
            cell: (info) => (
                <p className="text-xs font-bold text-navy-700 dark:text-white">
                    {moment(new Date(info.getValue())).subtract(5, 'hours').format('LLL').toString()}
                </p>
            ),
        }),
        columnHelper.accessor('statusName', {
            id: 'statusName',
            header: () => (
                <p className="text-xs font-bold text-gray-600 dark:text-white">Status</p>
            ),
            cell: (info) => 
                <Box padding={"5px"} borderRadius="5px" className={info.getValue() == 'Approved' || info.getValue() == 'Received' ? 'bg-green-50': info.getValue() == 'Sent'|| info.getValue() == 'Acknowledged'? 'bg-yellow-50' : 'bg-red-50'}>
                    <Center><p className="text-xs font-bold text-navy-700" >
                     {info.getValue()}
                    </p></Center>
                </Box>
           // <FileProjectTableCell info={info} options={options} callUpdate={callUpdate} />, // Pass options to the cell component
        }),
        {
            id: 'upload',
            header: () => (
                <p className="text-xs font-bold text-gray-600 dark:text-white">Actions</p>
            ),
            cell: (info: any) => (
                        <Box  float={"left"} className="flex items-center justify-start"> 
                        
                            {
                                isStatusLoading && currentStatusToUpdateRow && info.row.original.projectRequestSentId == currentStatusToUpdateRow?.row.original.projectRequestSentId?    <SpinnerPage size='20px' /> : 
                                <table width={"250px"}>
                                    <tr>
                                    <td width={"45px"}>
                                        {
                                            info.row.original.statusName == 'Approved' ? <></> : 
                                            <Button  padding={"0px"}  title='Upload Document' marginLeft={"5px"} onClick={() => openModal(info.row.original)}>
                                                <MdUpload  size={"25px"}  color='#1d3d86'/>
                                            </Button>
                                        }
                                        </td>
                                        <td width={"45px"}>
                                        {
                                            info.row.original.statusName == 'Sent' ? <></> : 
                                            <Button  padding={"0px"}  title='Download Document' marginLeft={"5px"} onClick={() => downloadFileClick(info.row.original)}>
                                                <MdFileDownload  size={"25px"}  color='#1d3d86'/>
                                            </Button>
                                        }
                                            
                                        </td>
                                        <td width={"45px"} >
                                        {
                                            info.row.original.statusName == 'Sent' || info.row.original.statusName == 'Approved'? <></> :
                                            <Button padding={"0px"} title='Approve Document' marginLeft={"5px"} onClick={(e) => handleSelectStatusChange(e, info, 'Approved')}>
                                                <MdCheckCircle size={"25px"} color='#00de00'/>
                                            </Button>
                                        }
                                        </td>
                                        <td width={"45px"}>
                                        {
                                            info.row.original.statusName == 'Sent' || info.row.original.statusName == 'Approved' || info.row.original.statusName == 'Rejected'? <></> : 
                                            <Button  padding={"0px"}  title='Reject Document' marginLeft={"5px"} onClick={(e) => handleSelectStatusChange(e, info, 'Rejected')}>
                                                <MdCancel size={"25px"}  color='#F00' />
                                            </Button>
                                        }
                                        </td>
                                        <td width={"45px"}>
                                        {
                                            info.row.original.statusName == 'Sent' || info.row.original.statusName == 'Approved' || info.row.original.statusName == 'Acknowledged' ? <></> : 
                                            <Button  padding={"0px"}  title='Acknowledge Document' marginLeft={"5px"} onClick={(e) => handleSelectStatusChange(e, info, 'Acknowledged')}>
                                                
                                                <MdOutlineError  size={"25px"}  color='#ffad00'/>
                                            </Button>
                                        } 
                                        </td>
                                        
                                    </tr>
                                </table>
                            }
                        </Box>
                // <CustomButton
                //     label={"Upload"}
                //     onClick={() => openModal(info.row.original)}
                //     type="submit"
                // />
            ),
        },
        // {
        //     id: 'download',
        //     header: () => (
        //         <p className="text-sm font-bold text-gray-600 dark:text-white">Download</p>
        //     ),
        //     cell: (info: any) => {
        //         return (
        //             info.row._valuesCache.statusName !== 'Sent' ? (
        //                 <Box float={"left"}><CustomButton label="Download" onClick={() => downloadFileClick(info.row.original)} /></Box>

        //             ) : null
        //         );
        //     },
        // }
    ];
    const handleUploadError = (error: any) => {
        console.error('Error uploading file:', error);
        toast({
            title: 'Error uploading file',
            description: error || 'An error occurred while uploading the file',
            status: 'error',
            duration: 5000,
            position: 'top',
            isClosable: true,
        });
    };
    const [data, setData] = useState(() => [...defaultData]);

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: true,
    });

    return (
        <Box  className="overflow-x-scroll xl:overflow-x-hidden" paddingY={"5px"}  paddingX={"10px"}  border={"1px"} borderColor={"#eee"}>
            <div>
                <table className="w-full">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id} className="!border-px !border-gray-400">
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            onClick={header.column.getToggleSortingHandler()}
                                            className="cursor-pointer border-b border-gray-200 pb-2 pr-4 pt-4 text-start dark:border-white/30"
                                        >
                                            <div className="items-center justify-between text-xs text-gray-200">
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                                {{
                                                    asc: '',
                                                    desc: '',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table
                            .getRowModel()
                            .rows.slice(0, 10)
                            .map((row) => {
                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                <td
                                                    key={cell.id}
                                                    className="min-w-[150px] border-white/0 py-3  pr-4"
                                                >
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext(),
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div>
                {isModalOpen && (
                    <FileUploadModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        callUpdate={callUpdate}
                        tableData={modalInfo}
                    />
                )}
            </div>
        </Box>
    );
}

export default FileProjectTable;
const columnHelper = createColumnHelper<RequestDetails>();
