import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL, Data, ApiResponse, handleApiError } from './AxiosAuthService';

export const TemplateService={
    getReminderList: async <T>(): Promise<T> => {
        try {
          const response: any = await axios.get( `${BASE_URL}` + "/Reminder", getAuthHeader() );
          //const response: any = await axios.get("http://localhost:7166/business", getAuthHeader() );
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
      },  getReminderListByTenantId: async <T>(tenantId: any): Promise<T> => {
        try {
          const response: any = await axios.get( `${BASE_URL}` + "/Reminder/tenant/" + tenantId, getAuthHeader() );
          //const response: any = await axios.get("http://localhost:7166/business", getAuthHeader() );
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
      }, getDocumentList: async <T>(): Promise<T> => {
        try {
          const response: any = await axios.get( `${BASE_URL}` + "/documenttype", getAuthHeader() );
          //const response: any = await axios.get("http://localhost:7166/business", getAuthHeader() );
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
      }, getDocumentListByTenantId: async <T>(tenantId: any): Promise<T> => {
        try {
          const response: any = await axios.get( `${BASE_URL}` + "/documenttype/tenantId/" + tenantId, getAuthHeader() );
          //const response: any = await axios.get("http://localhost:7166/business", getAuthHeader() );
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
      },  postRequestTemplates: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${BASE_URL}/requesttemplate`,request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getProjectDocumentTemplates: async <T>(): Promise<T> => {
      try {
        const response: any = await axios.get( `${BASE_URL}` + "/requesttemplate", getAuthHeader() );
        //const response: any = await axios.get("http://localhost:7166/business", getAuthHeader() );
        return response.data;
      } catch (error:any) {
        handleApiError(error);
        throw error;
      }
    },
    getRequestDetailsById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${BASE_URL}/requesttemplate/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateRequestTemplates: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${BASE_URL}/requesttemplate`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
};