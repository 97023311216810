import {
    SimpleGrid,
    Box,
    Button,
    Flex,
    useColorModeValue,
    Text,
    Textarea,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import CustomButton from 'components/button/CustomButton';
import { ConfirmationDialog } from 'components/Confirmation/ConfirmationDialog';
import React, { useEffect, useState } from 'react'; 
import { MdDone, MdEdit } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { NotesService } from 'services/NotesService';
import { NotesDetails } from 'types/Projects';

export default function ProjectNotes(props: { projectId: any; notes: any }) {
    const [loading, setLoading] = useState<boolean>(false);
    const [notes, setNotes] = useState([]);
    const [projectId, setProjectId] = useState(null);
    const [isNotesDeleteOpen, setIsNotesDeleteOpen] = useState(false);
    const [deleteNotesId, setDeleteNotesId] = useState(null);
    const [isNotesLoading, setIsNotesLoading] = useState(false);
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const [userLoggedIn] = useState(JSON.parse(localStorage.getItem('userLoginStatus')));
    const [userId, setUserId] = useState(null);
    const [isTenantAdmin, setIsTenantAdmin] = useState(false);
    const [addNewNotes, setAddNewNotes] = useState('');
    const [editedNotes, setEditedNotes] = useState([]); 
    const toast = useToast();

    useEffect(() => {
        setUserId(userLoggedIn?.userDetails?.userInfo?.userId);
        setNotes(props.notes);
        setEditedNotes(props.notes);
        setProjectId(props.projectId);
        let roleType = userLoggedIn?.userDetails?.userInfo?.roles;
        if (roleType === 'TenantAdmin') {
            setIsTenantAdmin(true);
        }
    }, [props.notes, props.projectId]);

    const onNotesChange = (e: any) => {
        let notesValue = e.target.value;
        setAddNewNotes(notesValue);
    };

    const deleteClickAction = async () => {
        try {
            setLoading(true);
            await NotesService.deleteStaffNotes<NotesDetails>(deleteNotesId);
            toast({
                title: 'Notes Deleted successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            // Filter out the deleted note from both notes and editedNotes
            const updatedNotes = notes.filter(note => note.projectNotesId !== deleteNotesId);
            const updatedEditedNotes = editedNotes.filter(note => note.projectNotesId !== deleteNotesId);
            setNotes(updatedNotes);
            setEditedNotes(updatedEditedNotes);
        } catch (error) {
            toast({
                title: 'Error occurred while deleting the notes',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        } finally {
            setLoading(false);
            setIsNotesDeleteOpen(false);
        }
    };

    const onCloseClick = () => {
        setIsNotesDeleteOpen(false);
    };

    const onDeleteClick = async (notesId: any) => {
        setDeleteNotesId(notesId);
        setIsNotesDeleteOpen(true);
    };

    const AddNewNotesClick = async () => {
        const requestBody = {
            projectNotesId: 0,
            projectId: projectId,
            tenantId: userLoggedIn?.userDetails?.userInfo?.tenantId,
            notes: addNewNotes,
            createdByUserId: userLoggedIn?.userDetails?.userInfo?.userId,
            createdOn: new Date().toISOString(),
            createdByName: userLoggedIn?.userDetails?.userInfo?.firstName,
        };
        try {
            setLoading(true);
            const data: any = await NotesService.createStaffNotes<NotesDetails>(requestBody);
            toast({
                title: 'Notes Added successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            requestBody.projectNotesId = data?.projectNotesId;
            setNotes(prevNotes => [...prevNotes, requestBody]);
            setEditedNotes(prevNotes => [...prevNotes, requestBody]);
            setAddNewNotes(''); // Reset addNewNotes state after adding the note
        } catch (error) {
            toast({
                title: 'Error occurred while adding notes',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const handleEditNote = (noteId: any) => {
        const newNotes = editedNotes.map(note => {
            if (note.projectNotesId === noteId) {
                return { ...note, editing: true, editedValue: note.notes }; // Store original value
            }
            return note;
        });
        setEditedNotes(newNotes);
    };

    const handleSaveNote = async (noteId: any) => {
        try {
            const newNotes = await Promise.all(editedNotes.map(async (note) => {
                if (note.projectNotesId === noteId) {
                    const requestBody = {
                        projectNotesId: note.projectNotesId,
                        projectId: projectId,
                        tenantId: userLoggedIn?.userDetails?.userInfo?.tenantId,
                        notes: note.editedValue,
                        createdByUserId: userLoggedIn?.userDetails?.userInfo?.userId,
                        createdOn: new Date().toISOString(),
                        createdByName: userLoggedIn?.userDetails?.userInfo?.firstName,
                    };
                    await NotesService.updateStaffNotes(requestBody);
                    return { ...note, notes: note.editedValue, editing: false };
                }
                return note;
            }));
            setEditedNotes(newNotes);
        } catch (error) {
            console.error('Error saving note:', error);
        }
    };


    const handleCancelEdit = (noteId: any) => {
        const newNotes = editedNotes.map(note => {
            if (note.projectNotesId === noteId) {
                return { ...note, editing: false, editedValue: note.notes }; // Reset to original value
            }
            return note;
        });
        setEditedNotes(newNotes);
    };

    const handleInputChange = (noteId: any, value: any) => {
        const newNotes = editedNotes.map(note => {
            if (note.projectNotesId === noteId) {
                return { ...note, editedValue: value }; // Update edited value
            }
            return note;
        });
        setEditedNotes(newNotes);
    };

    return (
        <Box>
            {loading ? (
                <Spinner />
            ) : (
                <Box>
                    {notes?.length ? (
                        editedNotes.map((note: any) => (
                            <Box
                                key={note?.projectNotesId}
                                border="1px solid #e6e6e6"
                                borderRadius="8px"
                                padding="10px"
                                marginBottom="10px"
                                bg={paleGray}
                            >
                                <SimpleGrid
                                    py={2}
                                    spacingX={4}
                                    minChildWidth="225px"
                                    spacingY={'20px'}
                                >
                                    <Box
                                        fontWeight="bold"
                                        px="10px"
                                        borderTopLeftRadius="8px"
                                        borderTopRightRadius="8px"
                                        color="navy.700"
                                        _dark={{ color: 'white' }}
                                    >
                                        {note.createdByName}
                                    </Box>
                                    <Box>
                                        {isTenantAdmin || (note?.createdByUserId == userId && note?.projectNotesId)  ? (
                                            <Box className="flex items-center justify-end px-6">
                                                {note.editing ? ( // Render edit components when editing
                                                    <Box>
                                                        <Box className="flex items-center justify-end px-6">
                                                            <Button mr="5px" onClick={() => handleSaveNote(note.projectNotesId)}>
                                                                <MdDone />
                                                            </Button>
                                                            <Button onClick={() => handleCancelEdit(note.projectNotesId)}>Cancel</Button>
                                                        </Box>
                                                    </Box>
                                                ) : ( // Render default components when not editing
                                                    <Box className="flex items-center justify-end px-6">
                                                        <Button mr={'5px'} onClick={() => handleEditNote(note.projectNotesId)}>
                                                            <MdEdit />
                                                        </Button>
                                                        <Button onClick={() => onDeleteClick(note?.projectNotesId)}>
                                                            <RiDeleteBin6Line />
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                        ) : (
                                            <></>
                                        )}
                                    </Box>
                                </SimpleGrid>
                                <Box marginTop="5px" px="10px">
                                    {note.editing ? (
                                        <Textarea
                                            bg={"#FFF"}
                                            value={note.editedValue}
                                            onChange={(e) => handleInputChange(note.projectNotesId, e.target.value)}
                                            mr="5px"
                                        />
                                    ) : (
                                        <Text style={{ whiteSpace: 'pre-wrap' }}>{note.notes}</Text> // Render text when not editing
                                    )}
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Box>No Notes Added</Box>
                    )}
                    {/* Add new notes section */}
                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                        <Box>
                            <Textarea
                                placeholder="Add New Staff Notes"
                                id="projectStaffNotes"
                                name="projectStaffNotes"
                                size="md"
                                height="100px"
                                onChange={onNotesChange}
                                value={addNewNotes}
                            />
                            <span style={{ color: 'red' }}>
                                {/* Error message */}
                            </span>
                        </Box>
                    </SimpleGrid>
                    <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                        <CustomButton
                            label={'Add Notes'}
                            onClick={AddNewNotesClick}
                            disabled={addNewNotes && addNewNotes.trim() != '' ? false : true}
                        />
                    </Flex>
                    {/* Confirmation dialog for deleting notes */}
                    <ConfirmationDialog
                        isOpen={isNotesDeleteOpen}
                        header={'Delete Notes'}
                        content={"Are you sure? You can't undo this action afterwards."}
                        onConfirmClick={deleteClickAction}
                        onCloseClick={onCloseClick}
                    ></ConfirmationDialog>
                </Box>
            )}
        </Box>
    );
}
