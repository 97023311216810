// Chakra imports
import {
    background,
    Button,
    Checkbox,
    Divider,
    Flex,
    Grid,
    GridItem,
    Heading,
    Select,
    SimpleGrid,
    Spacer,
    Stack,
    Textarea,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { Box, Text, Input } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CustomButton from '../../../../components/button/CustomButton';
import { BusinessService } from '../../../../services/BusinessService';
import { tenantService } from '../../../../services/TenantService';
import { BusinessPersona } from '../../../../types/People';
import { TenantUsers } from '../../../../types/Tenant';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { PeopleService } from '../../../../services/PeopleService';
import { ProjectService } from '../../../../services/ProjectService';
import { FullProjectDetails, IndustryDetails, LoanTypeDetails, NotesDetails, Projects, StatuesDetails } from '../../../../types/Projects';
import { PersonaService } from '../../../../services/PersonaService';
import { Personas, PersonasByTenantId } from '../../../../types/Persona';
import { NotesService } from '../../../../services/NotesService';

// Custom components

export default function AddProjectsPage() {
    // Chakra Color Mode
    const bg = useColorModeValue('background.100', 'background.900');
    const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
    const [primaryBorrowerList, setPrimaryBorrowerList] = useState([]);
    const [primaryBusinessList, setPrimaryBusinessList] = useState([]);
    const [primaryAssignedStaffList, setPrimaryAssignedStaffList] = useState([]);
    const [industriesList, setindustriesList] = useState([]);
    const [loanTypesList, setloanTypesList] = useState([]);
    const [statuesList, setstatuesList] = useState([]);
    const [personaList, setPersonaList] = useState([]);


    const toast = useToast();
    const navigate = useNavigate();
    let { projectId } = useParams();
    const [projectsList, setProjectsList] = useState<Projects[] | []>([]);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const styles = {
        checkBoxStyle: {
            padding: '8px',
            background: '#EEE',
            border: '1px solid rgba(0, 0, 0, 0.05)',
        },
    };

    interface ProjectBusinessesRelation {
        businessId: number;
        primaryForLoan: number;
    }

    interface ProjectPeopleRelation {
        peopleId: number;
        primaryBorrower: number;
        personaAutoId: number;
    }

    interface FormInit {
        tenantId: number;
        projectName: string;
        projectDescription: string;
        industryId: number;
        amount: string;
        purpose: string;
        assignedToUserId: string;
        loanTypeAutoId: number;
        statusAutoId: number;
        projectFilesPath: string;
        projectStartDate: string | null;
        desiredClosedDate: string | null;
        fileName: string;
        loanAmount: string;
        assignedStaff: string;
        fileDescription: string;
        staffNote: string;
        notes: string;
        primaryBorrower: number;
        persona: number;
        primaryBusiness: number;
        initialPhase: string;
        industrySector: string;
        loanTypes: string;
        projectBusinessesRelations: ProjectBusinessesRelation[];
        projectPeopleRelations: ProjectPeopleRelation[];
    }

    const formInit: FormInit = {
        tenantId: 0,
        projectName: "",
        projectDescription: "",
        industryId: 0,
        amount: "",
        purpose: "",
        assignedToUserId: "",
        loanTypeAutoId: 0,
        statusAutoId: 1,
        projectFilesPath: "",
        projectStartDate: null,
        desiredClosedDate: null,
        fileName: "",
        loanAmount: "",
        assignedStaff: "",
        fileDescription: "",
        staffNote: "",
        notes: "",
        primaryBorrower: 0,
        persona: 0,
        primaryBusiness: 0,
        initialPhase: "",
        industrySector: "",
        loanTypes: "",
        projectBusinessesRelations: [
            {
                businessId: 0,
                primaryForLoan: 0,
            }
        ],
        projectPeopleRelations: [
            {
                peopleId: 0,
                primaryBorrower: 0,
                personaAutoId: 0
            }
        ]
    };



    const validationSchema = Yup.object().shape({
        //tenantId: Yup.number().integer('Age must be an integer').positive('Age must be a positive integer').required('Age is required'),,
        // clientName: Yup.string().required('Username is required'),
        fileName: Yup.string().required('Project Name is required'),
        loanAmount: Yup.string().required('Loan Amount is required'),
        //assignedStaff: Yup.string().required('Select Assigned Staff'),
        fileDescription: Yup.string().required('Project Description is required'),
        //staffNote: Yup.string().required('Staff Note is required'),
        //staffNote: Yup.string().notOneOf(['0'], 'Staff Note is required').required('Staff Note is required'),
        //primaryBorrower: Yup.string().notOneOf(['0'], 'Select Primary Borrower').required('Select Primary Borrower'),
        //// persona: Yup.string().notOneOf(['0'], 'Select persona').required('Select persona'),
        //primaryBusiness: Yup.string().notOneOf(['0'], 'Select Primary Business').required('Select Primary Business'),
        //industryId: Yup.string().notOneOf(['0'], 'Select Industry Sector').required('Select Industry Sector'),
        //loanTypeAutoId: Yup.string().notOneOf(['0'], 'Select Loan Type').required('Select Loan Type'),
        //projectStartDate: Yup.date().required('Start Date is required'),
        //desiredClosedDate: Yup.date().required('Closing Date is required'),
        //purpose: Yup.string().required('Purpose is required'),
        //projectFilesPath: Yup.string(),
        //statusAutoId: Yup.string().notOneOf(['0'], 'Select InitialPhase').required('Select Initial Phase'),
    });
    const [projectDetails, setprojectDetails] = useState(formInit);

    const formik = useFormik({
        initialValues: projectDetails,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {


            //values.projectStartDate = new Date().toISOString().substr(0, 10);
            //values.desiredClosedDate = new Date().toISOString().substr(0, 10);
            //values.primaryBorrower = values.primaryBorrower;
            //values.persona = values.persona;
            values.tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
            values.projectName = values.fileName;
            values.amount = values.loanAmount+"";
            values.assignedToUserId = values.assignedStaff;
            values.notes = values.staffNote;
            values.projectDescription = values.fileDescription;
            values.persona = personaList.find((persona) => persona.personaName == "Borrower").personaAutoId;
            if (projectId) {
                debugger;
                try {
                    const updateParams = {
                        projectId: projectId,
                        tenantId: values.tenantId,
                        projectName: values.projectName,
                        projectDescription: values.projectDescription,
                        industryId: values.industryId,
                        amount: values.amount,
                        purpose: values.purpose,
                        createdByUserId:  "bc18ffb9-cd30-11ee-b44c-cc4740007297",
                        createdDateTime: "2024-03-09T09:48:51",
                        assignedToUserId: values.assignedToUserId,
                        modifiedByUserId: "",
                        modifiedDateTime: "2024-03-09T09:48:51",
                        loanTypeAutoId: values.loanTypeAutoId,
                        statusAutoId: values.statusAutoId,
                        projectFilesPath: values.projectFilesPath,
                        projectStartDate: values.projectStartDate,
                        desiredClosedDate: values.desiredClosedDate,
                        notes: values.notes,
                        primaryBorrower: values.primaryBorrower,
                        primaryBusiness: values.primaryBusiness
                    };
                    debugger;
                    const data: any = await ProjectService.updateProject(updateParams);
                    toast({
                        title: values.fileName + ' successfully updated.',
                        //description: values.fileName + ' Project updated successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    clickCancel();
                } catch (error) {
                    toast({
                        title: 'Error occured while updating ' + values.fileName,
                        //description: values.fileName + ' Project update error.',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            } else {
                try {
                    //values.tenantId = tenantId;
                    // const data: any = await ProjectService.createProject(values);
                    const projectBusinessesRelations = {
                        businessId: values.primaryBusiness,
                        primaryForLoan: 1,
                    };
                    values.projectBusinessesRelations =  values.primaryBusiness==0?[]:[projectBusinessesRelations];

                    const projectPeopleRelation = {
                        peopleId: values.primaryBorrower,
                        primaryBorrower: 1,
                        personaAutoId: values.persona
                    };
                    values.projectPeopleRelations = values.primaryBorrower == 0 ? [] : [projectPeopleRelation];
                    debugger;
                    const data: any = await ProjectService.postProjectprojectWithDetails(values);
                    toast({
                        title: values.fileName + ' successfully created.',
                        //description: 'project added successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    navigate('/admin/projects');
                } catch (error) {
                    toast({
                        title: 'Error occured while updating ' + values.fileName,
                        //description: 'project create error.',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            }
        },
    });

    useEffect(() => {
        fetchData();
        if (projectId) {
            getProjectWithDetailsID();
        }
        return () => {

        };
    }, []);


    const fetchData = async () => {
        try {
            let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
            const borrowerData: any = await BusinessService.getPeopleDetailsByTenantId<TenantUsers>(tenantId);
            await setPrimaryBorrowerList(borrowerData.peoples);

            const businessData: any = await tenantService.getBussinessByTenantId<BusinessPersona>(tenantId);
            await setPrimaryBusinessList(businessData.business);

            const staffData: any = await BusinessService.getUserDetailsByTenantId<TenantUsers>(tenantId);
            await setPrimaryAssignedStaffList(staffData.users);

            const industryData: any = await ProjectService.getIndustries<IndustryDetails>();
            const industryTenant = industryData.filter((industry: any) => industry.tenantId==tenantId);
            await setindustriesList(industryTenant);

            const LoanTypeData: any = await ProjectService.getLoanTypes<LoanTypeDetails>();
            await setloanTypesList(LoanTypeData);

            const statusData: any = await tenantService.getStatuesByTenant<Projects>(tenantId, 0);
            await setstatuesList(statusData.statuses);
            await getPersonaList();

        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };
    const getProjectWithDetailsID = async () => {
        if (!projectId) {
            navigate('/admin/projects');
            return;
        }
        try {
            const data: any =
                await ProjectService.getProjectWithDetailsID<FullProjectDetails>(
                    parseInt(projectId),
                );
            debugger;

            setprojectDetails(data);
            formik.values = data;
            formik.values.fileName = data.project.projectName;
            formik.values.loanAmount = data.project.amount;
            formik.values.assignedStaff = data.project.assignedToUserId;
            formik.values.fileDescription = data.project.projectDescription;
            formik.values.purpose = data.project.purpose;
            formik.values.projectFilesPath = data.project.projectFilesPath;
            formik.values.industrySector = data.industry?.industrySector;
            formik.values.loanTypes = data.loanType?.loanType;
            formik.values.initialPhase = data.status?.statusId;
            formik.values.industrySector = data.industry?.industrySector;
            formik.values.industryId = data.industry?.industryId;
            formik.values.loanTypes = data.loanType?.loanType;
            formik.values.loanTypeAutoId = data.project?.loanTypeAutoId;
            formik.values.initialPhase = data.status?.statusId;
            formik.values.statusAutoId = data.status?.statusAutoId;
            formik.values.projectStartDate = data.project?.projectStartDate;
            formik.values.desiredClosedDate = data.project?.desiredClosedDate;
            formik.values.notes = data.project.notes;
            formik.values.staffNote = data?.notes?.[0]?.notes || "";
            formik.values.primaryBorrower = data?.projectPeopleRelation?.[0]?.peopleId || null;
            formik.values.persona = data?.projectPeopleRelation?.[0]?.personaId || ""; 
            formik.values.primaryBusiness = data?.projectBusinessesRelation?.[0]?.businessId || null;


        } catch (error) {
            navigate('/admin/projects');
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const getStartFormattedDate = (projectStartDate: string) => {
        return moment(projectStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    };
    const getCloseFormattedDate = (desiredClosedDate: string) => {
        return moment(desiredClosedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

    };
    const clickCancel = () => {
        navigate('/admin/projects');
    };
    

    const getPersonaList = async () => {
        try {
            const data: any = await PersonaService.getPersonaByTenantList<PersonasByTenantId>(userLoggedIn?.userDetails?.userInfo?.tenantId);
            setPersonaList(data?.personas);
        } catch (error) {
            navigate('/admin/projects');
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const AddNewPrimaryBorrowerClicked = async () => {
        //try {

        //} catch (error) {
        //    navigate('/admin/projects');
        //    setError('Error fetching user data');
        //} finally {
        //    setLoading(false);
        //}
    };

    const AddNewNotesClick = async () => {
        const requestBody = {
            projectNotesId: 0,
            projectId: projectId,
            tenantId: userLoggedIn?.userDetails?.userInfo?.tenantId,
            notes: formik.values.staffNote, 
            createdByUserId: userLoggedIn?.userDetails?.userInfo?.userId,
            createdOn: new Date().toISOString(), 
            createdByName: userLoggedIn?.userDetails?.userInfo?.firstName,
        };
        if (projectId) {
            try {
                const data: any = await NotesService.createStaffNotes<NotesDetails>(requestBody);
                toast({
                    title: 'Notes updated successfully.',
                    //description: 'Notes updated successfully.',
                    status: 'success',
                    duration: 5000,
                    position: 'top',
                    isClosable: true,
                });
            } catch (error) {
                toast({
                    title: 'Error occured while updating the notes.',
                    //description: 'Notes update Error.',
                    status: 'error',
                    duration: 5000,
                    position: 'top',
                    isClosable: true,
                });
            }
        }
    };


    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    return (
        <Box className="addTopMarginCard" mt={'40px'}>
            <Card bg={bg} extra={'w-full h-fullsm:overflow-auto px-6 cardH pt-6'}>
                
                <Box>
                    
                    <form onSubmit={formik.handleSubmit}>
                    <Flex mt={4}>
                        
                        <Spacer />
                        <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                <CustomButton
                                    label={"Cancel"}
                                    onClick={clickCancel}
                                />
                                <CustomButton
                                label={"Save"}
                                onClick={null}
                                type="submit"
                            />
                        </Flex>
                    </Flex>
                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>

                            {/*<Stack direction={{ base: 'column', md: 'row' }} spacing={4} align="start">*/}
                            <Box flex={1}>
                                <Text mb="8px">*Project Name: </Text>
                                <Input
                                    placeholder="Project Name"
                                    id="fileName"
                                    name="fileName"
                                    type="text"
                                    size="md"
                                    width="50%" // Adjusted width
                                    onChange={formik.handleChange}
                                    value={formik.values.fileName}
                                />
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.fileName ? formik.errors.fileName : ''}
                                </span>
                            </Box>
                        </SimpleGrid>
                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>

                            <Box>
                                <Text mb="8px">*Loan Amount: </Text>
                                <Input
                                    placeholder="$"
                                    id="loanAmount"
                                    name="loanAmount"
                                    type="number"
                                    size="md"
                                    width="50%" // Adjusted width
                                    onChange={formik.handleChange}
                                    value={formik.values.loanAmount}
                                />
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.loanAmount ? formik.errors.loanAmount : ''}
                                </span>
                            </Box>
                        </SimpleGrid>
                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Assigned Staff:</Text>
                                <Select
                                    placeholder="Select an option"
                                    name="assignedStaff"
                                    value={formik.values.assignedStaff}
                                    onChange={formik.handleChange}
                                >
                                    {primaryAssignedStaffList.map((staff) => (
                                        <option key={staff.userId} value={staff.userId}>
                                            {staff.firstName}
                                        </option>
                                    ))}
                                </Select>
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.assignedStaff
                                        ? formik.errors.assignedStaff
                                        : ''}
                                </span>
                            </Box>
                            <Flex justifyContent="flex-start" paddingTop="30">
                                {/* <CustomButton
                                    label={"Add New"}
                                    onClick={null}
                                /> */}
                            </Flex>
                        </SimpleGrid>

                        {/*</Stack>*/}

                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">*Project Description: </Text>
                                <Textarea
                                    placeholder="Purpose: Project Description"
                                    id="fileDescription"
                                    name="fileDescription"
                                    size="md"
                                    height="100px" // Adjusted height
                                    onChange={formik.handleChange}
                                    value={formik.values.fileDescription}
                                />
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.fileDescription ? formik.errors.fileDescription : ''}
                                </span>
                            </Box>
                        </SimpleGrid>

                        {!projectId && (
                            <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                                <Box>
                                    <Text mb="8px">Staff Note (only visible by staff): </Text>
                                    <Textarea
                                        placeholder="Staff Notes"
                                        id="staffNote"
                                        name="staffNote"
                                        size="md"
                                        height={{ base: '150px', md: '100px' }} // Adjusted height for responsiveness
                                        onChange={formik.handleChange}
                                        value={formik.values.staffNote}
                                    />
                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                        {formik.touched.staffNote ? formik.errors.staffNote : ''}
                                    </span>
                                    <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                        {/* <CustomButton
                                label={"Add Notes"}
                                onClick={AddNewNotesClick}
                            /> */}
                                    </Flex>
                                </Box>
                            </SimpleGrid>
                        )}
                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box flex={1}>
                                <Text mb="8px">Purpose: </Text>
                                <Input
                                    placeholder="Purpose"
                                    id="purpose"
                                    name="purpose"
                                    type="text"
                                    size="md"
                                    width="50%"
                                    onChange={formik.handleChange}
                                    value={formik.values.purpose}
                                />
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.purpose ? formik.errors.purpose : ''}
                                </span>
                            </Box>
                        </SimpleGrid>
                        {/*<SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>*/}
                        {/*    <Box flex={1}>*/}
                        {/*        <Text mb="8px">Project Files Path: </Text>*/}
                        {/*        <Input*/}
                        {/*            placeholder="Project Files Path"*/}
                        {/*            id="projectFilesPath"*/}
                        {/*            name="projectFilesPath"*/}
                        {/*            type="text"*/}
                        {/*            size="md"*/}
                        {/*            width="50%"*/}
                        {/*            onChange={formik.handleChange}*/}
                        {/*            value={formik.values.projectFilesPath}*/}
                        {/*        />*/}
                        {/*        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>*/}
                        {/*            {formik.touched.projectFilesPath ? formik.errors.projectFilesPath : ''}*/}
                        {/*        </span>*/}
                        {/*    </Box>*/}
                        {/*</SimpleGrid>*/}
                        <Divider mt={4} />

                        {!projectId ? ( <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Primary Borrower:</Text>
                                <Select
                                    placeholder="Select an option"
                                    name="primaryBorrower"
                                    width = "50%"
                                    value={formik.values.primaryBorrower}
                                    onChange={formik.handleChange}
                                >
                                    {primaryBorrowerList.map((borrower, index) => (
                                        <option key={index} value={borrower.clientId}>
                                            {borrower.firstName}, {borrower.lastName}
                                        </option>
                                    ))}
                                </Select>
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.primaryBorrower
                                        ? formik.errors.primaryBorrower
                                        : ''}
                                </span>
                            </Box>
                        </SimpleGrid>) : null}
                        {/* <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Persona:</Text>
                                <Select
                                    placeholder="Select an option"
                                    name="persona"
                                    value={formik.values.persona}
                                    onChange={formik.handleChange}
                                >
                                    {personaList.map((persona, index) => (
                                        <option key={index} value={persona.personaId} >
                                            {persona.personaName}
                                        </option>
                                    ))}
                                </Select>
                                <span style={{ color: 'red' }}>
                                    {formik.touched.persona
                                        ? formik.errors.persona
                                        : ''}
                                </span>
                            </Box>
                            <Flex justifyContent="flex-start" paddingTop="30">
                                <CustomButton
                                    label={"Add New"}
                                    onClick={AddNewPrimaryBorrowerClicked}
                                />
                            </Flex>
                        </SimpleGrid> */}

                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Primary Business:</Text>
                                <Select
                                    placeholder="Select an option"
                                    name="primaryBusiness"
                                    value={formik.values.primaryBusiness}
                                    onChange={formik.handleChange}
                                >
                                    {primaryBusinessList.map((business) => (
                                        <option key={business.businessId} value={business.businessId}>
                                            {business.businessName}
                                        </option>
                                    ))}
                                </Select>
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.primaryBusiness
                                        ? formik.errors.primaryBusiness
                                        : ''}
                                </span>
                            </Box>
                            <Flex justifyContent="flex-start" paddingTop="30">
                                
                            </Flex>
                        </SimpleGrid>
                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Industy Sector:</Text>
                                <Select
                                    placeholder="Select an option"
                                    name="industryId"
                                    width="50%"
                                    value={formik.values.industryId}
                                    onChange={formik.handleChange}
                                >
                                    {industriesList.map((industry, index) => (
                                        <option key={index} value={industry.industrySectorAutoId}>
                                            {industry.industrySector}
                                        </option>
                                    ))}
                                </Select>
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.industryId
                                        ? formik.errors.industryId
                                        : ''}
                                </span>
                            </Box>
                        </SimpleGrid>

                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Loan Type:</Text>
                                <Select
                                    placeholder="Select an option"
                                    name="loanTypeAutoId"
                                    width="50%"
                                    value={formik.values.loanTypeAutoId}
                                    onChange={formik.handleChange}
                                >
                                    {loanTypesList.map((loanType, index) => (
                                        <option key={index} value={loanType.loanTypeAutoId}>
                                            {loanType.loanType}
                                        </option>
                                    ))}
                                </Select>
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.loanTypeAutoId
                                        ? formik.errors.loanTypeAutoId
                                        : ''}
                                </span>
                            </Box>
                        </SimpleGrid>

                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Start Date: </Text>
                                <Input
                                    id="projectStartDate"
                                    name="projectStartDate"
                                    type="date"
                                    size="md"
                                    width="50%"
                                    onChange={formik.handleChange}
                                    value={getStartFormattedDate(formik.values.projectStartDate)}
                                />
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.projectStartDate ? formik.errors.projectStartDate : ''}
                                </span>
                            </Box>
                        </SimpleGrid>

                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Desired Closing Date: </Text>
                                <Input
                                    id="desiredClosedDate"
                                    name="desiredClosedDate"
                                    type="date"
                                    size="md"
                                    width="50%"
                                    onChange={formik.handleChange}
                                    value={getCloseFormattedDate(formik.values.desiredClosedDate)}
                                />
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.desiredClosedDate ? formik.errors.desiredClosedDate : ''}
                                </span>
                            </Box>
                        </SimpleGrid>

                        <SimpleGrid p={2} spacingX={2} minChildWidth="225px" columns={2}>
                            <Box>
                                <Text mb="8px">Status:</Text>
                                <Select
                                    placeholder="Select an option"
                                    name="statusAutoId"
                                    width="50%"
                                    value={formik.values.statusAutoId}
                                    onChange={formik.handleChange}
                                >
                                    {statuesList.map((status, index) => (
                                        <option key={index} value={status.statusAutoId}>
                                            {status.statusDescription}
                                        </option>
                                    ))}
                                </Select>
                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                    {formik.touched.statusAutoId
                                        ? formik.errors.statusAutoId
                                        : ''}
                                </span>
                            </Box>
                        </SimpleGrid>

                        {/* Additional content */}
                        {/* Ensure proper responsive handling for additional content */}

                        {/* Save Buttons */}
                        <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                            <CustomButton
                                label={"Cancel"}
                                onClick={clickCancel}
                            />
                            <CustomButton
                                label={"Save"}
                                onClick={null}
                                type="submit"
                            />
                        </Flex>
                    </form>

                </Box>
            </Card>
        </Box>
    );
}
