import './assets/css/App.css';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from "react-dom/client";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import App from './App';
import { WebStorageStateStore } from 'oidc-client-ts';


const container = document.getElementById("root")!;
const root = createRoot(container);

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  scope: process.env.REACT_APP_OIDC_SCOPE,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, "/");
    window.location.href = "/";
  }

};




root.render(
  <BrowserRouter>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </BrowserRouter>,
);
