import axios, { AxiosResponse, AxiosError } from 'axios';
import { getAuthHeader, BASE_URL, Data, ApiResponse } from './AxiosAuthService';

export const BusinessService={
    getBusinessList: async <T>(): Promise<T> => {
        try {
          const response: any = await axios.get( `${BASE_URL}` + "/business", getAuthHeader() );
          //const response: any = await axios.get("http://localhost:7166/business", getAuthHeader() );
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
      },createbusiness: async <T>(request: any): Promise<T> => {
        try {
          const response: any = await axios.post(`${BASE_URL}/business`,request, getAuthHeader());
          return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
      },getbusinessById: async <T>(id:number): Promise<T>=>{
        try{
            const response: any = await axios.get(`${BASE_URL}/business/${id}`, getAuthHeader());
            return response.data;
        } catch (error:any) {
          handleApiError(error);
          throw error;
        }
      },updateBusinessById: async <T>(values: any): Promise<T> => {
        try {
          const response: any = await axios.put(`${BASE_URL}/business`, values, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      },getClinetDetailsById: async <T>(id: number): Promise<T> => {
        try {
          const response: any = await axios.get(`${BASE_URL}/peopleBusinessRelation/businessRelation/${id}`, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      }
      ,getUserDetailsByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
          const response: any = await axios.get(`${BASE_URL}/tenant/usersByTenant/${tenantId}`, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      },getPeopleDetailsByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
          const response: any = await axios.get(`${BASE_URL}/tenant/peoplesByTenant/${tenantId}`, getAuthHeader());
          return response.data;
        } catch (error: any) {
          handleApiError(error);
          throw error;
        }
      }
    };


const handleApiError = (error: AxiosError) => {
    if (error.response) {
      console.error('API Error Response:', error.response.data);
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
  };


