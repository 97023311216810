import {
    Button,
    Flex,
    HStack,
    Input,
    SimpleGrid,
    Stack,
    Tab,
    TabList,
    Text,
    TabPanel,
    Tabs,
    useToast,
    Select,
    Divider,
    Heading,
    TabPanels,
    VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PeopleService } from 'services/PeopleService';
import { BusinessPersona } from 'types/People';
import { StateList } from 'types/StateList';
import * as Yup from 'yup';
import { Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import moment from 'moment';
import { PersonaService } from 'services/PersonaService';
import { Personas } from 'types/Persona';
import { BusinessListTable } from '../components/BusinessListTable';
import BusinessPeopleModel from '../components/BusinessPeopleModel';
import { tenantService } from 'services/TenantService';
import CustomButton from '../../../../components/button/CustomButton';
import { phoneNumberAutoFormat } from '../../../../components/phonenumber/phoneNumberAutoFormat';

export default function PeopleViewPage() {
    const { peopleId } = useParams();
    const [stateList, setStateList] = useState<StateList[] | []>([]);
    const [businessList, setBusinessList] = useState<[]>([]);
    const [personaList, setPersonaList] = useState([]);
    const [businessTenantList, setBusinessTenantList] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const toast = useToast();
    const navigate = useNavigate();
    const [userLoggedIn] = [JSON.parse(localStorage.getItem('userLoginStatus'))];
    const [tenantId, setTenantId] = useState<number | null>(null);
    const [currentUserId, setCurrentUserId] = useState<string>(null);

    const formInit = {
        clientId: 0,
        userId: '',
        tenantId: 0,
        clientName: '',
        firstName: '',
        lastName: '',
        dob: '',
        socialsecuritynumber: '',
        postalnumber: '',
        stateid: 0,
        clientAddress: '',
        phoneNumber: '',
        email: '',
        preferredContact: '',
        creditScore: '',
        lendabilityScore: '',
        marriedStatus: 0,
        spouseClientId: 0,
        rootFolder: '',
        clientAddress1: '',
        city: '',
        gender:'',
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = async () => {
        try {
            getBussinessByTenant();
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        getClientssById();
    };

    useEffect(() => {
        getStateList();
        getPersonaList();
        setTenantId(userLoggedIn?.userDetails?.userInfo?.tenantId);
        setCurrentUserId(userLoggedIn?.userDetails?.userInfo?.userId);
        if (peopleId) {
            getClientssById();
            formik.values = peopleDetails;
        }

        return () => {
            setStateList([]);
            setPeopleDetails(formInit);
            setPersonaList([]);
            formik.values = formInit;
            setTenantId(0);
        };
    }, []);

    const deleteBusiness = async (id: number) => {
        try {
            const data: any = await PeopleService.deleteBusinessDetailsById(id);
            toast({
                title: 'Business deleted.',
                description: 'Business deleted successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            getClientssById();
        } catch (error: any) {
            toast({
                title: 'Error.',
                description: 'Error occured while deleting the Business',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    };

    const [peopleDetails, setPeopleDetails] = useState(formInit);

    const getStateList = async () => {
        try {
            const data: any = await PeopleService.getState<StateList>('tenantId');
            setStateList(data);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const getFormattedDate = (dob: string) => {
        return moment(dob, 'YYYY-MM-DD').format('YYYY-MM-DD');
    };

    const getClientssById = async () => {
        if (!peopleId) {
            navigate('/admin/people');
            return;
        }
        try {
            const data: any =
                await PeopleService.getBusinessDetailsById<BusinessPersona>(
                    parseInt(peopleId),
                );
            setBusinessList(data.businesses);
            setPeopleDetails(data.client ? data.client : {});
            formik.values = data.client;
            formik.initialValues = data.client;
        } catch (error) {
            navigate('/admin/people');
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const getPersonaList = async () => {
        try {
            const data: any = await PersonaService.getPeoplePersona<Personas>();
            setPersonaList(data);
        } catch (error) {
            navigate('/admin/people');
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const getBussinessByTenant = async () => {
        try {
            let tenantId = userLoggedIn?.userDetails?.userInfo?.tenantId;
            const data: any =
                await tenantService.getBussinessByTenantId<BusinessPersona>(tenantId);
            setBusinessTenantList(data.business);
        } catch (error) {
            navigate('/admin/people');
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const clickCancel = () => {
        navigate('/admin/people');
    };

    const validationSchema = Yup.object().shape({
       
        //firstName: Yup.string().required('First Name is required'),
        //lastName: Yup.string().required('Last Name is required'),
        //dob: Yup.date().required('Date of Birth is required'),
        //socialsecuritynumber: Yup.string().required('Social Security Number is required'),
        //postalnumber: Yup.string().required('Zip Code is required'),
        //city: Yup.string().required('City is required'),
        //stateid: Yup.string().notOneOf(['0'], 'Select State').required('Select State'),
        //clientAddress: Yup.string().required('Street Address 1 is required'),
        //clientAddress1: Yup.string().required('Street Address 2 is required'),
        //phoneNumber: Yup.string().required('Mobile Number is required'),
        //email: Yup.string().required('Email is required'),
        //marriedStatus: Yup.number().required('Select Martial Status'),
        //gender: Yup.string().required('Select Gender'),

        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        phoneNumber: Yup.string().required('Mobile Number is required'),
    });


    const formik = useFormik({
        initialValues: peopleDetails,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            values.clientAddress = values.clientAddress;
            values.clientAddress1 = values.clientAddress1;
            values.dob = new Date(values.dob).toJSON();
            values.userId = currentUserId;
            values.socialsecuritynumber = values.socialsecuritynumber.replace(/-/g, '');
            values.postalnumber = values.postalnumber.replace(/-/g, '');
            values.gender = values.gender;
            if (peopleId) {
                try {
                    const data: any = await PeopleService.updatePeople(values);
                    toast({
                        title: values.firstName + ',' + values.lastName + ' successfully updated.',
                        //description: values.firstName + ',' + values.lastName,
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    clickCancel();
                } catch (error) {
                    toast({
                        title: 'People update error.',
                        description: 'People update error.',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            } else {
                try {
                    values.tenantId = tenantId;
                    //values.modifiedBy =
                    const data: any = await PeopleService.createPepople(values);
                    toast({
                        title: values.firstName + ',' + values.lastName + ' successfully created.',
                        //title: 'People created.',
                        //description: 'People Added successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    navigate('/admin/people');
                } catch (error) {
                    toast({
                        title: 'Error occured while creating ' + values.firstName + ',' + values.lastName,
                        //description: 'People Create Error.',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            }
        },
    });

    // Below code is for formatting phone number , postal code and SSN

    const handleFormattedInput = (event: { target: any; }, fieldName: string, maxDigits: number, isSSN = false, separator = '-') => {
        let inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        inputValue = inputValue.substring(0, maxDigits); // Limit to a maximum of `maxDigits` digits
        const maskedCharacters = 'XXX-XX';
        let formattedValue = inputValue;
        if (isSSN) {
            let digitCount = inputValue.replace(/\D/g, '').length; // Count digits without separators
            formattedValue = inputValue.replace(/^(\d{0,3})(\d{0,2})?(\d{0,4})?$/, (match: any, p1: string, p2: any, p3: any) => {
                let formatted = '';
                if (p2 === undefined && p3 === undefined) {
                    formatted = p1;
                } else if (p3 === undefined) {
                    formatted = `${p1}${separator}${p2}`;
                } else {
                    formatted = `${p1}${separator}${p2}${separator}${p3}`;
                    if (digitCount === maxDigits && formatted.length === 11) {
                        formatted = `${maskedCharacters}${separator}${p3}`;
                    } else if (digitCount > 11) {
                        formattedValue = inputValue;
                    }
                }
                return formatted;
            });
        } else if (fieldName === 'phoneNumber') {
            // Adjust the regex pattern for phone number formatting
            formattedValue = inputValue.replace(/^(\d{0,3})(\d{0,3})?(\d{0,4})?$/, (match: any, p1: string, p2: any, p3: any) => {
                let formatted = '';
                if (p2 === undefined && p3 === undefined) {
                    formatted = `(${p1})`; // Format as (XXX)
                } else if (p3 === undefined) {
                    formatted = `(${p1}) ${p2}`; // Format as (XXX) XXX
                } else {
                    formatted = `(${p1}) ${p2}-${p3}`; // Format as (XXX) XXX-XXXX
                }
                return formatted;
            });
        } else {
            // Adjust the regex pattern for postal code formatting
            formattedValue = inputValue.replace(/^(\d{0,5})(\d{0,3})?$/, (match: any, p1: string, p2: any) => {
                let formatted = '';
                if (p2 === undefined) {
                    formatted = p1;
                } else {
                    formatted = `${p1}${separator}${p2}`;
                }
                return formatted;
            });
        }

        // Update formik values with formatted value
        formik.setFieldValue(fieldName, formattedValue);
    };

    const addNewBusiness = () => {
        navigate('/admin/business/AddBusiness', { state: { businessId: '' } });
    };

    const handleChange = (event: { target: any; }, fieldType: string) => {
        let fieldName = '';
        let maxDigits = 0;
        let isSSN = false;
        let separator = '-';
        //let isValidEmail = false;
        switch (fieldType) {
            case 'socialsecuritynumber':
                fieldName = 'socialsecuritynumber';
                maxDigits = 9;
                isSSN = true;
                separator = '-';
                break;
            case 'phoneNumber':
                fieldName = 'phoneNumber';
                maxDigits = 10;
                break;
            case 'postalCode':
                fieldName = 'postalnumber';
                maxDigits = 5;
                separator = '-';
                break;
            //case 'email':
            //    fieldName = 'email';
            //    isValidEmail = validateEmail(event.target.value);
            //    break;
            default:
                break;
        }
        //if (isValidEmail) {
        //    handleFormattedInput(event, fieldName, maxDigits, isSSN, separator);
        //} else {
        //}

        handleFormattedInput(event, fieldName, maxDigits, isSSN, separator);
    };

    //const validateEmail = (email: string): boolean => {
    //    // Email validation regex
    //    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //    return emailRegex.test(email);
    //};


    return (
        <Card extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'} paddingTop="25">
            <Tabs isFitted variant="enclosed" bg="white">
                <TabList>
                    {peopleId ? <Tab>Profile</Tab> : <Tab isDisabled> Profile</Tab>}
                    {/* <Tab>People</Tab> */}
                    {peopleId ? <Tab>Associated Businesses</Tab> : ""}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <div>
                            <Box>
                                <form onSubmit={formik.handleSubmit}>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Text mb="8px">*First Name: </Text>
                                            <Input
                                                placeholder="First Name"
                                                as={Input}
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                size="md"
                                                width="50%"
                                                onChange={formik.handleChange}
                                                value={formik.values.firstName}
                                            />
                                            <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                {formik.touched.firstName
                                                    ? formik.errors.firstName
                                                    : ''}
                                            </span>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">

                                        <Box>
                                            <Text mb="8px">*Last Name: </Text>
                                            <Input
                                                placeholder="Last Name"
                                                as={Input}
                                                id="lastName"
                                                name="lastName"
                                                type="text"
                                                size="md"
                                                width="50%"
                                                onChange={formik.handleChange}
                                                value={formik.values.lastName}
                                            />
                                            <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                {formik.touched.lastName ? formik.errors.lastName : ''}
                                            </span>
                                        </Box>
                                    </SimpleGrid>

                                    <SimpleGrid
                                        p={2}
                                        spacingX={3}
                                        minChildWidth="225px"
                                        spacingY={'20px'}
                                    >
                                        <Box>
                                            <Box>
                                                <Text mb="8px">*Mobile Number: </Text>
                                                <Input
                                                    placeholder="(XXX) XXX-XXXX"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    type="text"
                                                    size="md"
                                                    width="50%"
                                                    onChange={(event) => handleChange(event, 'phoneNumber')}
                                                    value={formik.values.phoneNumber}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.phoneNumber
                                                        ? formik.errors.phoneNumber
                                                        : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Email: </Text>
                                                <Input
                                                    placeholder="name@email.com"
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    size="md"
                                                    width="50%"

                                                    onChange={formik.handleChange}

                                                    value={formik.values.email}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.email ? formik.errors.email : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>

                                    <SimpleGrid
                                        p={2}
                                        spacingX={4}
                                        minChildWidth="225px"
                                        spacingY={'20px'}
                                    >
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Street Address 1: </Text>
                                                <Input
                                                    placeholder="Address 1"
                                                    id="clientAddress"
                                                    name="clientAddress"
                                                    type="text"
                                                    size="md"
                                                    width="50%"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.clientAddress}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.clientAddress
                                                        ? formik.errors.clientAddress
                                                        : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Street Address 2: </Text>
                                                <Input
                                                    placeholder="Address 2"
                                                    id="clientAddress1"
                                                    name="clientAddress1"
                                                    type="text"
                                                    size="md"
                                                    width="50%"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.clientAddress1}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.clientAddress1
                                                        ? formik.errors.clientAddress1
                                                        : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Box>
                                                <Text mb="8px">City: </Text>
                                                <Input
                                                    placeholder="City"
                                                    id="city"
                                                    name="city"
                                                    type="text"
                                                    size="md"
                                                    width="50%"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.city}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.city ? formik.errors.city : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            {/* <Grid templateRows='repeat(3)' templateColumns='repeat(2, 1fr)' gap={4} > */}
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">State: </Text>

                                                    <Select
                                                        placeholder="Select an option"
                                                        name="stateid"
                                                        value={formik.values.stateid}
                                                        width="50%"
                                                        onChange={formik.handleChange}
                                                    >
                                                        {stateList.map((state: StateList) => (
                                                            <option key={state.name} value={state.id}>
                                                                {state.name}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.stateid
                                                            ? formik.errors.stateid
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Zip Code: </Text>
                                                <Input
                                                    placeholder="00000"
                                                    id="postalnumber"
                                                    name="postalnumber"
                                                    type="text"
                                                    size="md"
                                                    width="50%"
                                                    onChange={(event) => handleChange(event, 'postalCode')}
                                                    value={formik.values.postalnumber}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.postalnumber
                                                        ? formik.errors.postalnumber
                                                        : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <Divider mt="24px" mb="24px" />

                                    <Heading pt="24px" pb="24px" as="h4" size="lg">
                                        Extended Profile
                                    </Heading>

                                    <SimpleGrid
                                        p={2}
                                        spacingX={3}
                                        minChildWidth="225px"
                                        spacingY={'20px'}
                                    >
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Date of Birth: </Text>
                                                <Input
                                                    id="dob"
                                                    name="dob"
                                                    type="date"
                                                    size="md"
                                                    width="50%"
                                                    onChange={formik.handleChange}
                                                    value={getFormattedDate(formik.values.dob)}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.dob ? formik.errors.dob : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Social Security Number: </Text>
                                                <Input
                                                    placeholder="XXX-XX-XXXX"
                                                    id="socialsecuritynumber"
                                                    name="socialsecuritynumber"
                                                    type="string"
                                                    size="md"
                                                    width="50%"
                                                    onChange={(event) => handleChange(event, 'socialsecuritynumber')}
                                                    value={formik.values.socialsecuritynumber}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.socialsecuritynumber ? formik.errors.socialsecuritynumber : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Gender: </Text>
                                                <Select
                                                    placeholder="Select option"
                                                    id="gender"
                                                    name="gender"
                                                    size="md"
                                                    width="50%"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.gender}
                                                >
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </Select>
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.gender
                                                        ? formik.errors.gender
                                                        : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid p={2} spacingX={2} minChildWidth="225px">
                                        <Box>
                                            <Box>
                                                <Text mb="8px">Martial Status: </Text>
                                                <Select
                                                    placeholder="Select option"
                                                    id="marriedStatus"
                                                    name="marriedStatus"
                                                    size="md"
                                                    width="50%"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.marriedStatus}
                                                >
                                                    <option value="0">Single</option>
                                                    <option value="1">Married</option>
                                                </Select>
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.marriedStatus
                                                        ? formik.errors.marriedStatus
                                                        : ''}
                                                </span>
                                            </Box>
                                        </Box>
                                    </SimpleGrid>
                                    <SimpleGrid
                                        p={2}
                                        spacingX={'10px'}
                                        minChildWidth="225px"
                                        spacingY={'20px'}
                                        ml="8px"
                                        mb="16px"
                                    >
                                        <Box></Box>
                                        <Box></Box>
                                        <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                            <CustomButton label={'Cancel'} onClick={clickCancel} />
                                            <CustomButton
                                                label={'Save'}
                                                onClick={null}
                                                type="submit"
                                            />
                                           
                                        </Flex>
                                    </SimpleGrid>
                                </form>
                            </Box>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <Box>
                            <Box>
                                <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                <CustomButton label={"Add Business"} onClick={addNewBusiness} />
                                    <CustomButton label={'Link Business'} onClick={openModal} />
                                        </Flex>

                                <BusinessPeopleModel
                                    isOpen={isModalOpen}
                                    onClose={closeModal}
                                    businessList={businessList}
                                    personaList={personaList}
                                    tenantId={tenantId}
                                    clientID={peopleId}
                                    peopleList={businessTenantList}
                                />
                            </Box>
                            <Box>
                                <BusinessListTable
                                    businessList={businessList}
                                    personaList={personaList}
                                    deleteBusiness={deleteBusiness}
                                ></BusinessListTable>
                            </Box>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Card>
    );
}
