import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Input,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Select,
    useColorModeValue,
    Checkbox,
    useToast

} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
    createColumnHelper,
} from '@tanstack/react-table';
import { BusinessPersona } from 'types/People';
import { TableViewComponent } from 'components/table/tablecomponent';
import { PersonaService } from '../../../../services/PersonaService';
import CustomButton from '../../../../components/button/CustomButton';


interface BusinessModalProps {
    isOpen: boolean;
    onClose: () => void;
    tenantId: number | null;
    businessList: any[];
    personaList: any[];
    clientID: string;
    peopleList: any[];

}

const BusinessPeopleModel: React.FC<BusinessModalProps> = ({ isOpen, onClose, tenantId, businessList, personaList, clientID, peopleList }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const columnHelper = createColumnHelper<BusinessPersona>();

    const businessIds = businessList.map((item: any) => item.businessId);
    const businessPopUpDetails = peopleList.filter((item: any) => !businessIds.includes(item.businessId));

    let activeColor = useColorModeValue('gray.700', 'white');
    const toast = useToast();

    const saveBusinessesRelation = async () => {
        try {
            let isFormValid = true;
            const mapClientDetails: any = [];
            businessPopUpDetails.forEach(peopleMap => { 
                if (peopleMap.isSelected) {
                    if (!peopleMap?.personaAutoId) {
                        isFormValid = false;
                        return;
                    }
                    mapClientDetails.push({
                        clientBusinessId: 0,
                        clientId: clientID,
                        businessId: Number(peopleMap.businessId),
                        personaId: Number(peopleMap.personaAutoId)
                    });
                }
            });

            if (isFormValid && mapClientDetails.length > 0) {
                const data: any = await PersonaService.createClintRelation(mapClientDetails);
                toast({
                    title: 'Link Business created.',
                    description: 'successfully Linked Business.',
                    status: 'success',
                    duration: 5000,
                    position: 'top',
                    isClosable: true,
                });
                onClose();
            } else {
                toast({
                    title: 'error',
                    description: 'Please select the checkBox and valid Role.',
                    status: 'error',
                    duration: 5000,
                    position: 'top',
                    isClosable: true,
                });
            }


        } catch (error: any) {
            toast({
                title: 'error',
                description: error.response.data.title,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    }

    const bindSelectedDetailsToUser = (userData: any, keyType: string, value: any) => {
        const data = userData.original;
        data[keyType] = value;
        businessPopUpDetails[userData.index] = data
    }


    const selectPersonType = (e: any, info: any) => {

        bindSelectedDetailsToUser(info.row, "personaAutoId", e.target.value);
    }

    const columns = [

        columnHelper.accessor('isSelected', {
            id: 'isSelected',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">

                </p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">

                    </p>
                    <p color={activeColor}>
                        {/*<Checkbox*/}
                        {/*    name={'bussinessClient'} value={info.getValue()}></Checkbox>*/}

                        <Checkbox
                            name={'bussinessClient'} onChange={(e) => {
                                bindSelectedDetailsToUser(info.row, "isSelected", e.target.checked);
                            }}></Checkbox>
                    </p>
                </Box>

            ),
        }),
        columnHelper.accessor('businessName', {
            id: 'businessName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Business Name
                </p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        Business Name
                    </p>
                    <p color={activeColor}
                        className="text-sm font-bold  dark:text-white">
                        {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                        {info.getValue()}
                    </p>
                </Box>

            ),
        }),
        columnHelper.accessor('businessType', {
            id: 'businessType',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Business Type
                </p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">
                        Business Type
                    </p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </Box>
            ),
        }),
        columnHelper.accessor('industry', {
            id: 'industry',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Industry</p>
            ),
            cell: (info) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Industry</p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </Box>
            ),
        }),
        columnHelper.accessor('personas.personaName', {
            id: 'personName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Role
                </p>
            ),
            cell: (info: any) => (
                <Box>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Role</p>
                    <Select placeholder='Select' onChange={(e) => {

                        selectPersonType(e, info)
                    }}  >
                        {personaList.map((persona: any) => (
                            <option key={persona.personaId} value={persona.personaId}  >{persona.personaName}</option>
                        ))}
                    </Select>
                </Box>
            ),
        }),
    ]; // eslint-disable-next-line

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Business People</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {(businessPopUpDetails && businessPopUpDetails?.length > 0) ? <TableViewComponent
                        columnsList={columns}
                        tableData={businessPopUpDetails}
                        enablePagination={businessPopUpDetails?.length > 15 ? true : false}
                        enableSearch={true}
                        saveButtonValue="Save"
                        saveButtonClick={saveBusinessesRelation}
                        cancelButtonValue="Cancel"
                        cancelButtonClick={onClose}   
                        viewSaveCancel = {true}                  >
                    </TableViewComponent> : <div>No Records Available</div>}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default BusinessPeopleModel;
