import React from 'react';

import { MdChevronRight, MdChevronLeft } from 'react-icons/md';


import {
    PaginationState,
    createColumnHelper,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
} from '@tanstack/react-table';
import { Box, Button, Flex, SimpleGrid, Table, Tbody, Thead, useColorModeValue } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import SearchIcon from 'components/icons/SearchIcon';
import Card from 'components/card/Card';
import { People } from 'types/People';
import CustomButton from '../../../../components/button/CustomButton';
import { TableViewComponent } from 'components/table/tablecomponent';
import { phoneNumberAutoFormat } from 'components/phonenumber/phoneNumberAutoFormat';

function PeopleListTable(props: { tableData: any }) {
    const navigate = useNavigate();

    const bg = useColorModeValue('background.100', 'background.900');
    const { tableData } = props;
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
        [],
    );
    let defaultData = tableData;
    // const [tableView, setTableView] = React.useState(true);
    // const [fileDetailsView, setFileDetailsView] = React.useState(false);
    const [listViewCount, setListViewCount] = React.useState(6);
    const [globalFilter, setGlobalFilter] = React.useState('');

    const addNewPeople = () => {
        navigate("/admin/people/AddPeople");
    };

    const onFileViewClick = (fileId: any, flag: any) => {
        let selectedFile: File = data.find(file => file.fileId === fileId);
        // navigate("/admin/people/ViewPeople", { state: { fileId: fileId, file: selectedFile } });
        navigate("/admin/people/PeopleDemo", { state: { fileId: fileId, file: selectedFile } });

    }

    const createPages = (count: number) => {
        let arrPageCount = [];

        for (let i = 1; i <= count; i++) {
            arrPageCount.push(i);
        }

        return arrPageCount;
    };
    const columns = [
        columnHelper.accessor('firstName', {
            id: 'name',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Name
                </p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Name</p>
                    <p
                        style={{ textDecoration: 'underline' }} // Apply underline style here
                        color={activeColor}
                        className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                        onClick={() => {
                            //onPeopleClick(info.getValue(), true);
                            onPeopleClick(info.row.original.clientId, true);
                        }}
                    >
                        {info.row.original.firstName} , {info.row.original.lastName}
                    </p>
                </div>
            ),
        }),
        columnHelper.accessor('phoneNumber', {
            id: 'phoneNumber',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Phone
                </p>
            ),
            cell: (info) => {
                //const phoneNumber = info.row.original.phoneNumber;
                // Format the phone number as desired, for example, inserting dashes
                //const formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

                return (
                    <div>
                        <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Phone</p>
                        <p color={activeColor} className="text-sm font-bold dark:text-white">
                            {phoneNumberAutoFormat(info.getValue())}
                        </p>
                    </div>
                );
            },
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Email</p>
            ),
            cell: (info) => (
                <div>
                    <p className="tdBefore text-sm font-bold text-gray-600 dark:text-white">Email</p>
                    <p color={activeColor} className="text-sm font-bold dark:text-white">
                        {info.getValue()}
                    </p>
                </div>
            ),
        }),

    ]; // eslint-disable-next-line

    const [data, setData] = React.useState(() => [...defaultData]);

    const [listFilterData, setListFilterData] = React.useState(() => [
        ...defaultData,
    ]);
    const [peopleId, setPeopleId] = React.useState(1);
    const [selectedPeople, setSelectedPeople] = React.useState(null);
    const [{ pageIndex, pageSize }, setPagination] = React.useState<
        PaginationState
    >({
        pageIndex: 0,
        pageSize: 15,
    });
    let activeColor = useColorModeValue('gray.700', 'white');

    const addNewPersonClick = () => {
        navigate('/admin/people/AddPeople');
    };

    const onPeopleClick = (clientId: any, flag: any) => {
        let selectedPeople = data.find((people) => people.clientId === clientId);
        navigate("/admin/people/ViewPeople/" + clientId, { state: { clientId: clientId, clientDetails: selectedPeople } });

    };

    const onChangeSearchFilter = (e: any) => {
        setGlobalFilter(e.target.value);
        setListViewCount(6);
        let filterData: any[] = [];
        table.getFilteredRowModel().rows.forEach((row) => {
            filterData.push(row.original);
        });
        setListFilterData(filterData);
    };

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );
    const table = useReactTable({
        data,
        columns,
        state: {
            columnFilters,
            globalFilter,
            pagination,
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    return (
        <Box bg={bg}>
            <GetTableView />
        </Box>
    );


    function GetTableView(props: any) {
        return ((data && data?.length > 0) ? <TableViewComponent
            columnsList={columns}
            tableData={data}
            enablePagination={tableData?.length > 15 ? true : false}
            enableSearch={true}
            saveButtonValue="Save"
            viewSaveCancel = {false}
            cancelButtonValue="Cancel" 
        ></TableViewComponent> : <div>No Records Available</div>)
        
    }

    
}

export default PeopleListTable;
const columnHelper = createColumnHelper<People>();