////export const phoneNumberAutoFormat = (phoneNumber: string): string => {
////    const number = phoneNumber.trim().replace(/[^0-9]/g, "");
  
////    if (number.length < 4) return number;
////    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
////    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
////    return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
////};

export const phoneNumberAutoFormat = (phoneNumber: string): string => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    if (number.length < 3) return number; // If length is less than 3, return the original number
    if (number.length < 6) return `(${number.slice(0, 3)}) ${number.slice(3)}`; // Format as (XXX) XXX-XXXX
    if (number.length < 10) return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`; // Format as (XXX) XXX-XXXX
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`; // Format as (XXX) XXX-XXXX
};
