import { Box, useColorModeValue } from '@chakra-ui/react';
import 'assets/css/layout.css';
function Card(props: {
  variant?: string;
  extra?: string;
  children?: JSX.Element | any[];
  [x: string]: any;
}) {
  const bg = useColorModeValue('background.100', 'background.900');
  const { variant, extra, children,isHover, ...rest } = props;
  return (
    <Box  bg={bg} _hover={isHover? { bg: 'brand.200', color: "white" } : {}}
      className={`!z-5 relative flex flex-col rounded-[20px] bg-clip-border shadow-3xl ${
        props.default
          ? 'shadow-shadow-500 dark:shadow-none'
          : 'shadow-shadow-100 dark:shadow-none'
      }  dark:!bg-navy-800 dark:text-white  ${extra}`}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default Card;
