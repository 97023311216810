import './assets/css/App.css';
import './assets/css/layout.css';
import { Routes, Route, Navigate, useLocation, BrowserRouter } from 'react-router-dom';
import {} from 'react-router-dom';
import AdminLayout from './layouts/admin';
import { userService } from 'services/UsersService';
import { getActiveRoutes, getAllValidRoutes, getFilteredSearchLink } from 'routes';
import { useAuth, hasAuthParams } from "react-oidc-context";
import UploadPage from 'views/upload';
import {
  Box,
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useEffect, useState } from 'react';
import SpinnerPage from 'components/spinner/spinner';
import { tenantService } from 'services/TenantService';
import UploadLayout from 'layouts/upload';
// Chakra imports

export default function Main() {
  
  const [userInfo, setUserInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const location = useLocation();
  const [userLogged] = useState(
    JSON.parse(localStorage.getItem("userLoginStatus"))
  );
  const [breadCrumbList, setBreadCrumbList] = useState<{name: string, url: string, route: RoutesType}[]>(( JSON.parse(localStorage.getItem("breadCrumbs"))));
  useEffect(() => {
    localStorage.setItem('breadCrumbs', JSON.stringify(breadCrumbList));
  }, [breadCrumbList]);
  
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

    // automatically sign-in
    useEffect(() => {
      setIsUpload(location?.pathname.startsWith("/upload/"));
      if(!isUpload) {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
            !hasTriedSignin
        ) {
          localStorage.removeItem("userLoginStatus");
          localStorage.removeItem("userLoginToken");
            auth.signinRedirect();
            setHasTriedSignin(true);
        } else if(auth.isAuthenticated) {
          getUserInfo(auth.user.profile.preferred_username);  
        }
      }
    }, [auth, hasTriedSignin]);


    useEffect(() => {
      if(breadCrumbList && breadCrumbList.length >0) {
        if(breadCrumbList[breadCrumbList.length-1].url == location.pathname) {
          return;
        } else {
          let exisit = false;
          breadCrumbList.forEach((breadCrumb, index) => {
            if(breadCrumb.url == location.pathname) {
              let breadCrumbNew = [...breadCrumbList];
              breadCrumbNew.length = index+1;
              setBreadCrumbList(breadCrumbNew);
              exisit = true;
              return;
            }
          });

          if(exisit) {
            return;
          }
        }
      } 
      

      let allRoutes = getAllValidRoutes();
      let isActiveRoute = false;
      let routePath: RoutesType;

      let filteredRoute = getFilteredSearchLink(location.pathname);
      allRoutes.forEach((route) => {
        if((route.layout + route.path)== filteredRoute) {
          routePath = route;
          isActiveRoute = true;
          return;
        }
      });
      if(routePath && routePath?.isActive) {
        setBreadCrumbList([{name: routePath.name, url: location.pathname, route: routePath}]);
      } else if (routePath) {
        setBreadCrumbList([...breadCrumbList, {name: routePath.name, url: location.pathname, route: routePath}]);
      }
    }, [location]);


    const getUserInfo = async (userName: string) => {
      try {
        //setLoading(true);
        localStorage.setItem("userLoginToken", auth.user.access_token);
        const data: any = await userService.getUserByUserName(auth.user.profile.email);
        const userType = data?.isAdminUser === 1? ( data?.isTenantUser !== 1? "Admin" : "TenantAdmin") : "Staff";

        const tenentId = data?.tenantId;
        const tenantDetails = await tenantService.gettenantById(
            tenentId,
        );
        data.userType = userType;
        data.tenant = tenantDetails;
        
        setUserInfo(data);
        let userParams = JSON.parse(localStorage.getItem("userLoginStatus"));
        if(!userLogged || !(userLogged?.isUserLoggedIn) ) {
          const now = new Date();
          const item = {
            isUserLoggedIn: true,
            userDetails: {
              "userName": auth.user.profile.preferred_username,
              "profile": auth.user.profile,
              "userInfo": data,
            },
            loginToken: auth.user.id_token,
            expiry: now.getTime() + 2000,
          }
          localStorage.removeItem("userLoginStatus");
          localStorage.removeItem("userLoginToken");
          localStorage.setItem("userLoginStatus", JSON.stringify(item));
          localStorage.setItem("userLoginToken", auth.user.access_token);
        }
  
  
      } catch (error) {
        setError('Error fetching people data');
      } finally {
        setLoading(false);
      }
    };
    if(!isUpload) { 
      if (auth.isLoading || loading) {
        return  <SpinnerPage /> ;
      } else if (!auth.isAuthenticated) {
          auth.signinRedirect();
      } else if(auth.isAuthenticated && !loading) {  
        return (
          <ChakraProvider theme={currentTheme}>
            <Box >
              <Routes>
                <Route path="admin/*" element={ <AdminLayout breadCrumbs={breadCrumbList} theme={currentTheme} setTheme={setCurrentTheme} /> } />
                <Route path="/" element={<Navigate to="/admin" replace />} />
              </Routes>
            </Box>
          </ChakraProvider>
        );
      } else {
        return <div>Invalid Login</div>
      } 
  } else {
    return <ChakraProvider theme={currentTheme}><Routes>
                <Route path={"upload/*"} element={ <UploadPage /> } />
          </Routes>
          </ChakraProvider>
           
  }
    
}
