import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Input,
    useToast,
    useColorModeValue,
    Flex
} from '@chakra-ui/react';
import { UploadService } from '../../../../../services/UploadService';
import CustomButton from '../../../../../components/button/CustomButton';

interface FileUploadModalProps {
    isOpen: boolean;
    onClose: () => void;
    callUpdate: () => void;
    tableData: any; // Replace with the actual type if known
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ isOpen, onClose, callUpdate, tableData }) => {
    const [file, setFile] = useState<File | null>(null);
    const toast = useToast();
    const activeColor = useColorModeValue('gray.700', 'white');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (file) {
            const reader = new FileReader();

            reader.onload = async (event) => {
                try {
                    const fileDataUrl = event.target?.result as string;
                    const base64Data = fileDataUrl.split(',')[1];
                    const mimeTypeSegment = fileDataUrl.split(',')[0];
                    const mimeType = mimeTypeSegment.split(':')[1].split(';')[0];
                    const fileExtension = mimeType.split('/')[1];
                    const token = "";
                    const tenantId = tableData.tenantId;
                    const projectId = tableData.projectId;
                    const projectRequestId = tableData.projectRequestId;
                    const projectRequestSentId = tableData.projectRequestSentId;
                    const peopelId = tableData.peopleId;
                    const documentTypeAutoId = tableData.documentTypeAutoId;
                    const fileext = "."+fileExtension;
                    const label = tableData.label;

                    const params = {
                        token: token,
                        tenantId: tenantId,
                        projectId: projectId,
                        projectRequestId: projectRequestId,
                        fileData: base64Data,
                        projectRequestSentId: projectRequestSentId,
                        peopelId: peopelId,
                        documentTypeAutoId: documentTypeAutoId,
                        fileext: fileext,
                        fileName: label+ "_"+projectId+"_"+projectRequestSentId+"_"+peopelId,
                    };
                    await UploadService.projectRequestUploadFileForStaff(params);
                    toast({
                        title: 'File uploaded successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    callUpdate();
                    onClose();
                } catch (error) {
                    handleUploadError(error);
                }
            };

            reader.readAsDataURL(file);
        } else {
            toast({
                title: 'No file selected.',
                description: 'Please select a file to upload.',
                status: 'warning',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    };

    const handleUploadError = (error: any) => {
        toast({
            title: 'Upload failed.',
            description: 'There was an error uploading your file.',
            status: 'error',
            duration: 5000,
            position: 'top',
            isClosable: true,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload File</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box as="form" onSubmit={handleSubmit}>
                        <Input type="file" onChange={handleFileChange} mb={4} accept=".pdf,.doc,.jpg"/>
                        <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                            <CustomButton
                                label={"Cancel"}
                                onClick={onClose}
                            />
                            <CustomButton
                                label={"Submit"}
                                onClick={null}
                                type="submit"
                            />
                           
                        </Flex>

                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default FileUploadModal;
