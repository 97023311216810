import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    useColorModeValue,
    Checkbox,
    useToast,
    Select

} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
    createColumnHelper,
} from '@tanstack/react-table';
import { ProjectPeople } from 'types/People';
import { TableViewComponent } from 'components/table/tablecomponent';
import { PersonaService } from '../../../../../services/PersonaService';
import { Personas } from '../../../../../types/Persona';
import { useNavigate, useParams } from 'react-router-dom';



interface ProjectsLinkToPeopleModel {
    isOpen: boolean;
    onClose: () => void;
    peopleList: any[];

}

const ProjectsLinkToPeople: React.FC<ProjectsLinkToPeopleModel> = ({ isOpen, onClose, peopleList }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const toast = useToast();
    const { projectId } = useParams();
    // const temDetails = jsonData.filter((item: any) => !userIds.includes(item.userId)); 
    let activeColor = useColorModeValue('gray.700', 'white');
    const [personaList, setPersonaList] = useState([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();


    useEffect(() => {
        const getPersonaList = async () => {
            try {
                const data: any = await PersonaService.getProjectPersona<Personas>();
                setPersonaList(data);
            } catch (error) {
                navigate('/admin/projects');
                setError('Error fetching user data');
            } finally {
                setLoading(false);
            }
        };

        getPersonaList();
    }, []);

    const bindSelectedDetailsToUser = (userData: any, keyType: string, value: any) => {
        const data = userData.original;
        data[keyType] = value;
        peopleList[userData.index] = data
    }

    const selectPersonType = (e: any, info: any) => {

        bindSelectedDetailsToUser(info.row, "personaAutoId", e.target.value);
    }
    const columns = [
        columnHelper.accessor('isSelected', {
            id: 'isSelected',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">

                </p>
            ),

            cell: (info) => (
                <Box color={activeColor}>
                    <Checkbox
                        name={'bussinessClient'} onChange={(e) => {
                            bindSelectedDetailsToUser(info.row, "isSelected", e.target.checked);
                        }}></Checkbox>
                </Box>

            ),

        }),
        columnHelper.accessor('firstName', {
            id: 'firstName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    First Name#
                </p>
            ),
            cell: (info) => (
                <p
                    color={activeColor}
                    className="text-sm font-bold  dark:text-white cursor-pointer hover:text-gray-600"
                    onClick={() => {
                        // onBusinessClick(info.row.original.pe, true);
                    }}
                >
                    {/* <NavLink href={"./files/" + (info.getValue())} className="mt-0 w-max lg:pt-10">{info.getValue()} </NavLink> */}
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('lastName', {
            id: 'lastName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Last Name
                </p>
            ),
            cell: (info: any) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">Email</p>
            ),
            cell: (info: any) => (
                <p color={activeColor} className="text-sm font-bold dark:text-white">
                    {info.getValue()}
                </p>
            ),
        }),
        columnHelper.accessor('personas.personaName', {
            id: 'personName',
            header: () => (
                <p className="text-sm font-bold text-gray-600 dark:text-white">
                    Persona
                </p>
            ),
            cell: (info: any) => (
                <Box>
                    <Select placeholder='Select' onChange={(e) => {

                        selectPersonType(e, info)
                    }} >

                        {personaList.map((persona: any) => (
                            <option key={persona.personaId} value={persona.personaId}  >{persona.personaName}</option>
                        ))}
                    </Select>
                </Box>
            ),
        }),
    ];

    const saveClientRelation = async () => {
        try {
            let isFormValid = true;
            const mapClientDetails: any = [];
            peopleList.forEach(peopleMap => {
                if (peopleMap.isSelected) {
                    if (!peopleMap?.personaAutoId) {
                        isFormValid = false;
                        return;
                    }
                    mapClientDetails.push({
                        projectPeopleId: 0,
                        tenantId: 0,
                        projectId: Number(projectId),
                        peopleId: peopleMap.clientId,
                        primaryBorrower: 0,
                        personaAutoId: Number(peopleMap.personaAutoId)
                    });
                }

            });


            if (isFormValid) {
                const data: any = await PersonaService.createProjectPeopleRelation(mapClientDetails);
                toast({
                    title: 'Project People link created.',
                    description: 'Project People link created successfully.',
                    status: 'success',
                    duration: 5000,
                    position: 'top',
                    isClosable: true,
                });
                onClose();
            } else {
                toast({
                    title: 'Validation error',
                    description: 'Please select Persona for all selected People.',
                    status: 'error',
                    duration: 5000,
                    position: 'top',
                    isClosable: true,
                });
            }


        } catch (error: any) {

            toast({
                title: 'error',
                description: error.response.data.title,
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Assign People to Project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        //(temDetails && temDetails?.length > 0) ?
                        <TableViewComponent
                            columnsList={columns}
                            tableData={peopleList}
                            enablePagination={peopleList?.length < 10 ? true : false}
                            enableSearch={true}
                            saveButtonValue="Save"
                            saveButtonClick={saveClientRelation}
                            cancelButtonValue="Cancel"
                            viewSaveCancel={true}
                            cancelButtonClick={onClose}
                        ></TableViewComponent>
                        /*: <div>No Data</div>*/
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ProjectsLinkToPeople;
const columnHelper = createColumnHelper<ProjectPeople>();
