// Chakra imports
import {
    Button,
    Heading,
    Input,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    Grid,
    SimpleGrid,
    GridItem,
    useToast,
    Select,
    Divider,
    Flex,
    Spacer,
} from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Card from 'components/card/Card';
import { PeopleService } from 'services/PeopleService';
import * as Yup from 'yup';
import StaffListTable from './components/StaffListTable';
import { tenantService } from 'services/TenantService';
// Custom components
import { useLocation, useNavigate } from 'react-router-dom';
import { Users } from 'types/Users';
import { userService } from 'services/UsersService';
import CustomButton from '../../../../components/button/CustomButton';

interface StateList {
    name: string;
    Code: string;
    id: string;
}

interface Tenant {
    tenantId: number;
    tenantName: string;
    tenantDomain: string;
    tenantLogoPath: string;
    tenantAddress: string;
    tenantPhone: string;
    tenantContactName: string;
    tenantContactEmail: string;
}

export default function AddTenentPage() {
    // Chakra Color Mode
    const formInitialValues = {
        clientId: '',
        userId: '',
        tenantId: '',
        clientName: '',
        companyName: '',
        tenantName: '',
        tenantDomain: '',
        postalCode: '',
        city: '',
        stateid: '',
        tenantAddress: '',
        tenantAddress1: '',
        tenantPhone: '',
        email: '',
        tenantContactEmail: '',
        tenantContactName: '',
        preferredContact: '',
        creditScore: '',
        lendabilityScore: '',
        spouseClientId: 0,
        rootFolder: '',
        tenantLogoPath: '',
    };
    let { state } = useLocation();
    const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');
    const bg = useColorModeValue('background.100', 'background.900');
    const [stateList, setStateList] = useState<StateList[] | []>([]);
    const [userList, setUserList] = useState<StateList[] | []>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [isTenantAdmin, setIsTenantAdmin] = useState<boolean>(false);
    const [isPortalAdmin, setIsPortalAdmin] = useState<boolean>(false);
    const [tenantAdminTenent, setTenantAdminTenent] = useState<any>({});
    const [isAddTenent, setIsAddTenent] = useState<boolean>(true);
    const [tenantDetails, setTenantDetails] = useState(formInitialValues);
    const [tabIndex, setTabIndex] = useState(0);
    const defaultState = {
        tenentId: '',
        tenantSelected: {},
        isStaffListView: false,
    };
    let { tenentId, tenantSelected, isStaffListView } = state ? state : defaultState;
    let [userLoggedIn] = useState(
        JSON.parse(localStorage.getItem('userLoginStatus')),
    );
    const [tenantInfo, setTenentInfo] = useState<Tenant>();
    const [error, setError] = useState<string | null>(null);
    const toast = useToast();
    const navigate = useNavigate();
    const addNewPeople = () => {
        navigate('/admin/tenant/AddTenant/AddStaff', {
            state: {
                tenantId: tenentId,
                tenant: tenantInfo,
                isAddStaff: true,
                tenantDetails: tenantDetails,
            },
        });
    };
    useEffect(() => {
        checkInitialValue();
        getStateList();
        getTenantById();
        getUserList();
        return () => {
            checkInitialValue();
            setStateList([]);
            setTenantDetails(formInitialValues);
            setUserList([]);
        };
    }, []);

    const checkInitialValue = () => {
        let userDetails = userLoggedIn?.userDetails?.userInfo;

        let roleType = userLoggedIn?.userDetails?.userInfo?.roles;
        if (roleType === 'TenantAdmin') {

            tenentId = userLoggedIn?.userDetails?.userInfo?.tenant.tenantId;
            formik.values.tenantId = tenentId;
            tenantSelected = userLoggedIn?.userDetails?.userInfo?.tenant;
            setTenantDetails(tenantSelected);
            setTenantAdminTenent(tenantSelected);
            isStaffListView = 0;
            setIsTenantAdmin(true);
        } else if (roleType === 'Admin') {
            setIsPortalAdmin(true);
        } else if (roleType !== 'Admin') {
            navigate('/');
        }
    };
    const getStateList = async () => {
        try {
            const data: any = await PeopleService.getState<StateList>('tenantId');
            setStateList(data);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };
    const getUserList = async () => {
        try {
            const data: any = await userService.getUserListByTanantId<Users>(
                tenentId,
            );
            setUserList(data?.users);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };
    const getTenantById = async () => {
        // try {
        //     const data: any = await userService.getUserListByTanantId<Users>(
        //         tenentId,
        //     );
        //     setUserList(data);
        // } catch (error) {
        //     setError('Error fetching user data');
        // } finally {
        //     setLoading(false);
        // }



        try {
            const data: any = tenantSelected;

            setTenantDetails({
                clientId: '',
                userId: '',
                tenantId: '',
                clientName: '',
                companyName: '',
                tenantName: data.tenantName,
                tenantDomain: data.tenantDomain,
                postalCode: data.postalCode,
                city: data.city,
                stateid: data.stateid,
                tenantAddress: data.tenantAddress,
                tenantAddress1: data.tenantAddress1,
                tenantPhone: data.tenantPhone,
                email: '',
                tenantContactEmail: data.tenantContactEmail,
                tenantContactName: data.tenantContactName,
                preferredContact: '',
                creditScore: '',
                lendabilityScore: '',
                spouseClientId: 0,
                rootFolder: '',
                tenantLogoPath: '',
            });
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };
    const validationSchema = Yup.object().shape({
        username: Yup.string(),
        password: Yup.string(),
        //tenantId: Yup.number().integer('Age must be an integer').positive('Age must be a positive integer').required('Age is required'),,
        tenantContactName: Yup.string().required('Contact Name is required'),
        tenantName: Yup.string().required('Name is required'),
        tenantDomain: Yup.string().required('Website is required'),
        postalCode: Yup.string().required('Zip Code is required'),
        city: Yup.string().required('City is required'),
        stateid: Yup.string().required('Select state'),
        tenantAddress: Yup.string().required('Street Address 1 is required'),
        tenantAddress1: Yup.string(),
        tenantPhone: Yup.string().required('Mobile Number is required'),
        tenantContactEmail: Yup.string().required('Email is required'),
    });
    const formik = useFormik({
        initialValues: tenantDetails,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            // values.tenantAddress = ${values.address2};
            values.userId = values.userId;

            let userDetails = userLoggedIn?.userDetails?.userInfo;

            let roleType = userLoggedIn?.userDetails?.userInfo?.roles;
       
            // tenentId = userLoggedIn?.userDetails?.userInfo?.tenant.tenantId;

            // values.tenantId = values.tenantId;
            // values.tenantContactEmail = values.email;
            // values.tenantName = values.companyName;
            // delete values.clientId;
            if (!tenentId && !values.tenantId && roleType != 'TenantAdmin') {
                try {
                    tenentId=0;
                    values.tenantId = tenentId;
                    const data: Tenant = await tenantService.createtenant(values);
                    setTenentInfo(data);

                    toast({
                        title: values.tenantName + ' successfully created.',
                        //description: 'Tenant added successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    navigate('/admin/tenent');
                    setTabIndex(1);
                } catch (error) {
                    toast({
                        //title: values.tenantName + ' Tenant created error.',
                        title: 'Error occured while Creating ' + values.tenantName,

                        //description: 'Error while adding tenant.',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            } else {
                if (!isTenantAdmin) {
                    values.tenantId = tenentId;
                } else {
                    values.tenantId = tenantAdminTenent.tenantId;
                }

                try {
                    const data: Tenant = await tenantService.updatetenantById(values);
                    setTenentInfo(data);
                    toast({
                        title: values.tenantName + ' successfully updated.',
                        //description: 'Tenant updated successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    if (!isTenantAdmin && isPortalAdmin) {
                        navigate('/admin/tenent');
                        setTabIndex(1);
                    } else {
                        userLoggedIn.userDetails.userInfo.tenant = values;
                        localStorage.removeItem('userLoginStatus');
                        localStorage.setItem(
                            'userLoginStatus',
                            JSON.stringify(userLoggedIn),
                        );
                    }
                } catch (error) {
                    toast({
                        title: 'Error occured while updating ' + values.tenantName,
                        //description: 'Error while updating tenant.',
                        status: 'error',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                }
            }
        },
    });

    const onCancelClick = () => {
        if (!isTenantAdmin) {
            navigate('/admin/tenent');
        } else {
            navigate('/admin');
        }
    };

    const GetStaffListView = () => {
        return <Box bg={bg}></Box>;
    };

    const handleFormattedInput = (event: { target: any; }, fieldName: string, maxDigits: number, isSSN = false, separator = '-') => {
        let inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        inputValue = inputValue.substring(0, maxDigits); // Limit to a maximum of `maxDigits` digits

        let formattedValue = inputValue;
         if (fieldName === 'tenantPhone') {
            // Adjust the regex pattern for phone number formatting
            formattedValue = inputValue.replace(/^(\d{0,3})(\d{0,3})?(\d{0,4})?$/, (match: any, p1: string, p2: any, p3: any) => {
                let formatted = '';
                if (p2 === undefined && p3 === undefined) {
                    formatted = `(${p1})`; // Format as (XXX)
                } else if (p3 === undefined) {
                    formatted = `(${p1}) ${p2}`; // Format as (XXX) XXX
                } else {
                    formatted = `(${p1}) ${p2}-${p3}`; // Format as (XXX) XXX-XXXX
                }
                return formatted;
            });
        } 

        // Update formik values with formatted value
        formik.setFieldValue(fieldName, formattedValue);
    };



    const handleChange = (event: { target: any; }, fieldType: string) => {
        let fieldName = '';
        let maxDigits = 0;
        let separator = '-';
        //let isValidEmail = false;
        switch (fieldType) {
            case 'tenantPhone':
                fieldName = 'tenantPhone';
                maxDigits = 10;
                break;
            case 'postalCode':
                fieldName = 'postalCode';
                maxDigits = 5;
                separator = '-';
                break;
            //case 'email':
            //    fieldName = 'email';
            //    isValidEmail = validateEmail(event.target.value);
            //    break;
            default:
                break;
        }
        //if (isValidEmail) {
        //    handleFormattedInput(event, fieldName, maxDigits, isSSN, separator);
        //} else {
        //}

        handleFormattedInput(event, fieldName, maxDigits);
    };


    return (
        <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'} paddingTop="25">
            <Box className="addTopMarginCard" paddingBottom="125">
                <Tabs
                    defaultIndex={isStaffListView ? 1 : 0}
                    isFitted
                    variant="enclosed"
                    bg="white"
                >
                    {isAddTenent ? (
                        <TabList>
                            <Tab>Info</Tab>
                            {tenentId ? (
                                <Tab>Staff</Tab>
                            ) : (
                                <Tab isDisabled={!isAddTenent}>Staff</Tab>
                            )}
                        </TabList>
                    ) : (
                        <TabList>
                            <Tab isDisabled={!isAddTenent}>Info</Tab>
                            <Tab>Staff</Tab>
                        </TabList>
                    )}
                    <TabPanels>
                        <TabPanel>
                            <Heading as="h4" size="md">
                                Company Profile
                            </Heading>
                            <Box>
                                <form onSubmit={formik.handleSubmit}>
                                    <Box>
                                        <SimpleGrid
                                            p={2}
                                            spacingX={2}
                                            minChildWidth="225px"
                                            columns={2}
                                        >
                                            <Box>
                                                <Text mb="8px">Name</Text>
                                                <Input
                                                    as={Input}
                                                    id="tenantName"
                                                    name="tenantName"
                                                    type="text"
                                                    // helperText={
                                                    //   formik.touched.tenantName
                                                    //     ? formik.errors.tenantName
                                                    //     : ''
                                                    // }
                                                    size="md"
                                                    width="50%"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.tenantName}
                                                />
                                                <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                    {formik.touched.tenantName
                                                        ? formik.errors.tenantName
                                                        : ''}
                                                </span>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={3}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">Mobile Number: </Text>
                                                    <Input
                                                        placeholder="(XXX) XXX-XXXX"
                                                        id="tenantPhone"
                                                        name="tenantPhone"
                                                        type="text"
                                                        size="md"
                                                        width="50%"
                                                        onChange={(event) => handleChange(event, 'tenantPhone')}
                                                        value={formik.values.tenantPhone}
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.tenantPhone
                                                            ? formik.errors.tenantPhone
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={3}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">Email: </Text>
                                                    <Input
                                                        placeholder="name@email.com"
                                                        id="email"
                                                        name="tenantContactEmail"
                                                        type="email"
                                                        size="md"
                                                        width="50%"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.tenantContactEmail}
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.tenantContactEmail
                                                            ? formik.errors.tenantContactEmail
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={3}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">Contact Name: </Text>
                                                    <Input
                                                        placeholder="Name"
                                                        id="tenantContactName"
                                                        name="tenantContactName"
                                                        type="text"
                                                        size="md"
                                                        width="50%"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.tenantContactName}
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.tenantContactName
                                                            ? formik.errors.tenantContactName
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={2}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">Website </Text>
                                                    <Input
                                                        placeholder="www.xyzbusiness.com"
                                                        id="URL"
                                                        name="tenantDomain"
                                                        type="text"
                                                        width="50%"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.tenantDomain}
                                                        size="md"
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.tenantDomain
                                                            ? formik.errors.tenantDomain
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={4}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">Street Address 1: </Text>
                                                    <Input
                                                        placeholder="Address 1"
                                                        id="tenantAddress"
                                                        name="tenantAddress"
                                                        type="text"
                                                        size="md"
                                                        width="50%"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.tenantAddress}
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.tenantAddress
                                                            ? formik.errors.tenantAddress
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={3}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">Street Address 2: </Text>
                                                    <Input
                                                        placeholder="Address 2"
                                                        id="tenantAddress1"
                                                        name="tenantAddress1"
                                                        type="text"
                                                        size="md"
                                                        width="50%"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.tenantAddress1}
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.tenantAddress1
                                                            ? formik.errors.tenantAddress1
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={3}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">City: </Text>
                                                    <Input
                                                        placeholder="City"
                                                        id="city"
                                                        name="city"
                                                        type="text"
                                                        size="md"
                                                        width="50%"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.city}
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.city ? formik.errors.city : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>

                                        <SimpleGrid
                                            p={2}
                                            spacingX={3}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                {/* <Grid templateRows='repeat(3)' templateColumns='repeat(2, 1fr)' gap={4} > */}
                                                <Box>
                                                    <Box>
                                                        <Text mb="8px">State: </Text>

                                                        <Select
                                                            placeholder="Select an option"
                                                            name="stateid"
                                                            value={formik.values.stateid}
                                                            width="50%"
                                                            onChange={formik.handleChange}
                                                        >
                                                            {stateList.map((state: StateList) => (
                                                                <option key={state.name} value={state.id}>
                                                                    {state.name}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                            {formik.touched.stateid
                                                                ? formik.errors.stateid
                                                                : ''}
                                                        </span>
                                                    </Box>
                                                </Box>

                                                {/* </Grid> */}
                                            </Box>
                                        </SimpleGrid>
                                        <SimpleGrid
                                            p={2}
                                            spacingX={4}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                        >
                                            <Box>
                                                <Box>
                                                    <Text mb="8px">Zip Code: </Text>
                                                    <Input
                                                        placeholder="00000"
                                                        id="postalCode"
                                                        name="postalCode"
                                                        type="text"
                                                        maxLength={5}
                                                        size="md"
                                                        width="50%"
                                                        onChange={(event) => handleChange(event, 'postalCode')}
                                                        value={formik.values.postalCode}
                                                    />
                                                    <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                                        {formik.touched.postalCode
                                                            ? formik.errors.postalCode
                                                            : ''}
                                                    </span>
                                                </Box>
                                            </Box>
                                        </SimpleGrid>
                                        <Divider mt="24px" mb="24px" />

                                        <SimpleGrid
                                            p={2}
                                            spacingX={'10px'}
                                            minChildWidth="225px"
                                            spacingY={'20px'}
                                            ml="8px"
                                            mb="16px"
                                        >
                                            <Box></Box>
                                            <Box></Box>
                                            <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                                <CustomButton
                                                    label={"Cancel"}
                                                    onClick={onCancelClick}
                                                />
                                                <CustomButton
                                                    label={"Save"}
                                                    onClick={null}
                                                    type="submit"
                                                />
                                            </Flex>
                                        </SimpleGrid>
                                    </Box>
                                </form>
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid
                                p={2}
                                spacingX={4}
                                minChildWidth="225px"
                                spacingY={'20px'}
                            >
                                <Heading as="h4" size="md">
                                    Staff Management
                                </Heading>
                                <Box>
                                    <Box className="flex h-[38px] w-[355px] flex h-20 items-center justify-end float-right">

                                        <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                            {/* <CustomButton
                                            label={"Create Filter"}
                                            onClick={null}
                                            type="submit"
                                        /> */}
                                            <CustomButton
                                                label={"Add Staff"}
                                                onClick={addNewPeople}
                                                type={null}
                                            />
                                        </Flex>
                                    </Box>
                                </Box>
                            </SimpleGrid>

                            {/* <Flex>
              <Heading>Staff Management</Heading>
              <Spacer />
              <Box>
                <div className="flex h-[38px] w-[400px] flex h-20 items-center justify-end px-6">
                  <Box
                    mr="8px"
                    className={
                      'linear flex p-1 cursor-pointer border-1 justify-around border border-gray-200 transition shadow-3xl px-10 shadow-shadow-100 hover:bg-blue-500 '
                    }
                  >
                    Create Filter
                  </Box>
                  <Box
                    className={
                      'linear flex p-1 cursor-pointer border-1 justify-around border border-gray-200 transition shadow-3xl px-10 shadow-shadow-100 hover:bg-blue-500 '
                    }
                    onClick={() => {
                      addNewPeople();
                    }}
                  >
                    Add Staff
                  </Box>
                </div>
              </Box>
            </Flex> */}

                            <Box w="100%" p={2}>
                                {userList.length > 0 ? (
                                    <StaffListTable
                                        tableData={userList}
                                        tenantDetails={tenantSelected}
                                    ></StaffListTable>
                                ) : (
                                        'No Records Available'
                                )}
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Card>
    );
}
