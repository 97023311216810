import { Box, Input, Text, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useColorModeValue, useToast, Flex } from "@chakra-ui/react";
import CustomButton from "components/button/CustomButton";
import Card from "components/card/Card";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BusinessService } from "services/BusinessService";
import { PeopleService } from "services/PeopleService";
import { PersonaService } from "services/PersonaService";
import { BusinessDetails } from "types/Business";
import { StateList } from "types/People";
import { TenantUsers } from "types/Tenant";
import * as Yup from 'yup';
import PeopleListTable from "../components/PeopleListTable";
import ViewTenantList from "../components/ViewTenantList";

export default function ViewBusinessPage() {
    const { businessId } = useParams();
    const [stateList, setStateList] = useState<StateList[] | []>([]);
    const [tenantList, setTenantList] = useState<StateList[] | []>([]);
    const [peopleList, setPeopleList] = useState<StateList[] | []>([]);
    const [personasList, setPersonasList] = useState<StateList[] | []>([]);
    const [userLoggedIn] = [JSON.parse(localStorage.getItem("userLoginStatus"))];
    const [currentUserId, setCurrentUserId] = useState<string>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const bg = useColorModeValue('background.100', 'background.900');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toast = useToast();
    const navigate = useNavigate();
    const {
        userDetails: {
            userInfo: { userId, tenantId },
        },
    } = userLoggedIn;
    const formInit: {
        businessName: string;
        businessAddress: string;
        businessType: string;
        incorporationDate: string | null; // change type to string or null
        annualRevenue: string | null;
        industry: string;
        businessRegistrationNumber: string;
        rootDocumentFolder: string;
        tenantId: number;
        userId: string;
        businessId: number;
    } = {
        businessName: '',
        businessAddress: '',
        businessType: '',
        incorporationDate: null, // assign null to incorporationDate
        annualRevenue: null,
        industry: '',
        businessRegistrationNumber: '',
        rootDocumentFolder: '',
        tenantId: 0,
        userId: '',
        businessId: 0,
    };
    const [businessDetails, setBusinessDetails] = useState(formInit);
    const getStateList = async () => {
        try {
            const data: any = await PeopleService.getState<StateList>('tenantId');
            setStateList(data);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const getBusinessById = async () => {
        if (!businessId) {
            return;
        }

        try {
            const data: any =
                await BusinessService.getClinetDetailsById<BusinessDetails>(parseInt(businessId));
            setBusinessDetails(data.business);
            setPeopleList(data.clientPersonas ? data.clientPersonas : []);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const getPersonasById = async () => {
        if (!businessId) {
            return;
        }

        try {
            const data: any = await PersonaService.getPeoplePersona<BusinessDetails>();
            setPersonasList(data);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }
    };

    const openModal = async () => {
        try {
            const data: any =
                await BusinessService.getPeopleDetailsByTenantId<TenantUsers>(tenantId);
            const result = data.peoples.map((item: any) => {
                return { ...item, isSelected: false };
            });
            setTenantList(result);
        } catch (error) {
            setError('Error fetching user data');
        } finally {
            setLoading(false);
        }

        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        getBusinessById();
    };

    useEffect(() => {
        setCurrentUserId(userLoggedIn?.userDetails?.userInfo?.userId);
        getStateList();
        getBusinessById();
        getPersonasById();
        return () => {
            setBusinessDetails(formInit);
            setPeopleList([]);
        };
    }, []);


    const validationSchema = Yup.object().shape({
        businessName: Yup.string().required('Business Name is required'),
        //businessAddress: Yup.string().required('Business Address is required'),
        //businessType: Yup.string().required('Business Type is required'),
        //incorporationDate: Yup.date().required('Select Incorporation Date'),
        //annualRevenue: Yup.string().required('Annual Revenue is required'),
        //industry: Yup.string().required('Industry is required'),
        //businessRegistrationNumber: Yup.string().required('Registration Number is required'),
        //rootDocumentFolder: Yup.string().required('Root Document Folder is required'),
    });

    const cancelRequest = () => {
        navigate('/admin/businesses');
    };

    const getFormattedDate = (dob: string) => {
        return moment(dob, 'YYYY-MM-DD').format('YYYY-MM-DD');
    };


    const formik = useFormik({
        initialValues: businessDetails,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            values.userId = userId;
            values.tenantId = tenantId;

            if (businessId) {
                try {
                    const data: any = await BusinessService.updateBusinessById(values);
                    toast({
                        title: values.businessName + ' successfully updated.',
                        //description: 'Business updated successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    cancelRequest();
                } catch (error) { }
            } else {
                values.businessId = 0;

                // delete values.clientId;

                try {
                    const data: any = await BusinessService.createbusiness(values);
                    toast({
                        title: values.businessName + ' successfully created.',
                        //description: 'Business added successfully.',
                        status: 'success',
                        duration: 5000,
                        position: 'top',
                        isClosable: true,
                    });
                    resetForm();
                    cancelRequest();
                } catch (error) { }
            }
        },
    });

    const deletePeople = async (id: string) => {
        try {
            const data: any = await PersonaService.deleteClientRelation(id);
            toast({
                title: 'Business deleted successfully.',
                //description: 'Business deleted successfully.',
                status: 'success',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
            getBusinessById();
        } catch (error: any) {
            toast({
                title: 'Error.',
                description: 'Error occured while deleting the business',
                status: 'error',
                duration: 5000,
                position: 'top',
                isClosable: true,
            });
        }
    };

    const addNewPersonClick = () => {
        navigate('/admin/people/AddPeople');
    };



    return (
        <Card bg={bg} extra={'w-full h-full pb-5 sm:overflow-auto px-6 cardH'} paddingTop="25">
            <Tabs isFitted variant="enclosed" bg="white">
                <TabList>
                    <Tab>Profile</Tab>
                    {/* {businessId ? <Tab>Documents</Tab> : <Tab isDisabled> Documents</Tab>} */}
                    {/* <Tab>People</Tab> */}
                    {businessId ? <Tab>Associated People</Tab> : ""}
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">*Business Name</Text>
                                        <Input
                                            as={Input}
                                            id="businessName"
                                            name="businessName"
                                            placeholder="Business Name"
                                            type="text"
                                            // helperText={
                                            //     formik.touched.businessName
                                            //         ? formik.errors.businessName
                                            //         : ''
                                            // }
                                            size="md"
                                            onChange={formik.handleChange}
                                            value={formik.values.businessName}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.businessName
                                                ? formik.errors.businessName
                                                : ''}
                                        </span>
                                    </Box>
                                    <Box></Box>
                                </SimpleGrid>

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Business Type </Text>
                                        <Input
                                            placeholder="Business Type"
                                            id="businessType"
                                            name="businessType"
                                            type="text"
                                            onChange={formik.handleChange}
                                            value={formik.values.businessType}
                                            size="md"
                                            width="50%"
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.businessType
                                                ? formik.errors.businessType
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Registration Number </Text>
                                        <Input
                                            placeholder="+1 000 111 2223"
                                            id="businessRegistrationNumber"
                                            name="businessRegistrationNumber"
                                            type="text"
                                            size="md"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.businessRegistrationNumber}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.businessRegistrationNumber
                                                ? formik.errors.businessRegistrationNumber
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                                {/*<SimpleGrid*/}
                                {/*    p={2}*/}
                                {/*    spacingX={2}*/}
                                {/*    minChildWidth="225px"*/}
                                {/*    columns={2}*/}
                                {/*>*/}
                                {/*    <Box>*/}
                                {/*        <Text mb="8px">Root Document Folder </Text>*/}
                                {/*        <Input*/}
                                {/*            placeholder="Root Document Folder"*/}
                                {/*            id="rootDocumentFolder"*/}
                                {/*            name="rootDocumentFolder"*/}
                                {/*            type="text"*/}
                                {/*            size="md"*/}
                                {/*            width="50%"*/}
                                {/*            onChange={formik.handleChange}*/}
                                {/*            value={formik.values.rootDocumentFolder}*/}
                                {/*        />*/}
                                {/*        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>*/}
                                {/*            {formik.touched.rootDocumentFolder*/}
                                {/*                ? formik.errors.rootDocumentFolder*/}
                                {/*                : ''}*/}
                                {/*        </span>*/}
                                {/*    </Box>*/}
                                {/*</SimpleGrid>*/}

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Annual Revenue </Text>
                                        <Input
                                            placeholder="Annual Revenue"
                                            id="annualRevenue"
                                            name="annualRevenue"
                                            type="text"
                                            size="md"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.annualRevenue}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.annualRevenue
                                                ? formik.errors.annualRevenue
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Industry </Text>
                                        <Input
                                            placeholder="industry"
                                            id="industry"
                                            name="industry"
                                            type="text"
                                            size="md"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.industry}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.industry ? formik.errors.industry : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>
                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Incorporation Date </Text>
                                        <Input
                                            placeholder="+1 000 111 2223"
                                            size="md"
                                            id="incorporationDate"
                                            name="incorporationDate"
                                            type="date"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={getFormattedDate(formik.values.incorporationDate)}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.incorporationDate
                                                ? formik.errors.incorporationDate
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                                <SimpleGrid
                                    p={2}
                                    spacingX={2}
                                    minChildWidth="225px"
                                    columns={2}
                                >
                                    <Box>
                                        <Text mb="8px">Business Address </Text>
                                        <Input
                                            placeholder="Address"
                                            id="businessAddress"
                                            name="businessAddress"
                                            type="text"
                                            size="md"
                                            width="50%"
                                            onChange={formik.handleChange}
                                            value={formik.values.businessAddress}
                                        />
                                        <span style={{ color: 'red', display: 'block', marginTop: '4px' }}>
                                            {formik.touched.businessAddress
                                                ? formik.errors.businessAddress
                                                : ''}
                                        </span>
                                    </Box>
                                </SimpleGrid>

                                <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                    <CustomButton
                                        label={"Cancel"}
                                        onClick={cancelRequest}

                                    />
                                    <CustomButton
                                        label={"Save"}
                                        onClick={null}
                                        type="submit"

                                    />
                                </Flex>

                            </form>
                        </Box>
                    </TabPanel>
                    {/* <TabPanel></TabPanel> */}
                    <TabPanel>
                        <Box pt={'16px'} pb={'16px'}>
                            <Flex justify={{ base: 'center', md: 'flex-end' }} mt={4}>
                                <CustomButton label={"Add Person"} onClick={addNewPersonClick} />
                                <CustomButton label={'Link People'} onClick={openModal} />
                            </Flex>

                            <ViewTenantList
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                jsonData={tenantList}
                                personasList={personasList}
                                peoplesDetails={peopleList}
                                businessId={businessId}
                            />
                        </Box>
                        {peopleList.length ? (
                            <PeopleListTable
                                tableData={peopleList}
                                personasList={personasList}
                                deletePeople={deletePeople}
                            ></PeopleListTable>
                        ) : (
                            'data not available'
                        )}


                    </TabPanel>
                </TabPanels>
            </Tabs>

        </Card>
    );
}